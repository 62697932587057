import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as action from '../../../modules/Store/action';

export const MenuItem = ({ alias = '', Icon = <></>, text = '', onClick = () => {} }) => {
  const dispatch = useDispatch();
  const { currentPage } = useSelector(state => state);
  const [hover, setHover] = useState(false);
  const isActive = currentPage.alias === alias;

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave= () => setHover(false);
  const handleClick = () => {
    if (alias) {
      dispatch(action.setPage(alias));
    } else {
      onClick();
    }
  };

  return (
    <View isActive={isActive} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ItemIcon><Icon fill={isActive || hover ? Colors.main_blue : Colors.main_dark} /></ItemIcon>
      <ItemText>{text}</ItemText>
    </View>
  );
};

const View = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  color: ${({ isActive }) => isActive ? Colors.main_blue : Colors.main_dark};
  display: flex;
  align-items: center;
  cursor: pointer;
  
  ${({ isActive }) => !isActive && `
    &:hover {
      color: ${Colors.main_blue};
    }
  `};
`;
const ItemIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  flex-shrink: 0;
  overflow: hidden;
`;
const ItemText = styled.div`
  font-size: 16px;
  line-height: 19px;
`;
