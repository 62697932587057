import React, { memo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { useSelector } from 'react-redux';
import { Information } from '../components/Information';
import { Shop } from '../components/Shop';

export const MainScreen = memo(() => {
  const { goods: { main }} = useSelector(state => state);

  return (
    <View>
      <Information />
      {Boolean(main) && <Shop />}
    </View>
  );
});

const View = styled.div`
  display: flex;
`;
