import {
  SET_CURRENT_PANORAMA,
  SET_STRUCTURE,
  SET_PROGRESS_AUTO_SAVE,
} from '../constants';
import { ApiCall } from './api-call';
import { setTourId } from './Store/actions';
import { translations } from '@libs';

export const setNext = () => (dispatch, getState) => {
  const { current, panoramas } = getState();
  const index = panoramas.indexOf(current);
  const nextIndex = panoramas[index + 1] ? index + 1 : 0;

  dispatch({ type: SET_CURRENT_PANORAMA, payload: panoramas[nextIndex] });
};

export const setPrev = () => (dispatch, getState) => {
  const { current, panoramas } = getState();
  const index = panoramas.indexOf(current);
  const prevIndex = panoramas[index - 1] ? index - 1 : panoramas.length - 1;

  dispatch({ type: SET_CURRENT_PANORAMA, payload: panoramas[prevIndex] });
};

export const setCurrent = (panoramaId) => (dispatch, getState) => {
  const { current, panoramas } = getState();

  if (Number(current.id) === Number(panoramaId)) return;

  const panorama = panoramas.find(panorama => Number(panorama.id) === Number(panoramaId));

  if (!panorama) {
    console.error(`Panorama id ${panoramaId} not found!`);

    return;
  }

  dispatch({ type: SET_CURRENT_PANORAMA, payload: panorama });
};

const getFirstScene = (structure, panoramas) => {
  try {
    if (structure.startScene) {
      const idsPanoramas = panoramas.map(panoram => panoram.id);
      let idStartScene = idsPanoramas.includes(structure.startScene) ? structure.startScene : null;

      if (!idStartScene) {
        idStartScene = panoramas[0].id;
      }

      return idStartScene;
    }

    return panoramas[0].id;
  } catch (error) {
    console.error(error);

    return panoramas?.[0]?.id || null;
  }
};

export const setStartStructure = (panoramas = []) => (dispatch, getState) => {
  try {
    const { structure } = getState();
    const startScene = getFirstScene(structure, panoramas);
    const scenes = {};

    panoramas.forEach((panorama) => {
      const saveScene = structure?.scenes?.[panorama.id];
      const roomName = panorama?.cam_info?.room;

      scenes[panorama.id] = saveScene || {
        title: roomName || `${translations.t('tour_editor.panorama')} ${panorama.id}`,
        file: panorama.original.file
      }
    });

    const startStructure = {
      startScene,
      scenes,
    };

    dispatch(setCurrent(startScene));
    dispatch({ type: SET_STRUCTURE, payload: startStructure });
  } catch (error) {
    console.error(error);
  }
};

export const setStartScene = (sceneId = '') =>  (dispatch, getState) => {
  try {
    const { structure } = getState();
    const { scenes } = structure;
    const idsScenes = Object.keys(scenes).map(id => Number(id));

    if (Number(structure.startScene) === Number(sceneId)) {
      return;
    }

    if (idsScenes.includes(sceneId)) {
      dispatch({ type: SET_STRUCTURE, payload: { ...structure, startScene: sceneId }});
    } else {
      console.error('sceneId not found among the panoramas');
    }
  } catch (error) {
    console.error(error);
  }
};

export const setNameScene = (sceneId = '', name = '') =>  (dispatch, getState) => {
  try {
    const { structure } = getState();
    const { scenes } = structure;
    const idsScenes = Object.keys(scenes).map(id => Number(id));

    if (idsScenes.includes(sceneId)) {
      dispatch({
        type: SET_STRUCTURE,
        payload: {
          ...structure,
          scenes: {
            ...structure.scenes,
            [sceneId]: {
              ...structure.scenes[sceneId],
              title: name
            }
          }
        }
      });
    } else {
      console.error('sceneId not found among the panoramas');
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeHotspot = (sceneId, index = '', removeFromStructure = true) => (dispatch, getState) => {
  const { krpano } = getState();

  krpano.get('global').removehotspot(`arrow_hotspot_${sceneId}_${index}`);
  krpano.get('global').removehotspot(`text_hotspot_${sceneId}_${index}`);

  if (`${index}` && removeFromStructure) {
    dispatch(onRemoveHotspotStructure(sceneId, index));
  }
};

export const onRemoveHotspotStructure = (sceneId, index) => (dispatch, getState) => {
  if (!sceneId || !index) return;

  try {
    const { structure } = getState();
    const scene = structure?.scenes?.[sceneId];

    if (scene) {
      const hotspots = scene.hotspots.filter((h, i) => i !== Number(index));

      dispatch({
        type: SET_STRUCTURE,
        payload: {
          ...structure,
          scenes: {
            ...structure.scenes,
            [sceneId]: {
              ...structure.scenes[sceneId],
              hotspots: [...hotspots]
            }
          }
        }
      });
    } else {
      console.error('sceneId not found among the panoramas');
    }
  } catch (error) {
    console.error(error);
  }
};

export const onEditHotspotStructure = (sceneId, index, linkSceneId, icon = {}) => (dispatch, getState) => {
  if (!sceneId || !index || !(linkSceneId || icon)) return;

  try {
    const { structure } = getState();
    const scene = structure?.scenes?.[sceneId];

    if (linkSceneId) {
      if (!structure?.scenes?.[linkSceneId]) return;
    }

    if (scene) {
      const hotspots = scene.hotspots.map((hotspot, i) => {
        const newHotspot = {
          ...hotspot,
        };
  
        if (linkSceneId) {
          newHotspot.linkedscene = i === Number(index) ? linkSceneId : hotspot.linkedscene;
        }
  
        if (icon && icon.alias) {
          newHotspot.icon = i === Number(index) ? icon.alias : hotspot.icon;
        }

        return newHotspot;
      });

      dispatch({
        type: SET_STRUCTURE,
        payload: {
          ...structure,
          scenes: {
            ...structure.scenes,
            [sceneId]: {
              ...structure.scenes[sceneId],
              hotspots: [ ...hotspots ]
            }
          }
        }
      });
    } else {
      console.error('sceneId not found among the panoramas');
    }
  } catch (error) {
    console.error(error);
  }
};

export const onCreateHotspotStructure = (sceneId, linkSceneId, ath, atv, icon = {}) => (dispatch, getState) => {
  if (!sceneId || !linkSceneId || !ath || !atv) return;

  try {
    const { structure } = getState();
    const scene = structure?.scenes?.[sceneId];

    if (!structure?.scenes?.[linkSceneId]) return;

    if (scene) {
      const hotspot = {
        type: 'hotspot',
        at: {
          "h": ath,
          "v": atv
        },
        linkedscene: `${linkSceneId}`,
        icon: icon.alias || '',
      };

      const hotspots = [...(structure?.scenes?.[sceneId]?.hotspots || []), hotspot];

      dispatch({
        type: SET_STRUCTURE,
        payload: {
          ...structure,
          scenes: {
            ...structure.scenes,
            [sceneId]: {
              ...structure.scenes[sceneId],
              hotspots,
            }
          }
        }
      });
    } else {
      console.error('sceneId not found among the panoramas');
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadScene = (sceneId) => (dispatch, getState) => {
  try {
    const { krpano, structure } = getState(state => state);
    const scene = structure.scenes[sceneId];

    if (scene && scene.file) {
      krpano.call(`_loadpanoimage('${scene.file}', '${sceneId}');`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const createHotspot = (sceneId, linkSceneId, ath, atv, icon = {}) => (dispatch, getState) => {
  try {
    const { krpano, structure } = getState(state => state);
    const hotspots = structure.scenes[sceneId].hotspots;
    const index = hotspots.length - 1;

    const arrow_hotspot = krpano.get('global').addhotspot(`arrow_hotspot_${sceneId}_${index}`);

    krpano.call(`assignstyle(hotspot["${arrow_hotspot.name}"], ${icon.style || 'skin_hotspot_arrow_style'});`);
    arrow_hotspot.ath = ath;
    arrow_hotspot.atv = parseFloat(atv) + 1.25;
    arrow_hotspot.onclick = `js(onClickHotspot("${index}", "${sceneId}"));`;

    const text_hotspot = krpano.get('global').addhotspot(`text_hotspot_${sceneId}_${index}`);

    if (structure.scenes?.[linkSceneId]?.title) {
      krpano.call(`assignstyle(hotspot["${text_hotspot.name}"], 'skin_hotspot_text_style');`);
      text_hotspot.ath = ath;
      text_hotspot.atv = parseFloat(atv) - 1.25;
      text_hotspot.onclick = `js(onClickHotspot("${index}", "${sceneId}"));`;
      text_hotspot.html = structure.scenes?.[linkSceneId]?.title;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addHotspot = (sceneId, index, icon = {}) => (dispatch, getState) => {
  try {
    const { krpano, structure } = getState(state => state);
    const hotspot = structure.scenes[sceneId].hotspots[index];

    if (!hotspot) return console.error(new Error(`hotspot ${index} not found`));

    const arrow_hotspot = krpano.get('global').addhotspot(`arrow_hotspot_${sceneId}_${index}`);

    krpano.call(`assignstyle(hotspot["${arrow_hotspot.name}"], ${icon.style || 'skin_hotspot_arrow_style'});`);
    arrow_hotspot.ath = hotspot.at.h;
    arrow_hotspot.atv = parseFloat(hotspot.at.v) + 1.25;
    arrow_hotspot.onclick = `lookto(get(ath), ${hotspot.at.v}, get(view.fov));js(onClickHotspot("${index}", "${sceneId}"));`;

    const text_hotspot = krpano.get('global').addhotspot(`text_hotspot_${sceneId}_${index}`);

    if (structure.scenes?.[hotspot?.linkedscene]?.title) {
      krpano.call(`assignstyle(hotspot["${text_hotspot.name}"], 'skin_hotspot_text_style');`);
      text_hotspot.ath = hotspot.at.h;
      text_hotspot.atv = parseFloat(hotspot.at.v) - 1.25;
      text_hotspot.onclick = `lookto(get(ath), ${hotspot.at.v}, get(view.fov));js(onClickHotspot("${index}", "${sceneId}"));`;
      text_hotspot.html = structure.scenes?.[hotspot?.linkedscene]?.title;
    }
  } catch (error) {
    console.error(error);
  }
};

export const centerTo = (ath, atv) => (dispatch, getState) => {
  try {
    const { krpano } = getState(state => state);

    krpano.call(`lookto(${ath}, ${atv}, 90);`);
  } catch (error) {
    console.error(error);
  }
};

export const focusHotspot = (sceneId, index) => async (dispatch, getState) => {
  try {
    const { structure } = getState(state => state);
    const hotspot = structure.scenes[sceneId].hotspots[index];

    if (!hotspot) return;

    const atv = hotspot?.at?.v || 0;
    const ath = hotspot?.at?.h || 0;

    dispatch(centerTo(ath, atv + 20));
  } catch (error) {
    console.error(error);
  }};

export const blurHotspot = (sceneId, index) => (dispatch, getState) => {
  try {
    const { structure } = getState(state => state);
    const hotspot = structure?.scenes?.[sceneId]?.hotspots?.[index];

    if (!hotspot) return;

    const atv = hotspot?.at?.v || 0;
    const ath = hotspot?.at?.h || 0;

    dispatch(centerTo(ath, atv));
  } catch (error) {
    console.error(error);
  }
};

export const saveTourStructure = (structure) => async (dispatch, getState) => {
  try {
    const { settings: { projectId, tourId }} = getState();
    const minTimeWait = 500; // Минимальное время нахождения SET_PROGRESS_AUTO_SAVE в true
    const start = Date.now();

    dispatch({ type: SET_PROGRESS_AUTO_SAVE, payload: true });

    const response = await ApiCall.saveStructure({ projectId, tourId, data: structure });
    const { data } = response.data;

    if (!tourId && data.id) {
      dispatch(setTourId(data.id));
    }

    setTimeout(() => {
      dispatch({ type: SET_PROGRESS_AUTO_SAVE, payload: false });
    }, minTimeWait - (Date.now() - start));
  } catch (error) {
    console.error(error);

    dispatch({ type: SET_PROGRESS_AUTO_SAVE, payload: false });
  }
};

export const updateTitleInHotspots = (prevStructure) => (dispatch, getState) => {
  if (!prevStructure) return;

  const { structure, current, hotspotIcons } = getState();
  const currentSceneId = current.id;
  
  try {
    const scenes = structure.scenes;
    const prevScenes = prevStructure.scenes;
    const sceneIdWithChangedTitle = [];

    for (let key in scenes) {
      if (Object.prototype.hasOwnProperty.call(scenes, key) && Object.prototype.hasOwnProperty.call(prevScenes, key)) {
        if (scenes[key].title !== prevScenes[key].title) {
          sceneIdWithChangedTitle.push(key);
        }
      }
    }

    if (!sceneIdWithChangedTitle.length) return;

    const hotspotsNeedUpdate = [];
    const hotspotsInCurrentScene = scenes[currentSceneId].hotspots;

    if (hotspotsInCurrentScene && hotspotsInCurrentScene.length) {
      for (let index in hotspotsInCurrentScene) {
        if (Object.prototype.hasOwnProperty.call(hotspotsInCurrentScene, index)) {
          if (sceneIdWithChangedTitle.includes(hotspotsInCurrentScene[index].linkedscene)) {
            hotspotsNeedUpdate.push({ index, ...hotspotsInCurrentScene[index] });
          }
        }
      }
    }

    hotspotsNeedUpdate.forEach((hotspot) => {
      const icon = hotspotIcons.find(icon => icon.alias === hotspot.icon);

      dispatch(removeHotspot(currentSceneId, hotspot.index, false));
      dispatch(addHotspot(currentSceneId, hotspot.index, icon));
    });
  } catch (error) {
    console.error(error);
  }
};
