import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IWarning } from 'planoplan-ui-kit/core/icons/i-warning';
import { translations } from '@libs';
import { StoreContext } from '../../modules/Store';
import * as effects from '../../modules/effects';
import { getAnalyticParamsRender, getAnalyticParamsTariff, getObjViewPrice } from '../../modules/helpers';
import { TARIFF_JOIN_GOODS, TARIFF_ALIAS } from '../../constants';
import { Analytics } from '../../modules/analytics';
import { IconPlus } from '@icons';

export const TariffCart = ({ tariff }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { products, pricing, cart, profileGoods: { tariff_alias, tariff_is_trial }} = state;
  const [period, setPeriod] = useState('year');
  const [alias, setAlias] = useState('');
  const [name, setName] = useState('');
  const discount = (period === 'year' && tariff.pricing) ? {
    sum: tariff.pricing.month * 12,
    percent: ((tariff.pricing.month * 12 - tariff.price) / (tariff.pricing.month * 12) * 100).toFixed(0)
  } : null;

  const isProplusTariff = tariff.caption.toLowerCase().indexOf('pro+') !== -1;
  const displayTrialInfo = tariff_is_trial && tariff_alias === TARIFF_ALIAS.PROPLUS && isProplusTariff;
  const displayWarning = tariff_alias !== TARIFF_ALIAS.START && tariff_alias !== alias && ![TARIFF_ALIAS.BUSINESS, TARIFF_ALIAS.TEAM2].includes(alias);

  const onChangeInput = (event) => setPeriod(event.target.value);
  const setAliasAndPeriod = () => {
    for (const key in TARIFF_JOIN_GOODS) {
      if (Object.prototype.hasOwnProperty.call(TARIFF_JOIN_GOODS, key)) {
        if (TARIFF_JOIN_GOODS[key].year === tariff.id) {
          setPeriod('year');
          setAlias(key);
        }

        if (TARIFF_JOIN_GOODS[key].month === tariff.id) {
          setPeriod('month');
          setAlias(key);
        }
      }
    }
  };
  const findNameTariff = () => {
    if (!alias) {
      return;
    }

    const tariffInfo = pricing.find(item => {
      return item.alias === alias
    });

    setName(tariffInfo.caption);
  };
  const sendAnalyticECommerce = (searchTariff) => {
    const analyticItems = cart.map(item => {
      if (item.alias === 'tariff' || [TARIFF_ALIAS.BUSINESS, TARIFF_ALIAS.TEAM2].includes(item.alias)) {
        return getAnalyticParamsTariff(searchTariff);
      }

      return getAnalyticParamsRender(item);
    });

    Analytics.ecommerceViewCart(analyticItems);
  };

  useEffect(() => {
    setAliasAndPeriod();
  }, []);

  useEffect(() => {
    findNameTariff();
  }, [alias]);

  useEffect(() => {
    if (!period || !alias) {
      return;
    }

    try {
      const id = TARIFF_JOIN_GOODS[alias][period];
      const searchTariff = products.find(item => item.id === id);

      sendAnalyticECommerce(searchTariff);
      effects.addToCart(dispatch, state, searchTariff.store_id);
    } catch (error) {
      console.error(error);
    }
  }, [period]);

  return (
    <View>
      <Top>
        <Title>
          <Caption>
            {tariff_alias === alias ? translations.t('store.cart.extend') : translations.t('store.cart.subscribe')}
          </Caption>
          <Name>{name}</Name>
        </Title>

        <Text>
          {translations.t(`store.cart.tariff_text.${alias}`)}
        </Text>
      </Top>

      <Bottom>
        <Switcher>
          <Switch>
            <input id="tariff-month" type="radio" name='tariff' value='month' checked={period === 'month'} onChange={onChangeInput}/>
            <label htmlFor="tariff-month">{translations.t('store.cart.tariff.month')}</label>
          </Switch>

          <Switch>
            <input id="tariff-year" type="radio" name='tariff' value='year' checked={period === 'year'} onChange={onChangeInput}/>
            <label htmlFor="tariff-year">{translations.t('store.cart.tariff.year')}</label>
          </Switch>
        </Switcher>
        <Pricing>
          {discount &&
            <Discounts>
              <OldPrice>{getObjViewPrice(tariff.view_currency, Math.round(tariff.view_price / 12))}/{translations.t('store.cart.tariff.month_short')}</OldPrice>
              <Discount>-{discount.percent}%</Discount>
            </Discounts>
          }

          {tariff.view_price > 0 &&
            <Price>
              {getObjViewPrice(tariff.view_currency, tariff.view_price)}
            </Price>
          }
        </Pricing>
      </Bottom>

      {displayTrialInfo && (
        <Warning><IconPlus />{translations.t('store.cart.tariff.warning.trial')}</Warning>
      )}

      {(displayWarning && !displayTrialInfo) && (
        <Warning><IWarning width={16} height={16} stroke={Colors.coal}/>{translations.t('store.cart.tariff.warning')}</Warning>
      )}
    </View>
  );
};

const View = styled.div`
  ${Mixins.transition('background-color')};
  width: 100%;
  margin-bottom: 1px;
  background-color: ${Colors.white};
  min-height: 98px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  color: ${Colors.night};
  
  &:first-child {
    border-radius: 10px 10px 0 0;
  }
  
  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  &:hover {
    background-color: ${!isMobile && Colors.azure};
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
`;
const Title = styled.div`
  
`;
const Caption = styled.div`
  font-size: 16px;
  line-height: 16px;
  margin-bottom: -5px;
`;
const Name = styled.div`
  font-size: 40px;
  line-height: 52px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  white-space: nowrap;
  color: ${Colors.night};
`;
const Text = styled.div`
  width: 230px;
  text-align: right;
  font-size: 16px;
  line-height: 19px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const Switcher = styled.div`
  width: 260px;
  height: 48px;
  display: flex;
`;
const Switch = styled.div`
  &:first-child {
    label {
      border-radius: 4px 0 0 4px;
    }
  }
  
  &:last-child {
    label {
      border-radius: 0 4px 4px 0;
    }
  }
  
  label {
    ${Mixins.transition('background-color, color')};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 130px;
    background-color: ${Colors.solitude};
    color: ${Colors.electric_blue};
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    
    &:hover {
      background-color: ${Colors.planoplan_blue};
      color: ${Colors.white};
    }
  }
  
  input {
    display: none;
    
    &:checked + label {
      background-color: ${Colors.electric_blue};
      color: ${Colors.white};
    }
  }
`;
const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;
const Discounts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3px;
  position: absolute;
  bottom: 100%;
  right: 0;
`;
const OldPrice = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.nobel};
  margin-right: 6px;
  white-space: nowrap;
  
  span {
    font-size: 14px;
  }
`;
const Discount = styled.div`
  padding: 0 3px 0 6px;
  background-color: ${Colors.frog_green};
  border-radius: 10px 2px 2px 10px;
  color: ${Colors.white};
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
`;
const Price = styled.div`
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.night};
  
  span {
    font-size: 24px;
  }
`;
const Warning = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 17px;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  font-weight: 300;
  margin-top: 15px;
  
  svg {
    fill: ${Colors.dark_cream};
    margin-right: 4px;
    flex-shrink: 0;
    margin-top: 1px;
  }
`;
