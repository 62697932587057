import { LocalStorage } from '@libs';
import { POP_USER_ID_FROM_TOKEN, POP_PROFILE } from '@globalLocalStorage';

let instance = null;

class Analytics {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.messages = {};

    return instance;
  }

  init({ locale, platform, unity_clientID }) {
    this.mode = 'unity';
    this.locale = locale || this.locale;
    this.platform = platform || this.platform;
    this.unity_clientID = unity_clientID || this.unity_clientID;
    this.isFrame = this.mode === 'demo' && window.location !== window.parent.location;
  }

  /**
   * Возвращает объект window - если форма открывается в айфрейме (напр. на демо), то общаемся с родительским окном
   * @returns {Window}
   * @private
   */
  _getWindow() {
    return this.isFrame ? window.parent : window;
  }

  load(user_id, tariff) {
    const name = tariff.is_trial &&  tariff.alias === 'proplus' ? 'Trial PRO+' : tariff.name;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      UserID: user_id,
      Tarif: name,
      Platform: this.platform || '',
      unity_clientID: this.unity_clientID || '',
    });
  }

  _pushPageView(title, category, action, label, userId) {
    const win = this._getWindow();

    if (!win.ga && !win.dataLayer) {
      return;
    }

    const modeTitle = this.mode === 'unity' ? 'standalone' : 'web';

    if (win.dataLayer && win.dataLayer.push) {
      const userIdFromToken = LocalStorage.get(POP_USER_ID_FROM_TOKEN) || '';

      win.dataLayer.push({
        event: 'Pageview',
        pagePath: `/#${this.locale}/${category}/${action}/${label}`,
        pageTitle: `${title} (${modeTitle})`,
        UserID: userIdFromToken
      });
    }
  }

  _pushEvent(category = '', action = '', label = '') {
    const win = this._getWindow();
    const profile = LocalStorage.get(POP_PROFILE);
    const userIdFromToken = LocalStorage.get(POP_USER_ID_FROM_TOKEN) || profile.id || null;

    if (!win.ga && !win.dataLayer) {
      return;
    }

    const eventData = {
      event: 'gaEvent',
      gaCategory: category,
      gaAction: action,
      gaLabel: label,
    };

    if (Number(userIdFromToken)) {
      eventData.UserID = userIdFromToken;
    }

    win.dataLayer.push(eventData);
  }

  pushView(title, category, action, label, userId) {
    this._pushPageView(title, category, action, label, userId);
  }

  pushEvent(category, action, label, user) {
    this._pushEvent(category, action, label, user);
  }

  pushEventGA4(evName, evParam1, evParam2, evParam3, evParam4) {
    if (!window.dataLayer) {
      return;
    }

    const params = {
      'event': 'ga4_Event',
      'evName': evName,
      'evParam1': evParam1,
      'evParam2': evParam2,
      'evParam3': evParam3,
      'evParam4': evParam4
    };

    for (let i = 1; i <= 4; i++) {
      if (typeof params[`evParam${i}`] === 'undefined') {
        delete params[`evParam${i}`];
      }
    }

    window.dataLayer.push(params);
  };

  push(props = { event: 'gaEvent' }, user = null) {
    const win = this._getWindow();

    if (!win.ga && !win.dataLayer) {
      return;
    }

    const userIdFromToken = LocalStorage.get(POP_USER_ID_FROM_TOKEN) || {};
    const data = {
      ...props,
      UserID: userIdFromToken,
    };

    win.dataLayer.push(data);
  }

  pushWelcome(fields) {
    const win = this._getWindow();

    if (!win.ga && !win.dataLayer) {
      return;
    }

    win.dataLayer.push({
      'Welсome-1': fields[1] ? fields[1].value : '',
      'Welсome-2': fields[2] ? fields[2].value : '',
    });
  }
}

export const analytics = new Analytics();
