import { actions } from '@globalInvoke';
import { DEBUG_LOG } from '@globalConstants';

export class Logging {
  static startRequest(method = 'GET', url = '') {
    const reqString = `${method.toUpperCase()} запрос на ${url}`;

    window.invokeEditorAction({
      name: actions[DEBUG_LOG].name,
      value: reqString,
      viewConsole: false,
    });
  }

  static endRequest(response = {}) {
    try {
      const { length, duration, status } = response;
      const { method, url } = response.config;
      const reqString = `${method.toUpperCase()} запрос на ${url}, Статус: ${status}, Длина: ${length}, Время: ${duration}мс`;

      window.invokeEditorAction({
        name: actions[DEBUG_LOG].name,
        value: reqString,
        viewConsole: false,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
