import { Cookie, LocalStorage } from '@libs';
import * as action  from './Store/action';
import { POP_SESSION } from '@globalCookie';
import { actions } from '@globalInvoke';
import { LOGOUT } from '@globalConstants';
import { POP_CURRENT_TEAM } from '@globalLocalStorage';
import { ON_OPEN_QUICK_STORE } from '@observer/constants';
import { TARIFF_ALIAS } from '../constants';

const observer = new window.POPObserver();

export const setCurrentTeam = () => (dispatch, getState) => {
  const { team: { id, list }} = getState(state => state);
  const currentTeam = id ? list.find(team => team.id === id) : null;

  if (currentTeam) {
    dispatch(action.setCurrentTeam(currentTeam));
  } else {
    dispatch(action.setCurrentTeam(null));
  }
};

export const logout = () => (dispatch, getState) => {
  const { settings: { onClose }} = getState();

  onClose();
  Cookie.remove(POP_SESSION);
  LocalStorage.remove(POP_CURRENT_TEAM);

  dispatch(action.removeProfile());

  window.invokeEditorAction({
    name: actions[LOGOUT].name,
  });
};

export const onOpenQuickStoreWithProplus = (props) => async (dispatch, getState) => {
  const {
    banner = 'lockproject',
    promocode = '',
    openProject = 0, // id проекта который нужно открыть
  } = props;

  try {
    const { goods, settings: { teamId, projectId }} = getState();

    if (!goods || !goods.length) {
      console.error(new Error('Goods not found'));

      return;
    }

    const tariff = goods.find(item => item?.extra_data?.tariff_alias === TARIFF_ALIAS.PROPLUS && item?.extra_data?.duration === 12);

    if (!tariff || !tariff.id) {
      console.error(new Error('Tariff not found'));

      return;
    }

    observer.postMessage(ON_OPEN_QUICK_STORE, {
      goods: tariff.id,
      bannerAlias: banner,
      team_id: teamId,
      project_id: projectId,
      openProject,
      promocode,
    });
  } catch (error) {
    console.error(error);
  }
};
