import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { List } from './list';
import { Item } from './item';

export const StoreItems = ({ title, items = [], isSubscribe = false }) => (
  <View>
    <Head>
      <Title>{title}</Title>
      <Caption>{translations.t('cabinet.balance.available')}</Caption>
    </Head>
    
    <List>
      {items.map((item, index) => (
        <Item item={item} key={index} isSubscribe={isSubscribe} />
      ))}
    </List>
  </View>
);

export const View = styled.div`
  padding: 20px;
`;
const Head = styled.div`
  display: flex,
`;
const Caption = styled.div`
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  color: ${Colors.second_gray};
`;
const Title = styled(Caption)`
  width: 280px;
`;