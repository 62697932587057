import { DOMAINS } from '@globalConstants';

export const isProdHost = window.location.host.indexOf('planoplan.com') !== -1;
export const isDevHost = window.location.host.indexOf('pp.ksdev.ru') !== -1;
export const isBeta = window.location.href.indexOf('beta-interfaces') !== -1;
export const uiHost = isBeta ? `${DOMAINS.PROD}/beta-interfaces` : `${isProdHost ? DOMAINS.PROD : DOMAINS.DEV}/interfaces`;
export const getHost = ({ short = false, prefix = true } = {}) => {
  if (window.location.host.indexOf('localhost') !== -1) {
    return short ? DOMAINS.DEV_SHORT : DOMAINS.DEV;
  }

  const host = short ? window.location.host : window.location.origin;

  return prefix ? host : host.replace('app.', '');
};
// export const isNewSiteDevHost = window.location.host.indexOf('new.pp.ksdev.ru') !== -1;
export const isLocalHost = window.location.host.indexOf('localhost') !== -1;
export const isPlanoplanLocal = window.location.host.indexOf('planoplan.local') !== -1;
