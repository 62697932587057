// Actions
export const SET_STEP = 'SET_STEP';
export const SET_PREV_STEP = 'SET_PREV_STEP';
export const SET_PROCESSING = 'SET_PROCESSING';
export const SET_SOCIAL_INFO = 'SET_SOCIAL_INFO';
export const SET_ERROR = 'SET_ERROR';
export const SET_CHECKING_EMAIL = 'SET_CHECKING_EMAIL';
export const SET_SIGN_UP_MODE = 'SET_SIGN_UP_MODE';
export const SET_LOGIN_MODE = 'SET_LOGIN_MODE';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_SELECTED_DOMAIN = 'SET_SELECTED_DOMAIN';
export const SET_UPDATE_STATUS = 'SET_UPDATE_STATUS';
export const SET_DOWNLOADED_PERCENT = 'SET_DOWNLOADED_PERCENT';
export const SET_CHECKING_MODE = 'SET_CHECKING_MODE';
export const SET_CAN_RESEND_CODE = 'SET_CAN_RESEND_CODE';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const SET_SURVEY_COMPLETED = 'SET_SURVEY_COMPLETED';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_IS_LOGIN = 'SET_IS_LOGIN';
export const SET_COUNTRY = 'SET_COUNTRY';

export const ENTRY_STEPS = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  CHECK: 'CHECK',
  WELCOME: 'WELCOME',
  SOCIAL: 'SOCIAL',
  RECOVERY_PASSWORD: 'RECOVERY_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  SUCCESS: 'SUCCESS',
  THANK: 'THANK',
  DOMAINS: 'DOMAINS',
  ERROR: 'ERROR',
  SKELETON: 'SKELETON',
  UPDATE: 'UPDATE',
  DOWNLOAD: 'DOWNLOAD',
  INPUT: 'INPUT',
};

export const WELCOME_SUB_STEPS = {
  first: 'first',
  second: 'second',
};

export const STEP_ALIAS = {
  login: ENTRY_STEPS.LOGIN,
  signup: ENTRY_STEPS.SIGNUP,
  check: ENTRY_STEPS.CHECK,
  welcome: ENTRY_STEPS.WELCOME,
  social: ENTRY_STEPS.SOCIAL,
  recovery_password: ENTRY_STEPS.RECOVERY_PASSWORD,
  new_password: ENTRY_STEPS.NEW_PASSWORD,
  success: ENTRY_STEPS.SUCCESS,
  thank: ENTRY_STEPS.THANK,
  domains: ENTRY_STEPS.DOMAINS,
  error: ENTRY_STEPS.ERROR,
  skeleton: ENTRY_STEPS.SKELETON,
  update: ENTRY_STEPS.UPDATE,
  download: ENTRY_STEPS.DOWNLOAD,
  input: ENTRY_STEPS.INPUT,
};

export const ENTER_METHOD = {
  login: 'login',
  signup: 'signup',
};

export const SIGN_UP_MODE = {
  EMAIL: 'email',
  VK: 'vk',
  FB: 'fb',
  GOOGLE: 'google',
};

export const LOGIN_MODE = {
  EMAIL: 'email',
  VK: 'vk',
  FB: 'fb',
  GOOGLE: 'google',
};

export const SOCIAL_MODE_TEXT ={
  vkontakte: 'Vkontakte',
  'vkontakte_popup': 'Vkontakte',
  google: 'Google',
};

export const ENTER_ROUTE_PATH = {
  google: 'auth/google.html',
  vk: 'auth/vk.html',
};

export const CHECKING_MODE = {
  CONFIRM_EMAIL_AFTER_LOGIN: 'CONFIRM_EMAIL_AFTER_LOGIN',
  CONFIRM_EMAIL_AFTER_SIGNUP: 'CONFIRM_EMAIL_AFTER_SIGNUP',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
  ACCOUNT_RECOVERY: 'ACCOUNT_RECOVERY',
};

export const ERROR_TYPES = {
  GLOBAL: 'GLOBAL',
  LOCAL: 'LOCAL',
};

export const UPDATE_STATUSES = {
  CHECKING: 'CHECKING',
  INSTALLING: 'INSTALLING',
  NOT_REQUIRED: 'NOT_REQUIRED',
  ERROR_UPDATE: 'ERROR_UPDATE',
};

export const UPDATE_STATUSES_ALIAS = {
  checking: UPDATE_STATUSES.CHECKING,
  installing: UPDATE_STATUSES.INSTALLING,
  notRequired: UPDATE_STATUSES.NOT_REQUIRED,
  error: UPDATE_STATUSES.ERROR_UPDATE,
};

// Invoke
export const TRY_AGAIN_UPDATE = 'TRY_AGAIN_UPDATE';
export const ENTRY_SUCCESS = 'ENTRY_SUCCESS';

// Params
export const RECAPTCHA_KEY = '6Ld8A8sUAAAAAOmaiTZJEJabBhqoSKq7_V-BnmD7';

export const VK_KEY_DEV = 3192013;
export const VK_KEY_MASTER = 3362197;
export const GOOGLE_KEY_DEV = '935546481838-cip8qr7fprdi7va90jsarcp5gnl19sl7.apps.googleusercontent.com';
export const GOOGLE_KEY_MASTER = '223326650666-kmfo9al8si1smhq8sdhouittgnc9d90v.apps.googleusercontent.com';
export const YOUTUBE_VIDEO = 'https://www.youtube.com/embed/Q5oTJVOmcIQ';

export const EMAIL_REGEXP =
  '^[-._a-zA-Za-яA-я0-9]{1,}@([-a-z-A-Z-a-я-А-Я0-9]+\\.)+[a-z-A-Z-a-я-А-Я]{2,6}$';

export const LENGTH_EMAIL_CODE = 6;
export const NAME_CODE_REGEXP = /^[А-Яа-яA-Za-zßüÜöÖäÄ][А-Яа-я-'\wßüÜöÖäÄ ]+$/;
export const RESEND_CODE_TIMEOUT = 300; // cек

export const SELECTED_USER_CATEGORIES_ID = [
  'sell-furniture-materials',
  'sell-real-estate',
  'run-studio',
  'produce-furniture-materials',
];

export const AUTH_ERROR_MESSAGES = {
  deletedAccountDetected: 'Deleted account detected',
  duplicateRegistrationDetected: 'Duplicate registration detected',
}

// Observer subscribe 
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';