import React, { memo } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { TextWithLinks } from '@components/TextWithLinks';
import { translations } from '@libs';

export const StatusLayout = memo(({ children, title = '', text = '', redirect = '' }) => {
  const handleClickCopy = () => copy(redirect);

  return (
    <View>
      <Content>
        <Icon>{children}</Icon>
        <Title>{title}</Title>
        <TextWithLinks Tag={Text} text={text} />
        {redirect && (
          <RedirectLink>
            <RedirectLinkText>{redirect}</RedirectLinkText>
            <Button theme="yellow_black" size="S" widthText={true} onClick={handleClickCopy}>{translations.t('store.cart.copy.link')}</Button>
          </RedirectLink>  
        )}
      </Content>
    </View>
  );
});

const View = styled.div`
  width: 720px;
  height: 430px;
  background-color: ${Colors.night};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.coal};
`;
const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`;
const Icon = styled.div`
  margin-bottom: 30px;
`;
const Title = styled.div`
  color: ${Colors.light_gray};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
`;
const Text = styled.div`
  color: ${Colors.light_gray};
  font-size: 12px;
  text-align: center;
  
  a {
    text-decoration: none;
    color: ${Colors.dark_cream};
  }
`;
const RedirectLink = styled.div`
  padding: 0 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    border: none;
    box-shadow: none;
    padding: 0 15px;
  }
`;
const RedirectLinkText = styled.div`
  color: ${Colors.dark_cream};
  font-size: 12px;
  line-height: 14px;
  font-family: 'CoFoSans', 'sans-serif';
  margin: 10px 0;
  word-break: break-all;
`;