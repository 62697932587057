import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { getRange } from '../../../modules/normalize';

export const Pagination = ({
  pageRange,
  currentPage,
  pageCount,
  onPageChange
}) => {
  const [start, end] = getRange(pageRange, currentPage, pageCount);
  const hasNext = currentPage < pageCount;
  const hasPrev = currentPage > 1;
  const pages = [];

  for (let i = start; i <= end; i++) {
    pages.push(i);
  }

  return (
    <Container>
      {hasPrev && (
        <PrevButton onClick={() => onPageChange(currentPage - 1)}>
          {translations.t('cabinet.pagination.prev')}
        </PrevButton>
      )}
      <PageList>
        {pages.map((item) => (
          <Page active={currentPage === item} key={item} onClick={() => onPageChange(item)}>
            {item}
          </Page>
        ))}
      </PageList>
      {hasNext && (
        <NextButton onClick={() => onPageChange(currentPage + 1)}>
          {translations.t('cabinet.pagination.next')}
        </NextButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Button = styled.button`
  ${Mixins.transition('background-color color')};
  box-shadow: none;
  border: 1px solid ${Colors.main_blue};
  background: ${(p) => (p.active ? Colors.main_blue : 'none')};
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(p) => (p.active ? Colors.white : Colors.feldgrau)};
  padding: 10px 14px;
  cursor: ${(p) => (p.active ? 'auto' : 'pointer')};
  outline: none;

  &:hover {
    border-color: ${Colors.main_blue};
    background-color: ${Colors.main_blue};
    color: ${Colors.white};
  }
`;

const Page = styled(Button)`
`;

const PageList = styled.div`
  display: flex;

  & ${Button}:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & ${Button}:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const NavButton = styled(Button)`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled(NavButton)`
  width: 100px;
  margin-left: 20px;
`;

const PrevButton = styled(NavButton)`
    width: 100px;
    margin-right: 20px;
`;
