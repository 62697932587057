import {
  SET_PROCESSING,
  SET_STEP,
  SET_SOCIAL_INFO,
  SET_ERROR,
  SET_CHECKING_EMAIL,
  SET_SIGN_UP_MODE,
  SET_LOGIN_MODE,
  SET_DOMAINS,
  SET_SELECTED_DOMAIN,
  SET_PREV_STEP,
  SET_UPDATE_STATUS,
  SET_DOWNLOADED_PERCENT,
  SET_CHECKING_MODE,
  SET_CAN_RESEND_CODE,
  SET_FIRST_LOGIN,
  SET_SURVEY_COMPLETED,
  SET_PROFILE,
  SET_IS_LOGIN,
  SET_COUNTRY,
  SET_LOCALE,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case SET_PREV_STEP:
      return { ...state, prevStep: action.payload };
    case SET_SIGN_UP_MODE:
      return { ...state, signUpMode: action.payload };
    case SET_LOGIN_MODE:
      return { ...state, loginMode: action.payload };
    case SET_CHECKING_MODE:
      return { ...state, checkingMode: action.payload };
    case SET_PROCESSING:
      return { ...state, isProcessing: action.payload };
    case SET_CAN_RESEND_CODE:
      return { ...state, isCanResendCode: action.payload };
    case SET_DOMAINS:
      return { ...state, domains: action.payload };
    case SET_SELECTED_DOMAIN:
      return { ...state, selectedDomain: action.payload };
    case SET_CHECKING_EMAIL:
      return { ...state, checkingEmail: action.payload };
    case SET_SOCIAL_INFO:
      return {
        ...state,
        socialInfo: {
          name: action.payload.name,
          avatar: action.payload.avatar,
        },
      };
    case SET_UPDATE_STATUS:
      return { ...state, updateStatus: action.payload };
    case SET_DOWNLOADED_PERCENT:
      return { ...state, downloadedPercent: action.payload };
    case SET_ERROR:
      return {
        ...state,
        error: {
          type: action.payload.type,
          message: action.payload.message,
          step: action.payload.step,
          data: action.payload.data,
        },
      };
    case SET_FIRST_LOGIN:
      return {...state, firstLogin: action.payload,};
    case SET_SURVEY_COMPLETED:
      return {...state, surveyCompleted: action.payload,};
    case SET_PROFILE:
      return {...state, profile: action.payload,};
    case SET_IS_LOGIN:
      return {...state, isLogin: action.payload,};
    case SET_COUNTRY:
      return {...state, country: action.payload};
    case SET_LOCALE:
      return {
        ...state, 
        settings: {
          ...state.settings,
          locale: action.payload,
          localeUi: action.payload,
        }
      };
    default:
      return state;
  }
};
