import { url } from '@globalInvoke';
import { URL_OPEN, UNAUTH_URL_OPEN } from '@globalConstants';
import { ON_OPEN_STORE } from '@observer/constants';
// import from libs не работает

export const invokeEditorAction = ({ name, value, viewConsole = true }) => {
  const EditorInvokeFunction = window.EditorInvokeFunction;

  // для тестирования
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    window.POPInvokeEditorActionScope = {
      ...window.POPInvokeEditorActionScope,
      [name]: value || {},
    };
  }

  // Проверяем открывает ли ссылка магазин
  if (runStoreFromInvokeOpenUrl(name, value)) {
    return;
  }

  try {
    EditorInvokeFunction(name, value);

    if (!window.location.host.indexOf('planoplan.com') !== -1 && process.env.NODE_ENV !== 'test' && viewConsole) {
      console.info('%cEDITOR INVOKE MESSAGE', 'color: #228B22', { [name]: value });
    }
  } catch (e) {
    console.error(e);
  }
};

const EditorInvokeFunction = (name, value, ...restProps) => {
  if (typeof window[value] === 'function') {
    window[value](restProps);
  }
};

const runStoreFromInvokeOpenUrl = (name, value) => {
  const observer = new window.POPObserver();
  
  if ((name === url[URL_OPEN].name || name === url[UNAUTH_URL_OPEN].name) && value.includes('https://planoplan.com/ru/cabinet/')) {
    try {
      const url = new URL(value);
      const tariff = url.searchParams.get('tariff');
      const promocode = url.searchParams.get('promocode');
      const products = url.searchParams.getAll('product[]');
      const quantities = url.searchParams.getAll('quantity[]');

      let initialCart = {};

      products.forEach((product, index) => {
        initialCart[product] = parseInt(quantities[index], 10) || 1;
      });
      
      const storeOptions = {
        cartTariff: tariff ? tariff : null,
        initialScreen: tariff ? 'cart' : 'pricing',
        promocode: promocode ? promocode : null,
        initialCart,
      };

      observer.postMessage(ON_OPEN_STORE, storeOptions);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  }
};

window.EditorInvokeFunction = EditorInvokeFunction;
