import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { ON_OPEN_STORE } from '@observer/constants';
import { translations } from '@libs';
import { TARIFF_ALIAS } from '../../../constants';
import { IconFire } from '../../../components/icons';

const observer = new window.POPObserver();

export const Promo = () => {
  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, {
      cartTariff: TARIFF_ALIAS.PROPLUS,
      initialScreen: 'card',
    });
  };

  return (
    <View>
      <Content>
        <Title dangerouslySetInnerHTML={{ __html: translations.t('cabinet.promo.title') }} />
        <Caption>{translations.t('cabinet.promo.caption')}</Caption>
        <ButtonWrap>
          <Button theme="secondary" size="S" onClick={openStore}>
            {translations.t('cabinet.promo.button')}
          </Button>
        </ButtonWrap>
        <Description>{translations.t('cabinet.promo.description')}</Description>
      </Content>

      <Icon>
        <IconFire/>
      </Icon>
    </View>
  );
};

const View = styled.div`
  margin-top: 10px;
  width: 610px;
  background-color: ${Colors.second_link};
  border-radius: 5px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  margin-right: 50px;
  color: ${Colors.white};
  font-family: 'CoFoSans';
  font-size: 16px;
  line-height: 18px;

  button {
    color: ${Colors.main_dark};
  }
`;
const Title = styled.div`
  font-size: 40px;
  line-height: 44px;
  font-weight: 500;
  margin-bottom: 8px;
`;
const Caption = styled.div`
  margin-bottom: 20px;
`;
const ButtonWrap = styled.div`
  max-width: 257px;
`;
const Description = styled.div`
  margin-top: 20px;
`;
const Icon = styled.div``;