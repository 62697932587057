import { getCurrentPrices, getGoodsName } from './helpers';
import { GOODS_CATEGORY_ALIAS, TARIFF_ALIAS } from '../constants';

const pushEventGA4 = (evName, evParam1, evParam2, evParam3, evParam4) => {
  window.dataLayer = window.dataLayer || [];

  const params = {
    'event': 'ga4_Event',
    'evName': evName,
    'evParam1': evParam1,
    'evParam2': evParam2,
    'evParam3': evParam3,
    'evParam4': evParam4
  };

  for (let i = 1; i <= 4; i++) {
    if (typeof params[`evParam${i}`] === 'undefined') {
      delete params[`evParam${i}`];
    }
  }

  window.dataLayer.push(params);
};

const pushEcommerce = (event = '', items = [], additional = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: items,
      ...additional,
    }
  });
};

const getItem = ({ price }) => (dispatch, state) => {
  const { selected: { currentGoods }, bannerAlias, goods: { main, isTariff }} = state();
  
  if (!main) return;

  const goodsName = dispatch(getGoodsName());

  return [{
    item_name: isTariff ? currentGoods.caption : currentGoods.name,
    price,
    item_category: GOODS_CATEGORY_ALIAS[main.alias.toLowerCase()],
    item_list_name: isTariff ? `locked_feature_${bannerAlias}` : `buy_render_${goodsName}`,
    quantity: 1
  }];
};

export const Analytics = {
  buyRender: () => (dispatch, state) => {
    const { settings: { project_id }, bannerAlias, goods: { main, isTariff }} = state();

    if (!main) return;

    const alias = bannerAlias ? bannerAlias : dispatch(getGoodsName());

    if (isTariff) {
      return;
    }

    pushEventGA4('project_event', 'buy_render', project_id, alias, 'banner_view');
  },
  addToCart: () => (dispatch, state) => {
    try {
      const { goods: { main }} = state();

      if (!main) return;

      const { price } = dispatch(getCurrentPrices());
      const item = dispatch(getItem({ price }));

      pushEcommerce('add_to_cart', item);
    } catch (error) {
      console.error(error);
    }
  },
  tariffSubscription: () => (dispatch, state) => {
    try {
      const { goods: { main, isTariff}, bannerAlias, profile, settings: { project_id }} = state();

      if (!main) return;

      const alias = bannerAlias ? bannerAlias : dispatch(getGoodsName());

      if (!isTariff) {
        return;
      }

      const tariffAlias = profile.tariff.is_trial ? TARIFF_ALIAS.TRIAL_PROPLUS : profile.tariff.alias;

      pushEventGA4('tariff_subscription_lf', tariffAlias, main.extra_data.tariff_alias);
      pushEventGA4('project_event', 'locked_feature', project_id, alias, 'banner_view');
    } catch (error) {
      console.error(error);
    }
  },
  viewItem: (price) => (dispatch, state) => setTimeout(() => {
    const { goods: { main }} = state();

    if (!main) return;

    const item = dispatch(getItem({ price }));

    pushEcommerce('view_item', item);
  }, 0),
  viewCart: () => (dispatch, state) => setTimeout(() => {
    const { goods: { main }} = state();

    if (!main) return;

    const { price } = dispatch(getCurrentPrices());
    const item = dispatch(getItem({ price }));

    pushEcommerce('view_cart', item);
  }, 500),
  applyPromoCode: () => (dispatch, state) => {
    const { goods: { main }, appliedPromo: { active_from, active_to, discount }, selected: { promoCode }} = state();

    if (!main) return;

    const startDate = active_from ? new Date(active_from * 1000).toLocaleDateString() : '';
    const endDate = active_to ? new Date(active_to * 1000).toLocaleDateString() : '';

    pushEventGA4('apply_promo_code', promoCode, startDate, endDate, discount);
  },
  getPay: () => (dispatch, state) => {
    try {
      const {
        settings: { project_id },
        bannerAlias,
        goods: { main, isTariff },
        selected: { currentGoods },
        profile
      } = state();

      if (!main) return;

      const { price } = dispatch(getCurrentPrices());
      
      const alias = bannerAlias ? bannerAlias : dispatch(getGoodsName());
      const item = dispatch(getItem({ price }));

      pushEventGA4('project_event', isTariff ? 'locked_feature' : 'buy_render', project_id, alias, 'banner_click');
      pushEventGA4(
        'store_payment_lf',
        profile.tariff.alias,
        isTariff ? currentGoods.extra_data.tariff_alias : '',
        isTariff ? [] : [main.caption],
        price
      );
      pushEcommerce('begin_checkout', item);

    } catch (error) {
      console.error(error);
    }
  },
  successPay: () => (dispatch, state) => {
    const {
      bannerAlias,
      goods: { main, isTariff },
      selected: { currentGoods },
      profile
    } = state();

    if (!main) return;
    
    const { price } = dispatch(getCurrentPrices());
    
    pushEventGA4(
      'store_success_lf',
      profile.tariff.alias,
      isTariff ? `${currentGoods.extra_data.tariff_alias}|locked_feature_${bannerAlias}` : '',
      isTariff ? '' : `${main.caption}|buy_render_${bannerAlias}`,
      price
    );
  },
};
