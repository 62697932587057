import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ITrash } from 'planoplan-ui-kit/core/icons/i-trash';
import { DETAIL, CART, GOODS_TARIFF_ALIAS, TARIFF_ALIAS } from '../../../constants';
import { tablet_landscape, laptop } from '../../../modules/breakpoints';
import { StoreContext } from '../../../modules/Store/Store';
import { getAnalyticParamsRender, getAnalyticParamsTariff, getObjViewPrice, getDiscountProduct } from '../../../modules/helpers';
import * as effects from '../../../modules/effects';
import { Quantity } from '../../../components/Quantity';
import { TariffCart } from '../../../components/TariffCart';
import { translations } from '@libs';
import { Analytics } from '../../../modules/analytics';

export const Table = ({ elements }) => {
  const [state, dispatch] = useContext(StoreContext);
  const tariffElement = elements.find((el) => GOODS_TARIFF_ALIAS.includes(el.alias));
  const filteredElements = elements.filter((el) => !GOODS_TARIFF_ALIAS.includes(el.alias));
  const { cart } = state;
  const [hoverCountOptions, setHoverCountOptions] = useState({
    hover: false,
    changed: false,
  });
  const changePriceForSet = (id, price, index, options) => {
    const { autoSet } = options;

    effects.changePriceForSet(dispatch, state, id, price, index);

    if (!autoSet) {
      try {
        sendECommerce();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const addCountGood = (id) => {
    effects.changeCountProduct(dispatch, state, id, 'add');
    setHoverCountOptions({ ...hoverCountOptions, changed: true });
  };
  const removeCountGood = (id) => {
    effects.changeCountProduct(dispatch, state, id, 'remove', { inCart: true });
    setHoverCountOptions({ ...hoverCountOptions, changed: true });
  };
  const onClearCart = () => {
    const analyticItems = cart.map(item => {
      if (item.alias === 'tariff' || [TARIFF_ALIAS.BUSINESS, TARIFF_ALIAS.TEAM2].includes(item.alias)) {
        return getAnalyticParamsTariff(item);
      }

      return getAnalyticParamsRender(item);
    });

    Analytics.ecommerceRemoveCart(analyticItems);
    Analytics.clearCart(state);
    effects.clearCart(dispatch, state);
  };
  const [activeCount, setActiveCount] = useState(0);

  useEffect(() => {
    filteredElements.map((item) => {
      const amountOfSets = item.in_sets?.filter((itemSets) => itemSets.grouping !== null && itemSets.grouping !== '');
      if(amountOfSets?.length) {
        changePriceForSet(amountOfSets[item.activeCount].store_id, amountOfSets[item.activeCount].price, item.activeCount, { autoSet: true });
      }
    })
  }, [activeCount]);

  const setProductScreen = (id) => {
    // effects.setProductScreen(dispatch, state, id, DETAIL, CART);
  };

  const countMouseEnter = () => setHoverCountOptions({ hover: false, changed: false });
  const countMouseLeave = () => {
    if (!hoverCountOptions.changed) {
      return;
    }

    sendECommerce();
  };
  const sendECommerce = () => {
    const analyticItems = elements.map(item => {
      if (item.alias === 'tariff' || [TARIFF_ALIAS.BUSINESS, TARIFF_ALIAS.TEAM2].includes(item.alias)) {
        return getAnalyticParamsTariff(item);
      }

      return getAnalyticParamsRender(item);
    });

    Analytics.ecommerceViewCart(analyticItems);
  };

  return (
    <View>
      <Items>
        {tariffElement && <TariffCart tariff={tariffElement} />}
        {filteredElements.map((item, index) => {
          const amountOfSets = item.in_sets?.filter((item) => item.grouping !== null && item.grouping !== '').sort((a, b) => a.count - b.count);
          const discount = getDiscountProduct(amountOfSets, item.activeCount, item.view_count);

          return (
            <Row key={item.store_id} data-test={`cart__item-${index}`}>
              <Top>
                <Name
                  onClick={() => {
                    setProductScreen(item.store_id);
                  }}>
                  <Title>{item.view_name}</Title>
                  <Title>{item.view_size}</Title>
                </Name>
                {amountOfSets?.length > 0 && (
                  <NumberGoods>
                    <Item>{translations.t('store.common.pcs')}</Item>
                    {amountOfSets.map((itemSets, index) => {
                      return (
                        <Item key={index}>
                          <ButtonSets
                            key={index}
                            active={item.activeCount === index}
                            type="button"
                            onClick={() => {
                              changePriceForSet(itemSets.store_id, itemSets.price, index, { autoSet: false });
                            }}>
                            {itemSets.count}
                          </ButtonSets>
                        </Item>
                      );
                    })}
                  </NumberGoods>
                )}
                {/*<CountPack />*/}
              </Top>
              <Bottom>
                <Quantity
                  count={item.view_count}
                  add={() => addCountGood(item)}
                  remove={() => removeCountGood(item)}
                  onMouseEnter={countMouseEnter}
                  onMouseLeave={countMouseLeave}
                />
                {item.view_price > 0 && (
                  <Pricing>
                    {discount &&
                      <Discounts>
                        <OldPrice>{getObjViewPrice(item.view_currency, discount.sum)}</OldPrice>
                        <Discount>-{discount.percent}%</Discount>
                      </Discounts>
                    }
                    <Price data-test="cart__item-price">{getObjViewPrice(item.view_currency, item.view_price)}</Price>
                  </Pricing>
                )}
              </Bottom>
            </Row>
          );
        })}
      </Items>
      <Clean>
        <span onClick={onClearCart}>{translations.t('store.cart.clear')}</span>
      </Clean>
    </View>
  );
};

const View = styled.div`
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.coal};

  @media ${tablet_landscape} {
    ${Grid.column(20, 32)};
  }

  @media ${laptop} {
    ${Grid.column(15, 24)};
  }
`;
const Items = styled.div`
  width: 100%;
`;
const Clean = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.nobel};
  margin-top: 10px;

  span {
    cursor: pointer;
  }
`;

const Row = styled.div`
  ${Mixins.transition('background-color')};
  padding: 20px 30px;
  width: 100%;
  margin-bottom: 1px;
  background-color: ${Colors.white};
  min-height: 98px;
  display: flex;
  flex-direction: column;

  &:first-child {
    border-radius: 10px 10px 0 0;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  @media ${tablet_landscape} {
    min-height: 104px;
  }

  &:hover {
    background-color: ${!isMobile && Colors.azure};
  }
`;

const Top = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Bottom = styled.div`
  margin-top: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  line-height: 19px;
`;

const Name = styled.div`
  margin-right: 10px;
  width: 100%;
  cursor: pointer;
`;

const Title = styled.p`
  color: ${Colors.night};
  font-size: 16px;
  line-height: 19px;
`;

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;
const Discounts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3px;
  position: absolute;
  bottom: 100%;
  right: 0;
`;
const OldPrice = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-decoration: line-through;
  color: ${Colors.nobel};
  margin-right: 6px;

  span {
    font-size: 14px;
  }
`;
const Discount = styled.div`
  padding: 0 3px 0 6px;
  background-color: ${Colors.frog_green};
  border-radius: 10px 2px 2px 10px;
  color: ${Colors.white};
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
`;
const Price = styled.div`
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.night};

  span {
    font-size: 24px;
  }
`;

const NumberGoods = styled.ul`
  display: flex;
  align-items: flex-end;
`;
const Item = styled.li`
    list-style-type: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9E9E9E;
`;

const ButtonSets = styled.button`
    margin-left: 8px;
    padding: 5 10px;
    width: 40px;
    height: 29px;
    border-radius: 5px;
    border: ${(p) => (p.active ? '1px solid #269CFF' : '1px solid #E5EAF1')};
    background: none;
    cursor: pointer;
    &:hover {
      border: 1px solid #269CFF;
    }
`;

