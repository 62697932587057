import { LocalStorage } from '@libs';
import { POP_USER_SETTINGS } from '@globalLocalStorage';
import { ApiCall } from '@features/notification-events/modules/api-call';

export const checkAndOpenGettingStarted = ({ callback = () => {} }) => {
  try {
    const userSettings = LocalStorage.get(POP_USER_SETTINGS);
    const showedGettingStarted = userSettings?.unitySettings?.showedGettingStarted;
    const isShow = showedGettingStarted ? JSON.parse(showedGettingStarted.toLowerCase()) === false : true;

    setTimeout(async () => {
      if (isShow) {
        callback();
        await setShowedGettingStarted();
      }
    }, 2000);

    return isShow;
  } catch (error) {
    console.error(error);
  }
};

export const setShowedGettingStarted = async () => {
  try {
    const userSettings = LocalStorage.get(POP_USER_SETTINGS);

    if (!userSettings) {
      console.error('UserSettings is undefined');

      return;
    }

    const params = {
      name: 'showedGettingStarted',
      value: 'true',
      checksum: userSettings.unitySettingsChecksum
    };

    const response = await ApiCall.setUserSettings(params);
    const { data: { success, errorText, data } } = response;

    if (!success) {
      console.error(errorText);

      return;
    }

    const newUserSettings = {
      ...userSettings,
      unitySettings: {
        ...userSettings.unitySettings,
        showedGettingStarted: 'true'
      },
      unitySettingsChecksum: data.unitySettingsChecksum
    };

    LocalStorage.set(POP_USER_SETTINGS, newUserSettings);
  } catch (error) {
    console.error(error);
  }
};
