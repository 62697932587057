import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { getLocaleUi, ScrollLock } from '@libs';
import { getUserSettings, getBanners, saveUserSettings, getTariffs, getPricing } from './modules/effects';
import { SHOW_BANNER } from './constants';
import { interfaces } from '@globalInvoke';
import { POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE } from '@globalConstants';
import { LocalStorage } from '@libs';
import { POP_PRICING, POP_BANNERS, POP_TARIFFS, POP_GROUP_BANNERS } from '@globalLocalStorage';
import ErrorBoundary from './ErrorBoundary';

export default class POPBanners {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения модуля не указан html элемент');
    this.mode = settings.mode || 'site'; // unity, site
    this.locale = settings.locale || 'en';
    this.localeUi = getLocaleUi(this.locale);
    this.processing = false;
    this.useBodyScrollLock = settings.useBodyScrollLock !== false;
    this.team_id = settings.team_id;
    this.alias = settings.alias;
    this.groups = settings.groups || [];
    this.action = settings.action || undefined;
    this.count = settings.count || 0;
    this.arrBanners = settings.arrBanners || undefined;
  }

  async createPoints({ tariff, team_id, alias, groups }) {
    this.processing = true;
    this.tariff = tariff;
    this.team_id = team_id;
    this.tariffsContent = LocalStorage.get(POP_TARIFFS);
    this.pricing = LocalStorage.get(POP_PRICING);

    if (groups && groups.length) {
      const result = await Promise.all(
        groups.map(async (group) => {
          const banners = await getBanners(this.locale, group);

          /* Блок кода для сохранения баннеров что бы в дальнейшем брать групповые баннеры без запроса */
          const expire = 5 * 60 * 1000;
          const localGroupBanners = LocalStorage.get(POP_GROUP_BANNERS) || [];
          const localGroupBanner =
            localGroupBanners &&
            localGroupBanners.find((item) => {
              return item.locale === this.locale && item.group === group && item.expireAt > Date.now();
            });
          const saveLocalGroupBanners = localGroupBanners.filter((item) => item.expireAt > Date.now());

          if (!localGroupBanner) {
            saveLocalGroupBanners.push({
              group,
              locale: this.locale,
              data: banners,
              expireAt: Date.now() + expire,
            });
          }

          LocalStorage.set(POP_GROUP_BANNERS, saveLocalGroupBanners);
          /* end */

          return banners;
        })
      );

      this.banners = result.flat();
      this._createEvents(SHOW_BANNER, this.banners[this.count]);
      this.count++;
    } else {
      this.banners = LocalStorage.get(POP_BANNERS);

      const banner = this.banners.find((item) => item.alias === alias);

      if (banner) {
        this._createEvents(SHOW_BANNER, banner);
      }
    }

    await saveUserSettings(this.team_id, this.tariff);
    this.processing = false;
  }

  _createEvents(event = '', banner = {}) {
    this.events = {
      [event]: banner,
    };
  }

  on(event = '') {
    if (this.processing) {
      setTimeout(() => {
        this.on(event);
      }, 0);
    } else {
      this._run(event);
    }
  }

  /**
   * Передает в юнити: заблокировать проход кликов через интерфейс
   * @private
   */
  _editorInvokeOpen() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  _run(event) {
    try {
      const banner = this.events[event];

      if (!banner || !banner.id) {
        throw null;
      }

      this._mount(banner);
      this._editorInvokeOpen();
    } catch (err) {
      console.warn('Данный баннер с таким алиасом не найден');
    }
  }

  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }

  close() {
    this._unMount();

    if (!window.cabinetIsOpen && !window.galleryIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount(banner) {
    const rootElement = document.getElementById(this.root);

    if (this.useBodyScrollLock) {
      ScrollLock.lock(rootElement);
    }

    ReactDOM.render(
      <ErrorBoundary close={this.close.bind(this)}>
        <App
          locale={this.locale}
          banner={banner}
          settings={this.settings}
          tariffsContent={this.tariffsContent}
          goods={this.pricing}
          count={this.count}
          amountBanners={this.banners.length}
        />
      </ErrorBoundary>,
      rootElement
    );
  }
}

if (!window.POPBanners) {
  window.POPBanners = POPBanners;
}
