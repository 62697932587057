import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { url } from '@globalInvoke';
import { translations } from '@libs';
import { URL_OPEN } from '@globalConstants';
import { ON_OPEN_STORE } from '@observer/constants';
import { TARIFF_ALIAS } from '../../../constants';
import { Analytics } from '../../../modules/analytics';
import { pluralWord } from '../../../modules/helpers';
import { getStepCancelSubscribe } from '../effects';
import { SubscribeCancel } from './subscribe-cancel';

const observer = new window.POPObserver();

export const Tariff = () => {
  const dispatch = useDispatch();
  const { tariff, profile, goods, stepsUnsubscribe } = useSelector((state) => state);
  const expireDate = tariff.expire ? new Date(tariff.expire * 1000).toLocaleDateString('ru-RU') : null;
  const currency = getCurrency(profile.currency);
  const goodsTariff = useMemo(() => goods.find(item => item.id === tariff.goods_id) || {}, [tariff]);
  const [openCancel, setOpenCancel] = useState(false);
  const [loadingCancelData, setLoadingCancelData] = useState(false);
  const tariffPeriod = goodsTariff?.extra_data?.duration === 1 ? translations.t('cabinet.subscribe.period.month') : translations.t('cabinet.subscribe.period.year');

  const isViewOnly = tariff.alias === TARIFF_ALIAS.VIEW_ONLY;
  const isProOrProPlus = (tariff.alias === TARIFF_ALIAS.BASIC || tariff.alias === TARIFF_ALIAS.PROPLUS) && !tariff.is_trial;
  const isTeam = tariff.alias === TARIFF_ALIAS.TEAM;
  const isTrial = tariff.is_trial;

  const isCancelled = isProOrProPlus && Boolean(!tariff.goods_id);

  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, isCancelled ? {
      cartTariff: tariff.alias,
      initialScreen: 'card',
    } : {});

    Analytics.clickChangeOrExtend(tariff.alias);
  };
  const openSubscribeCancel = async () => {
    Analytics.clickCancelSubscribe(tariff.alias);
    
    if (loadingCancelData) return;

    if (!stepsUnsubscribe || !stepsUnsubscribe.length) {
      setLoadingCancelData(true);
      await dispatch(getStepCancelSubscribe());
      setLoadingCancelData(false);
    }

    setOpenCancel(true);
  };

  const getPluralWorld = (count, key) => {
    try {
      const plural = JSON.parse(translations.t(key).replace(/'/g, '"'));

      return pluralWord(count, plural);
    } catch (error) {
      return '';
    }
  };

  if (isViewOnly) {
    return (
      <View>
        <Wrap>
          <Top>
            <Caption>{translations.t('cabinet.subscribe.tariff')}</Caption>
          </Top>
          <Name>{tariff.name}</Name>
        </Wrap>

        <Buttons>
          <StoreButton>
            <Button theme="primary" widthText size="S" onClick={openStore}>{translations.t('cabinet.subscribe.tariff.change')}</Button>
          </StoreButton>
        </Buttons>
      </View>
    )
  }

  if (isTeam) {
    const openMail = () => {
      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: 'mailto:corporate@planoplan.com',
      });
    };

    return (
      <View>
        <Top>
          <Caption>{translations.t('cabinet.subscribe.tariff')}</Caption>
          <Caption>{tariff.license_count} {getPluralWorld(tariff.license_count, 'cabinet.subscribe.license.work')} {getPluralWorld(tariff.license_count, 'cabinet.subscribe.license.place')}</Caption>
        </Top>
        <Name>{tariff.name}</Name>
        {expireDate && <Caption>{translations.t('cabinet.subscribe.active_until')} {expireDate}</Caption>}
        <Buttons>
          <StoreButton>
            <Button theme="primary" widthText size="S" onClick={openMail}>{translations.t('cabinet.subscribe.tariff.request')}</Button>
          </StoreButton>
        </Buttons>
      </View>
    )
  }

  if (isProOrProPlus) {
    return (
      <View>
        <Top>
          <Caption>{translations.t('cabinet.subscribe.tariff')}</Caption>
          {!isViewOnly && <Caption>{isCancelled ? translations.t('cabinet.subscribe.cancelled') : `${tariffPeriod} ${translations.t('cabinet.subscribe.period_post')}`}</Caption>}
        </Top>
        <Name>{tariff.name}</Name>
        {expireDate && <Caption>{isCancelled ? `${translations.t('cabinet.subscribe.active_until')} ${expireDate}` : `${goodsTariff.price} ${currency} ${translations.t('cabinet.subscribe.deducted')} ${expireDate}`}</Caption>}
        <Buttons>
          <StoreButton>
            <Button theme="primary" widthText size="S" onClick={openStore}>{isCancelled ? translations.t('cabinet.subscribe.tariff.extend') : translations.t('cabinet.subscribe.tariff.change')}</Button>
          </StoreButton>

          {!isCancelled && (
            <CancelButton>
              <Button theme="secondary" widthText size="S" onClick={openSubscribeCancel}>{translations.t('cabinet.subscribe.cancel')}</Button>
              {loadingCancelData && <LoadingCancel><Preloader width="32px" height="32px" fill={Colors.main_blue} /></LoadingCancel>}
            </CancelButton>
          )}
        </Buttons>

        <SubscribeCancel open={openCancel} setOpen={setOpenCancel} />
      </View>
    )
  }

  return (
    <View>
      <Top>
        <Caption>{translations.t('cabinet.subscribe.tariff')}</Caption>
      </Top>
      <Name>{isTrial ? 'TRIAL PRO+' : tariff.name.toUpperCase()}</Name>

      {expireDate && <Caption>{translations.t('cabinet.subscribe.active_until')} {expireDate}</Caption>}
      <Buttons>
        <StoreButton>
          <Button theme="primary" widthText size="S" onClick={openStore}>{translations.t('cabinet.subscribe.tariff.change')}</Button>
        </StoreButton>
      </Buttons>
    </View>
  );
};

const View = styled.div`
  width: 400px;
  margin-right: 10px;
  padding: 17px 20px 20px;
  background-color: ${Colors.main_beige};
  border-radius: 5px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Wrap = styled.div``;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Caption = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.main_dark};
`;
const Name = styled.div`
    font-size: 48px;
    line-height: 58px;
    color: ${Colors.main_blue};
    font-weight: 500;
    margin-bottom: 8px;
`;
const Buttons = styled.div`
  margin-top: 8px;
  display: flex;
  
  button {
    height: 40px;
    font-size: 16px;
  }
`;
const StoreButton = styled.div`
  margin-right: 10px;
`;
const CancelButton = styled.div`
  position: relative;
  
  button {
    ${Mixins.transition('color, background-color, box-shadow')};
    background-color: transparent;
    border-color: ${Colors.main_dark};
    color: ${Colors.main_dark};

    &:hover {
      color: ${Colors.main_dark};
      border-color: ${Colors.main_dark};
      box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
        3px 4px 5px 0px ${Colors.main_dark}12,
        6px 9px 7px 0px ${Colors.main_dark}0A,
        11px 16px 8px 0px ${Colors.main_dark}03,
        17px 25px 8px 0px ${Colors.main_dark}00;
    }

    &:active {
      color: ${Colors.white};
      background-color: ${Colors.main_black};
      box-shadow: none;
    }
  }
`;
const LoadingCancel = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #E6EAF0;
`;
