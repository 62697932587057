import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IconChevron } from '../../../components/icons';

export const Breadcrumbs = ({ setFolderId = () => {} }) => {
  const { projects: { breadcrumbs }} = useSelector(state => state);

  return (
    <View>
      <Item>
        <Text isClickable={breadcrumbs.length} onClick={() => setFolderId(0)}>{translations.t('cabinet.projects.myProjects')}</Text>
        {(breadcrumbs) && <IconChevron />}
      </Item>

      {breadcrumbs && breadcrumbs.map((item, index) => (
        <Item key={item.id}>
          <Text isClickable={Boolean(breadcrumbs[index + 1])} onClick={() => setFolderId(item.id)}>{item.name}</Text>
          <IconChevron />
        </Item>
      ))}
    </View>
  )
};

const View = styled.ul`
  ${Mixins.resetList()};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.feldgrau};
  margin-bottom: -5px;
`;
const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 6px;
  }
  
  &:last-child {
    svg {
      display: none;
    }
  }
  
  svg {
    margin-bottom: -2px;
  }
`;
const Text = styled.span`
  ${Mixins.transition('color')};
  margin-right: 6px;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    color: ${({ isClickable }) => isClickable ? Colors.main_blue : 'inherit'};
  }
`;
