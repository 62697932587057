import React, { memo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { useSelector } from 'react-redux';
import { InfoTariff } from '../InfoTariff';
import { InfoPanorama } from '../InfoPanorama';
import { InfoRender } from '../InfoRender';

export const Information = memo(() => {
  const { banner, goodsId, goods: { isTariff, isPanorama }} = useSelector(state => state);

  return (
    <View isBanner={Boolean(banner.alias)} onlyBanner={!Boolean(goodsId)}>
      {Boolean(banner.alias) && <InfoTariff />}
      {!Boolean(banner.alias) && isPanorama && <InfoPanorama/>}
      {(!Boolean(banner.alias) && !isPanorama && !Boolean(isTariff)) && <InfoRender />}
    </View>
  );
});

const View = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.night};
  border-radius: ${({ onlyBanner }) => onlyBanner ? '10px' : '10px 0 0 10px'} ;
  width: ${({ isBanner }) => isBanner ? '360px' : '640px'};
  height: 100%;
  overflow: hidden;
`;
