import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { useSelector } from 'react-redux';
import { isLocalHost } from '@libs';
import { IconNotification } from '../../../components/icons';
import { ON_OPEN_NOTIFICATION_EVENTS, ON_CALL_COUNT_NOTIFICATION_EVENTS, ON_SEND_COUNT_NOTIFICATION_EVENTS } from '@observer/constants'

const observer = new window.POPObserver();

export const Notification = () => {
  const { team, settings: { zoom } } = useSelector(state => state);
  const [count, setCount] = useState(0);

  const openNotificationEvents = (event) => {
    const { top, right, height } = event.target.getBoundingClientRect();
    // На локалке почему то не сходятся с размерами юнити браузера, поэтому умножать приходится на 1
    const innerZoom = isLocalHost ? 1 : zoom;

    const coordinates = {
      top: ((top * innerZoom) + (height * innerZoom)) / zoom,
      right: (window.innerWidth - (right * innerZoom)) / zoom
    };

    observer.postMessage(ON_OPEN_NOTIFICATION_EVENTS, {
      team_id: team.id,
      coordinates,
    });
  }

  useEffect(() => {
    // Отправляем событие что бы notification-events отправил обратно ON_SEND_COUNT_NOTIFICATION_EVENTS с количеством уведомлений
    observer.postMessage(ON_CALL_COUNT_NOTIFICATION_EVENTS, { teamId: team.id });
    observer.addEventListener(ON_SEND_COUNT_NOTIFICATION_EVENTS, (count => setCount(count)));

    const interval = setInterval(() => {
      observer.postMessage(ON_CALL_COUNT_NOTIFICATION_EVENTS, { teamId: team.id });
    }, 60 * 1000);

    return () => {
      observer.removeEventListener(ON_SEND_COUNT_NOTIFICATION_EVENTS);
      clearInterval(interval);
    }
  }, []);

  return (
    <View>
      <Button onClick={openNotificationEvents}>
        <IconNotification />
        {Boolean(count) && <Count>{count}</Count>}
      </Button>
    </View>
  )
};

const View = styled.div`
  position: relative;
`;
const Button = styled.div`
  ${Mixins.transition('background-color, border-color, box-shadow')};    
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.main_dark};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  
  svg {
    ${Mixins.transition('fill')};
    pointer-events: none;
  }
  
  &:hover {
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    background-color: ${Colors.main_black};
    box-shadow: none;
    
    svg path {
      stroke: ${Colors.white};
    }
  }
`;
const Count = styled.div`
  position: absolute;
  top: -3px;
  right: -6px;
  padding: 4px;
  border-radius: 10px;
  background-color: ${Colors.main_blue};
  color: ${Colors.white};
  font-size: 12px;
  line-height: 12px;
  height: 20px;
  min-width: 20px;
  text-align: center;
  font-family: 'FavoritPro';
  pointer-events: none;
`;
