import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { createRows } from '../../../modules/normalize';
import { IArrowPrev } from 'planoplan-ui-kit/core/icons/i-arrow-prev';

export const Table = ({ columns, data }) => {
  const [sortOrder, setSortOrder] = useState({
    slug: 'id',
    direction: 'asc',
  });

  const sort = (slug) => {
    if (sortOrder.slug === slug) {
      setSortOrder({
        slug,
        direction: sortOrder.direction === 'desc' ? 'asc' : 'desc',
      })
    } else {
      setSortOrder({
        slug,
        direction: 'asc'
      })
    }
  };

  const getValueForSort = (slug, item) => {
    const slugs = slug.split('.');
    let val = item;

    slugs.forEach((slug) => {
      val = val[slug];
    });

    return val;
  };

  data.sort((a, b) => {
    const { slug, direction } = sortOrder;
    const aVal = getValueForSort(slug, a);
    const bVal = getValueForSort(slug, b);

    if (typeof bVal === 'number') {
      return direction === 'desc' ? bVal - aVal : aVal - bVal;
    }

    if (!aVal.toLowerCase || !bVal.toLowerCase) {
      return 0;
    }

    if (aVal.toLowerCase() < bVal.toLowerCase()) {
      return direction === 'desc' ? -1 : 1;
    }

    if (aVal.toLowerCase() > bVal.toLowerCase()) {
      return direction === 'desc' ? 1 : -1;
    }

    return 0;
  });

  const rows = createRows(data, columns);

  return (
    <TableContent>
      <TableHeader>
        {columns.map(({ header, sortable, style, accessor, align }, i) => (
          <HeaderCol key={i} onClick={() => sortable && sort(accessor)} sortable={sortable} style={style} align={align}>
            <HeaderColText>
              {header}
              <SortIndicator isRotate={sortOrder.slug === accessor && sortOrder.direction === 'desc'} isActive={sortOrder.slug === accessor}>
                <IArrowPrev fill={Colors.main_blue} width={7} height={7} />
              </SortIndicator>
            </HeaderColText>
          </HeaderCol>
        ))}
      </TableHeader>

      <TableBody>
        {rows.map((row, i) => (
          <TableRow key={i}>
            {row.map((cell, i) => (
              <TableCol key={i} style={cell.style} align={cell.align}>{cell.render}</TableCol>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </TableContent>
  );
};

const SortIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  opacity: ${({ isActive }) => isActive ? 1 : 0};
  transform: translateY(-50%);
  margin-left: 3px;

  svg {
    ${Mixins.transition('transform')};
    display: block;
    transform: rotate(${({ isRotate }) => (isRotate ? `${-90+180}deg` : '-90deg')});
  }
`;

const TableContent = styled.div``;
const TableHeader = styled.div`
  display: flex;
  user-select: none;
`;
const HeaderCol = styled.div`
  ${Mixins.transition('color')};
  font-family: 'CoFoSans', 'sans-serif';
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.second_gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${({ align }) => align === 'left' ? '20px 0 20px 20px' : '20px 20px 20px 0'};
  text-align: ${({ align }) => align || 'left'};

  ${({ sortable }) =>sortable && css`
    cursor: pointer;

    &:hover {
      color: ${Colors.gray};
    }
  `}
`;
const HeaderColText = styled.span`
  position: relative;
`;
const TableBody = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-radius: 10px;
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.feldgrau25};
  }
`;
const TableCol = styled.div`
  padding: 20px;
  text-align: ${p => p.align || 'left'};
`;