export const filterNotification = (notifications) => {
  return notifications.filter((notify) => {
    if (notify.data.tariff) {
      if (!notify.data.tariff.expire) {
        return false;
      }

      const dataTime = new Date(notify.data.tariff.expire).getTime();
      const timeView = 3 * 24 * 60 * 60 * 1000; // 3 дня в секундах. За сколько сек будет выводиться уведомление

      if (dataTime < Date.now()) {
        return false;
      }

      return dataTime - timeView < Date.now();
    }

    return true;
  });
};

export const setGetParamsToUrl = (url = '', params = {}) => {
  const urlGet = new URLSearchParams();
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (typeof params[key] === 'string' || typeof params[key] === 'number') {
        urlGet.append(key, params[key]);
      }

      if (Array.isArray(params[key])) {
        params[key].forEach((item) => {
          urlGet.append(`${key}[]`, item);
        });
      }
    }
  }

  return `${url}${separator}${urlGet.toString()}`;
};