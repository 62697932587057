import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { actions } from '@globalInvoke';
import { PAYMENT_SUCCESSFUL, MODE_ALIAS, UNITY } from '@globalConstants';
import { Indent } from '../../modules/indent';
import { Table } from './organism/table';
import { Payment } from './organism/payment';
import { tablet_landscape } from '../../modules/breakpoints';
import { Message } from '../../components/Message';
import * as effects from './effects';
import { StoreContext } from '../../modules/Store/Store';
import { ID_PAY_METHOD_PAYPAL, PAY_STATUSES, TYPES_GOODS, TARIFF_ALIAS } from '../../constants';
import { Analytics } from '../../modules/analytics';
import { ErrorBoundary } from '@components/ErrorBoundary';
import { TextWithLinks } from '@components/TextWithLinks';
import { clearCart } from '../../modules/effects';
import { getAnalyticParamsRender, getAnalyticParamsTariff } from '../../modules/helpers';

export const Cart = ({ cart, payment }) => {
  const view_count = 1;
  const [state, dispatch] = useContext(StoreContext);
  const [count, setCount] = useState(view_count);
  const [successText, setSuccessText] = useState({
    goods: [],
    tariff: ''
  });
  const { settings: { mode, locale, unMount } } = state;

  const callbackError = () => {
    clearCart(dispatch, state);
  };
  const handleClickCopyLInk = (link) => copy(link);

  useEffect(() => {
    if ((payment.status === PAY_STATUSES.processing || payment.status === PAY_STATUSES.pending) && payment.orderid) {
      effects.checkStatusPay(dispatch, state, { orderid: payment.orderid });
    }

    if (payment.status === PAY_STATUSES.succeeded && payment.cart) {
      const sortingArr = ['tariff_12', 'tariff_1', 'businessvr_12', 'businessvr_1', 'render', 'panorama'];
      const cartCategories = [...new Set(payment.cart.map(item => {
        const alias = item.view_alias === 'panorama' ? 'render' : item.view_alias;

        return item.duration ? `${alias}_${parseInt(item.duration)}` : alias;
      }))].sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b));
      const text = { goods: [translations.t('store.cart.success')], tariff: '' };
      const cartIds = payment.cart.map(item => item.store_id);

      cartCategories.forEach(alias => {
        switch (alias) {
          case 'tariff_12': text.tariff = translations.t('store.cart.success.tariffYear'); break;
          case 'tariff_1': text.tariff = translations.t('store.cart.success.tariffMonth'); break;
          case 'businessvr_12': text.tariff = translations.t('store.cart.success.tariffYear'); break;
          case 'businessvr_1': text.tariff = translations.t('store.cart.success.tariffMonth'); break;
          case TYPES_GOODS.render: text.goods.push(translations.t('store.cart.success.render')); break;
          // case TYPES_GOODS.panorama: text.goods.push(translations.t('store.cart.success.panorama')); break;
        }
      });

      if (MODE_ALIAS[mode] === UNITY) {
        window.invokeEditorAction({
          name: actions[PAYMENT_SUCCESSFUL].name,
          value: cartIds,
        });
      }

      setSuccessText(text);
    }

    if (payment.status === PAY_STATUSES.error && payment.method && `${payment.method.mode}` === `${ID_PAY_METHOD_PAYPAL}`) {
      setTimeout(() => {
        unMount();
      }, 10000);
    }
  }, [payment]);

  useEffect(() => {
    const analyticItems = cart.map(item => {
      if (item.alias === 'tariff' || [TARIFF_ALIAS.BUSINESS, TARIFF_ALIAS.TEAM2].includes(item.alias)) {
        return getAnalyticParamsTariff(item);
      }

      return getAnalyticParamsRender(item);
    });

    Analytics.viewCart(mode, locale);
    Analytics.ecommerceViewCart(analyticItems);
  }, []);

  return (
    <ErrorBoundary errorInfo={{ title: `${translations.t('store.cart.error')} support@planoplan.com` }} callback={callbackError}>
      <View>
        {cart.length && !payment.status ? (
          <>
            <Table elements={cart} count={count} setCount={setCount} />
            <Payment cart={cart} payment={payment} />
          </>
        ) : null}

        {!cart.length && !payment.status ? (
          <NotResult>
            <Text
              dangerouslySetInnerHTML={{
                __html: translations.t('store.cart.empty'),
              }}
            />
          </NotResult>
        ) : null}

        {payment.status === PAY_STATUSES.succeeded && (
          <Message type="SUCCESS">
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: successText.goods.join('</br>'),
                }}
              />

              {Boolean(successText && successText.tariff) &&
                <TextBottom dangerouslySetInnerHTML={{
                  __html: successText.tariff,
                }} />
              }
            </div>
          </Message>
        )}

        {(payment.status === PAY_STATUSES.pending || payment.status === PAY_STATUSES.unpaid) && (
          <Message type="WARNING">
            <span
              dangerouslySetInnerHTML={{
                __html: translations.t('store.cart.pending'),
              }}
            />
            <TextBottom dangerouslySetInnerHTML={{
              __html: translations.t(payment?.redirect ? 'store.cart.pending.caption.redirect' : 'store.cart.pending.caption'),
            }} />
            {payment?.redirect && (
              <LinkPay>
                <LinkPayText>{payment.redirect}</LinkPayText>
                <LinkPayButton>
                  <Button theme="primary" size={"S"} widthText={true} onClick={() => handleClickCopyLInk(payment.redirect)}>{translations.t('store.cart.copy.link')}</Button>
                </LinkPayButton>
              </LinkPay>
            )}
          </Message>
        )}

        {payment.status === PAY_STATUSES.processing && (
          <Message type="WARNING">
            <span
              dangerouslySetInnerHTML={{
                __html: translations.t('store.cart.processing'),
              }}
            />
          </Message>
        )}

          {payment.status === PAY_STATUSES.wrong && (
              <Message type="WRONG">
                  <>
                      <span
                          dangerouslySetInnerHTML={{
                              __html: translations.t(payment.value)
                          }}
                      />
                  </>
              </Message>
          )}

        {payment.status === PAY_STATUSES.error && (
          <Message type="ERROR">
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: translations.t('store.cart.error'),
                }}
              />
              <TextWithLinks Tag={TextBottom} text={translations.t('store.cart.error.caption')}/>
            </>
          </Message>
        )}
      </View>
    </ErrorBoundary>
  );
};

const View = styled.div`
  height: 100%;
  
  @media ${tablet_landscape} {
    ${Grid.row()};
  }
`;

const Text = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.coal};
  text-align: center;
`;

const NotResult = styled.div`
  ${Indent.margin_top(2)};
  width: 100%;
`;

const TextBottom = styled.div`
  color: ${Colors.night};
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  font-weight: 400;
  max-width: 550px;
  
  a {
    text-decoration: none;
    color: ${Colors.planoplan_blue};
  }
`;
const LinkPay = styled.div`
  margin-top: 10px;
  padding: 0 60px;
`;
const LinkPayText = styled.div`
  color: ${Colors.night};
  font-size: 12px;
  line-height: 14px;
  font-family: 'CoFoSans', 'Museo', sans-serif;
  font-weight: 400;
  word-break: break-all;
`;
const LinkPayButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    font-size: 12px;
    border-radius: 4px;
    height: 24px;
    padding: 0 15px;
  }
`;