import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ERROR, WARNING, SUCCESS, WRONG } from '../../constants';
import { IconSuccess, IconError, IconPending } from '@icons';

export const Message = ({ type, children }) => (
  <View>
    {type === SUCCESS && <IconSuccess width="228px" height="228px" stroke={Colors.frog_green} />}

    {type === ERROR && <IconError width="228px" height="228px" stroke={Colors.colar} />}

    {type === WARNING && <IconPending width="228px" height="228px" stroke={Colors.electric_blue} />}

    {type === WRONG && <IconError width="228px" height="228px" stroke={Colors.colar} />}

    <Text>{children}</Text>
  </View>
);

const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  justify-content: center;
`;

const Text = styled.div`
  color: ${Colors.night};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
