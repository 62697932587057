import React from 'react';

export const IconPin = ({ width = 32, height = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="5" fill="#F9F4F1" />
    <g clipPath="url(#clip0_4915_3304)">
      <path d="M13.1504 18.8492L18.1001 23.799L17.7465 17.7886L22.6963 12.8388L26.2318 12.1317L19.8679 5.76777L19.1608 9.3033L14.211 14.253L8.2006 13.8995L13.1504 18.8492ZM13.1504 18.8492L6.4201 25.5" stroke="#292929" strokeLinejoin="bevel" />
    </g>
    <defs>
      <clipPath id="clip0_4915_3304">
        <rect width="24" height="24" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
