import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { deviceDetected } from 'planoplan-ui-kit/core/device-detected';
import { OS_ALIAS, OS_OPTIONS } from '@globalConstants';
import { isProdHost, getLocaleUi } from '@libs';
import { ON_LOADING_OFF_SCREEN_ENTRY, ON_INIT_GOOGLE_SDK } from '@observer/constants';
import { App } from './app';
import {
  STEP_ALIAS,
  ENTRY_STEPS,
  RECAPTCHA_KEY,
  UPDATE_STATUSES,
  UPDATE_STATUSES_ALIAS,
  ERROR_TYPES,
  ENTER_METHOD,
  LOGIN_MODE, GOOGLE_KEY_MASTER, GOOGLE_KEY_DEV,
} from './constants';
import { createAppStore, initialState } from './modules/Store';
import { analytics } from './modules/analytics';
import { ApiCall } from './modules/api-call';
import * as ApiSocial from './modules/api-social';
import * as login from './modules/login';
import * as signup from './modules/signup';
import * as effects from './modules/effects';
import { setInstallingPercent,
  setUpdateError,
  setUpdateNotRequired,
  checkAutoInput,
  runStandaloneWithParams,
} from './modules/effects';

export default class POPFormEntry {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения формы не указан html элемент');
    this.mode = settings.mode || 'site'; // unity, site, demo - для демо на webgl
    this.updateStatus = UPDATE_STATUSES_ALIAS[settings.updateStatus] || UPDATE_STATUSES.NOT_REQUIRED;
    this.autoInput = settings.autoInput;
    this.social = settings.social;
    this.firstEntry = settings.firstEntry;
    this.locale = settings.locale;
    this.appLanguages = settings.appLanguages;

    analytics.init({
      locale: settings.locale,
      platform: settings.platform,
      unity_clientID: settings.unity_clientID,
    });

    this._subscribes();
    this._hideRecaptcha();
  }

  /**
   * Содержит подписки на события в других модулях
   * @private
   */
  _subscribes() {
    const observer = new window.POPObserver();

    observer.addEventListener(ON_LOADING_OFF_SCREEN_ENTRY, () => this._checkAutoInput());
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    const url = new URLSearchParams(window.location.search);

    this.initialStep = STEP_ALIAS[url.get('initialStep')] || STEP_ALIAS[params.initialStep] || ENTRY_STEPS.LOGIN;
    this.locale = url.get('locale') || this.settings.locale || 'en';
    this.localeUi = getLocaleUi(this.locale);
    this.currentOS = deviceDetected.isMac ? OS_ALIAS.MAC : OS_ALIAS.WINDOWS;
    this.isAfterOpenProject = params.isAfterOpenProject;

    /* На сайте отключаем регистрацию */
    if (this.initialStep === STEP_ALIAS.signup && this.mode === 'site') {
      this.initialStep = STEP_ALIAS.login;
    }

    ApiCall.setSettings({ locale: this.locale }); // ui доступен только для ru и en языках

    this._mount();

    this._loadGoogleSDK(() => {
      const observer = new window.POPObserver();

      if (this.social && this.social.code && this.social.mode === LOGIN_MODE.GOOGLE) {
        this._autoLoginFromGoogle();
      }

      window.google.accounts.id.initialize({
        client_id: isProdHost ? GOOGLE_KEY_MASTER : GOOGLE_KEY_DEV,
        auto_select: false,
        cancel_on_tap_outside: true,
        callback: (props) => {
          const { credential } = props;

          this.store.dispatch(effects.checkGoogle({
            locale: this.locale,
            code: credential
          }))
        }
      });

      window.google.accounts.id.prompt();

      observer.postMessage(ON_INIT_GOOGLE_SDK);
    });

    if (this.social && this.social.code && this.social.mode === LOGIN_MODE.VK) {
      this._autoLoginFromVk();
    }
  }

  /**
   * Авто вход в гугл имея код гугла
   * @private
   */
  _autoLoginFromGoogle() {
    const { code, method, data } = this.social;

    if (!code) {
      return;
    }

    try {
      const { name, avatar } = data ? JSON.parse(decodeURI(atob(data))) : {};
      const profile = { name, avatar };
      const gCode = atob(code);

      if (this.social.method === ENTER_METHOD.login) {
        this.store.dispatch(login.getGoogleCode({ code: gCode, profile }));
      } else if (method === ENTER_METHOD.signup) {
        this.store.dispatch(signup.googleSignUp({ code: gCode, profile }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Авто вход в гугл имея код Vk
   * @private
   */
  _autoLoginFromVk() {
    const { code, method } = this.social;

    if (!code) {
      return;
    }

    if (this.social.method === ENTER_METHOD.login) {
      this.store.dispatch(login.getVKCode({ vkCode: code, mode: 'vkontakte_popup' }));
    } else if (method === ENTER_METHOD.signup) {
      this.store.dispatch(signup.vkSignUp({ vkCode: code, mode: 'vkontakte_popup' }));
    }
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  close() {
    const rootElement = document.getElementById(this.root);

    if (rootElement) {
      ReactDOM.unmountComponentAtNode(rootElement);
    }
  }

  /**
   * Установка процента загрузки обновления
   * @private
   */
  setInstallingPercent(value) {
    this.updateStatus = UPDATE_STATUSES.INSTALLING;
    this.store.dispatch(setInstallingPercent(value));
  }

  /**
   * Установвка статуса об отсутствии необходимости в обновлении и переключение на экран логина
   * @private
   */
  setUpdateNotRequired() {
    try {
      this.updateStatus = UPDATE_STATUSES.NOT_REQUIRED; // на случай если форма не успела заинититься
      this.store.dispatch(setUpdateNotRequired());

      if (this.social && this.social.code) {
        return true;
      }

      this._checkAutoInput();

      return true;
    } catch (error) {
      return error;
    }
  }

  /**
   * Установка статуса ошибки обновления и переключение на экран ошибки
   * @private
   */
  setUpdateError() {
    this.updateStatus = UPDATE_STATUSES.ERROR_UPDATE;
    this.store.dispatch(setUpdateError());
  }

  /**
   * Получение кода авторизации от гугла
   * @private
   */
  getGoogleCode({method = 'signup'}) {
    const params = new URLSearchParams(window.location.search.substring(1));
    const token = params.get('token');

    const getCode = async () => {
      let code = token;

      if (!code) {
        await ApiSocial.googleCall({ux_mode: 'redirect'});
      }

      if (code) {
        runStandaloneWithParams(`code=${btoa(code)}&mode=${LOGIN_MODE.GOOGLE}&method=${method}`);
      }
    };

    this._loadGoogleSDK(getCode);
  }


  /**
   * Получение кода авторизации от гугла
   * @private
   */
  async getVkCode({method = 'signup'}) {
    const params = new URLSearchParams(window.location.search.substring(1));
    let code = params.get('code');

    if (!code) {
      ApiSocial.vkCallRedirect();
    } else {
      runStandaloneWithParams(`code=${code}&mode=${LOGIN_MODE.VK}&method=${method}`);
    }
  }

  /**
   * Проверка и выполнение автовхода при возможности
   */
  _checkAutoInput() {
    this.store.dispatch(checkAutoInput());
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();

    this.store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={this.store}>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
          <App />
        </GoogleReCaptchaProvider>
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    if (this.updateStatus === UPDATE_STATUSES.ERROR_UPDATE) {
      this.initialStep = ENTRY_STEPS.ERROR;
      initialState.error = {
        type: ERROR_TYPES.GLOBAL,
        step: ENTRY_STEPS.UPDATE,
      };
    }

    return {
      ...initialState,
      step: this.initialStep,
      updateStatus: this.updateStatus,
      standaloneLink: this._getStandaloneLink(),
      settings: {
        locale: this.locale,
        localeUi: this.localeUi, // язык для интерфейса из массива поддерживаемых языков
        mode: this.mode,
        autoInput: this.autoInput,
        os: this.currentOS,
        isAfterOpenProject: this.isAfterOpenProject, // Отправить событие на открытие проект после входа / регистрации
        firstEntry: this.firstEntry,
        social: this.social,
        appLanguages: this.appLanguages,
        unity_clientID: this.settings.unity_clientID,
      },
    };
  }

  /**
   * Загрузка GoogleSDK для авторизации
   * @private
   */
  _loadGoogleSDK(callback = () => {}) {
    if (window.google && window.google.accounts) {
      callback();

      return;
    }

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.async = true;
      js.id = id;
      js.src = 'https://accounts.google.com/gsi/client';
      js.onload = () => {
        callback();
      };
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }

  /**
   * Скрытие рекапчти
   * @private
   */
  _hideRecaptcha() {
    document.head.insertAdjacentHTML('beforeend', `<style>.grecaptcha-badge { visibility: hidden; }</style>`);
  }

  /**
   * Получает линку для загрузки стендалона
   * @private
   */
  _getStandaloneLink() {
    return OS_OPTIONS[this.currentOS].downloadLink;
  }
}

if (!window.POPFormEntry) {
  window.POPFormEntry = POPFormEntry;
}
