import { ApiCall } from './api-call';
import { LocalStorage } from '@libs';
import { POP_USER_SETTINGS } from '@globalLocalStorage';
import { setChecksum, setLastNoticeId } from './Store/action';

const saveLocalStorageUserSettings = ({ lastReadedNotis, unitySettingsChecksum }) => {
  const userSettings = LocalStorage.get(POP_USER_SETTINGS);
  const dataLocalStorage = {
    ...userSettings,
    unitySettings: {
      ...userSettings.unitySettings,
      lastReadedNotis,
    },
    unitySettingsChecksum,
  };

  LocalStorage.set(POP_USER_SETTINGS, dataLocalStorage);
};

export const setUserSettings = ({ lastNoticeId }) => async (dispatch, getState) => {
  const { checksum, settings: { teamId } } = getState(state => state);

  const request = async (settingsChecksum) => {
    try {
      const params = {
        name: 'lastReadedNotis',
        value: lastNoticeId,
        checksum: settingsChecksum
      };
      const sendData = {
        team_id: teamId
      };

      const response = await ApiCall.setUserSettings(params, sendData);
      const { data: { success, errorText, data } } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);

          return;
        } else {
          throw new Error(errorText);
        }
      }

      dispatch(setChecksum(data.unitySettingsChecksum));
      dispatch(setLastNoticeId(lastNoticeId));
      saveLocalStorageUserSettings({
        unitySettingsChecksum: data.unitySettingsChecksum,
        lastReadedNotis: lastNoticeId,
      });
    } catch (e) {
      console.warn(e);
    }
  };

  await request(checksum);
};
