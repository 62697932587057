// Actions
export const SET_IMAGES = 'SET_IMAGES';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_CHECKED_IMAGES = 'SET_CHECKED_IMAGES';
export const SET_DOWNLOAD_ALL = 'SET_DOWNLOAD_ALL';
export const SET_REMOVE = 'SET_REMOVE';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_LAZY_IMAGES = 'SET_LAZY_IMAGES';
export const SET_CREATING_PDF = 'SET_CREATING_PDF';
export const SET_HAS_PDF = 'SET_HAS_PDF';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_RELOAD_GALLERY = 'SET_RELOAD_GALLERY';
export const SET_PROFILE_GOODS = 'SET_PROFILE_GOODS';
export const CHECK_PRODUCT = 'CHECK_PRODUCT';
export const CREATE_RENDER = 'CREATE_RENDER';
export const PAY_GOODS = 'PAY_GOODS';
export const PAY_PRODUCT = 'PAY_PRODUCT';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_GALLERY = 'GET_GALLERY';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_GOODS = 'GET_GOODS';
export const GET_BINDING_CARD = 'GET_BINDING_CARD';

// Mode
export const MODE_CLOSE = {
  WITH_OPEN_UI: 'WITH_OPEN_UI',
};

export const MODE_STORE_OPEN = {
  RENDERS: 'RENDERS',
  REVIEW: 'REVIEW',
  CART: 'CART',
};

export const TASK_STATUS = {
  waiting: 0,
  inprocess: 1,
  complete: 2,
  cancel: 3,
  fail: 4,
  reject: 5,
};

export const STATUSES = {
  [TASK_STATUS.waiting]: 'waiting',
  [TASK_STATUS.inprocess]: 'inprocess',
  [TASK_STATUS.complete]: 'complete',
  [TASK_STATUS.cancel]: 'cancel',
  [TASK_STATUS.fail]: 'fail',
  [TASK_STATUS.reject]: 'reject',
};

export const ZOOM = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
  RESET: 'RESET',
  CHANGE_IMAGE: 'CHANGE_IMAGE',
};

export const RENDER_SIZES_ALIAS = {
  PREVIEW: 'PREVIEW',
  SD: 'SD',
  HD: 'HD',
  FullHD: 'FullHD',
  QHD: 'QHD',
  '4K': '4K',
};
export const RENDER_SIZES = {
  450: RENDER_SIZES_ALIAS.SD,
  800: RENDER_SIZES_ALIAS.SD,
  720: RENDER_SIZES_ALIAS.HD,
  1280: RENDER_SIZES_ALIAS.HD,
  1080: RENDER_SIZES_ALIAS.FullHD,
  1920: RENDER_SIZES_ALIAS.FullHD,
  1440: RENDER_SIZES_ALIAS.QHD,
  2560: RENDER_SIZES_ALIAS.QHD,
  2160: RENDER_SIZES_ALIAS['4K'],
  3840: RENDER_SIZES_ALIAS['4K'],
};

export const RENDER_SIZES_ORDER = [RENDER_SIZES_ALIAS.SD, RENDER_SIZES_ALIAS.HD, RENDER_SIZES_ALIAS.FullHD, RENDER_SIZES_ALIAS.QHD, RENDER_SIZES_ALIAS['4K']];

export const COMPARISON_RENDERS = {
  [RENDER_SIZES_ALIAS.PREVIEW]: 'https://uikit.planoplan.com/static/images/common/standard.jpg',
  [RENDER_SIZES_ALIAS.HD]: 'https://uikit.planoplan.com/static/images/common/hd.jpg',
  [RENDER_SIZES_ALIAS.FullHD]: 'https://uikit.planoplan.com/static/images/common/full_hd.jpg',
  [RENDER_SIZES_ALIAS.QHD]: 'https://uikit.planoplan.com/static/images/common/qhd.jpg',
  [RENDER_SIZES_ALIAS['4K']]: 'https://uikit.planoplan.com/static/images/common/4k.jpg',
}

// Errors
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';

// Links
export const YOUTUBE_VIDEO_1 = 'https://www.youtube.com/embed/iAKnwn55TC0';
export const YOUTUBE_VIDEO_2 = 'https://www.youtube.com/embed/mtONdt1OI3A';

// Params
export const STEP_ZOOM = 0.4;

export const ZOOM_PARAMS = {
  MIN: 0.5,
  MAX: 8,
  STEP_WHEEL: 9.5,
  STEP_WHEEL_FULLSCREEN: 400, //хз почему такой большой
  STEP_BUTTON: 4.7,
};
export const AUTO_UPDATE_TIMEOUT = 10000;

// Invoke actions
export const BUTTONS = {
  GALLERY_CLOSE: 'GALLERY_CLOSE',
  DOWNLOAD: 'DOWNLOAD',
  CREATE_RENDER: 'CREATE_RENDER',
};

export const GALLERY_OPEN = 'GALLERY_OPEN';
export const GALLERY_CLOSE = 'GALLERY_CLOSE';

export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
  TEAM: 'businessvr',
  TEAM2: 'teamedu',
};

// Save mods
export const SAVE_MODE = {
  new: 'new',
  current: 'save'
};

/* Условия для метки Q+ (Q+ выводим смотря на время релиза) */
export const Q_PLUS_CONDITION = {
  time: new Date('2023-07-04:00:00:00:000').getTime(),
  height: 2160,
};

// Тарифы которым доступены доп. функционалы
export const TARIFF_PASS_FEATURES = [
  TARIFF_ALIAS.PROPLUS,
  TARIFF_ALIAS.BUSINESS,
  TARIFF_ALIAS.TEAM2,
  TARIFF_ALIAS.EDUCATION,
  TARIFF_ALIAS.PROFESSIONAL,
];