export const initialState = {
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  notifications: [],
  lastIdNotification: 0,
  checksum: null,
};
