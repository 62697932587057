// https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
import axios from 'axios';
import { LocalStorage, Cookie } from '@libs';
import { POP_PROFILE } from '@globalLocalStorage';
import { PHPSESSID } from '@globalCookie';
import { isLocalHost } from '@libs';
import { TYPES_LOG } from '@globalConstants';

let instance = null;

class SendLogClass {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.interface_init = {};
    
    return instance;
  }

  init(settings) {
    this.interface_init = settings;
  }

  _getProfile() { 
    return LocalStorage.get(POP_PROFILE);
  }
  
  _getSessionID() { 
    return Cookie.get(PHPSESSID);
  }
  
  _getUrl() { 
    return window?.location?.href || '';
  }

  _getUserAgent() { 
    return window?.navigator?.userAgent;
  }

  _getSendData() {

  }

  send(type, data) {
    if (isLocalHost) {
      console.info(`Send ${type} log`, data);

      return;
    };
    
    try {
      const params = {
        type,
        profile: this._getProfile(),
        session_id: this._getSessionID(),
        url: window?.location?.href,
        user_agent: window?.navigator?.userAgent,
        interface_init: this.interface_init,
        data,
      };

      this._sendRequest(params);
    } catch (error) {
      console.error(error);
    }
  }

  sendCrash(data) {
    this.send(TYPES_LOG.CRASH, data);
  }
  
  sendErrorLoad(data) {
    this.send(TYPES_LOG.ERROR_LOAD, data);
  }

  async _sendRequest(data) {
    const URL = 'https://planoplan.com/a4474f73-c9cf-2b63-0eb2-60df2abbc035/interface-logs';

    try {
      return axios.post(URL, data);
    } catch (error) {
      console.error(error);
    }
  }
}
export const SendLog = new SendLogClass();
