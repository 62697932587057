import React, { useEffect, useState, useRef }  from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { notifications } from '@features/notifications';
import { LocalStorage, SendLog } from '@libs';
import { TYPES_LOG } from '@globalConstants';
import { POP_GOOGLE_NOTIFICATION } from '@globalLocalStorage';
import { ON_BINDING_CARD_SUCCESS, ON_CHANGE_PAYMENT_STATUS_STORE } from '@observer/constants';
import { useModals, GoogleShutdownNotification } from '@components';
import { MODALS } from '@features/unity/constants';
import * as action from '@features/cabinet/modules/Store/action';
import * as getData from './modules/getdata';
import * as effects from './modules/effects';
import { PAGES } from './constants';
import { rail_y, thumb_y } from './components/scroll';
import { Navigation } from './components/Navigation'
import { Close } from './components/Close'
import {
  PageWaiting,
  PageBalance,
  PageLayouts,
  PageProfile,
  PageProfileTeam,
  PageLoginSettings,
  PageSubscribe,
  PagePaymentMethods,
  PagePurchaseHistory,
  PageMembers,
} from './pages';

const observer = new window.POPObserver();

export const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const LayoutRef = useRef();
  // team.id который меняется при смене команды. (teamId) - не изменен, выставляется при открытии кабинета
  const { currentPage, tariff, team, profile, settings: { projectId, onClose, teamId }} = useSelector(state => state);
  const [modals, openModal, closeAllModals] = useModals([MODALS.GOOGLE_SHUTDOWN_NOTIFICATION]);

  const loadData = async() => {
    dispatch(getData.getProfile());
    dispatch(getData.getNotifications());
    dispatch(getData.getHistory());
    dispatch(getData.getCards());
  };
  const setPage = (alias) => {dispatch(action.setPage(alias))};
  const goLoginSettings = () => {
    const dataDisplayGoogleNotify = LocalStorage.get(POP_GOOGLE_NOTIFICATION) || [];

    closeAllModals();
    LocalStorage.set(POP_GOOGLE_NOTIFICATION, [...dataDisplayGoogleNotify, profile.id]);
    setPage(PAGES.loginSettings);
  };

  useEffect(() => {
    (async () => {
      dispatch(getData.getGoods());
      dispatch(getData.getTeamMembers());
      dispatch(effects.setCurrentTeam());
    })();
  }, [team.id]);

  useEffect(() => {
    const intervalCheckTariffExpired = tariff?.expire && setInterval(() => {
      const timeNow = Math.ceil(Date.now() / 1000);

      if (timeNow >= tariff.expire) {
        dispatch(getData.getGoods());
      }
    }, 3000);

    return () => {
      clearInterval(intervalCheckTariffExpired);
    }
  }, [tariff.expire]);

  /* Выводим сообщение о заверщении работы Google */
  useEffect(() => {
    if (!profile.id) return;
    if (profile.country.IsoCode !== 'RU') return;
    if (!profile.linked_accounts || !profile.linked_accounts.google) return;

    const dataDisplayGoogleNotify = LocalStorage.get(POP_GOOGLE_NOTIFICATION) || [];

    if (!dataDisplayGoogleNotify.includes(profile.id)) {
      setTimeout(() => {
        openModal(MODALS.GOOGLE_SHUTDOWN_NOTIFICATION);
      }, 5000);
    }
  }, [profile]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      dispatch(getData.getTranslations());
      await dispatch(getData.getUserSettings());
      dispatch(getData.getSubscriptions());
      await dispatch(getData.getTeams());
      await loadData();
      dispatch(effects.setCurrentTeam());

      setIsLoading(false);
    })();

    notifications.init('cabinet_notifications');

    observer.addEventListener(ON_CHANGE_PAYMENT_STATUS_STORE, async (status) => {
      if (status === 'succeeded') {
        await loadData();
        dispatch(getData.getGoods());
        dispatch(getData.getTeams());

        if (LayoutRef && LayoutRef.current) {
          LayoutRef.current.updateProjects();
        }
      }
    });

    observer.addEventListener(ON_BINDING_CARD_SUCCESS, async () => {
      // todo Переделать без timout'a. Нужнен потому что не отслеживается когда привязалась карта.
      setTimeout(() => {
        dispatch(getData.getCards());
      }, 1000);
    });

    return () => {
      observer.removeEventListener(ON_CHANGE_PAYMENT_STATUS_STORE);
      observer.removeEventListener(ON_BINDING_CARD_SUCCESS);
    }
  }, []);

  return (
    <View>
      {isLoading && <Loading><Preloader width="80px" height="75px" fill={Colors.main_blue} /></Loading>}
      {!isLoading &&
        <ViewApp >
          {team.loading &&
            <Loading>
              <Preloader width="80px" height="80px" fill={Colors.main_blue} />
            </Loading>
          }
          <Sidebar>
            <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
              <SidebarContent>
                <Navigation />
              </SidebarContent>
            </ScrollBar>
          </Sidebar>
          <Content>
            <Wrapper>
              {
                {
                  [PAGES.waiting]: <PageWaiting />,
                  [PAGES.layouts]: <PageLayouts ref={LayoutRef} />,
                  [PAGES.balance]: <PageBalance />,
                  [PAGES.subscribe]: <PageSubscribe />,
                  [PAGES.profile]: <PageProfile />,
                  [PAGES.profileTeam]: <PageProfileTeam />,
                  [PAGES.loginSettings]: <PageLoginSettings />,
                  [PAGES.purchaseHistory]: <PagePurchaseHistory />,
                  [PAGES.members]: <PageMembers />,
                }[currentPage.alias]
              }

              {/*Если никакой проект не открыт то и кабинет нельзя закрыть*/}
              {/* {Boolean(projectId && teamId === team.id) && (
                <Close />
              )} */}
            </Wrapper>
          </Content>
        </ViewApp>
      }

      <GoogleShutdownNotification isOpen={modals[MODALS.GOOGLE_SHUTDOWN_NOTIFICATION].isOpen} onCancel={closeAllModals} goOver={goLoginSettings}/>

      <div id="cabinet_notifications" />
    </View>
  );
};

const ButtonError = styled.button`
  position: fixed;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 50px;
  z-index: 222;
`;
const View = styled.div``;
const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.timberwolf25};
  opacity: 0.8;
  z-index: 4;
  display:flex; 
  align-items: center;
  justify-content: center;
`;
const ViewApp = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${Colors.white};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; 
  font-family: 'CoFoSans', 'sans-serif';
  color: ${Colors.main_dark};
  
  input,
  textarea {
    font-family: 'CoFoSans', 'sans-serif';
    color: ${Colors.feldgrau};
  }
`;
const Sidebar = styled.div`
  width: 230px;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
  padding: 10px 0;
`;
const SidebarContent = styled.div`
  padding: 26px 20px 16px;
  height: 100%;
`;
const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 10px 10px 10px 0;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${rgba(Colors.main_light_beige, 0.5)};
  border-radius: 5px;
`;
