import {
  POINTER_ON_INTERFACE,
  POINTER_OFF_INTERFACE,
  URL_OPEN,
  UNAUTH_URL_OPEN,
  TOKENS_UPDATE,
  CLOSE_LOADER,
  LOADER_READY,
  PROJECT_ACTION,
  PROJECT_OPEN,
  OPEN_PROJECT,
  DISPLAY_INTERFACE,
  PAYMENT_SUCCESSFUL,
  LOGOUT,
  CHANGE_TEAM,
  ISSUE_LICENSE,
  PAUSE_TRACK,
  RESUME_TRACK,
  DEBUG_LOG,
  BLOCKING_RECTS_UPDATE,
  CHANGE_LANGUAGE,
} from '@globalConstants';
import { ON_AUTH_REQUEST_ERROR_400 } from '@observer/constants';

// Активация/дезактивация интерфейса юнити
export const interfaces = {
  [POINTER_ON_INTERFACE]: {
    name: 'PointerOnInterface',
    value: 0,
  },
  [POINTER_OFF_INTERFACE]: {
    name: 'PointerOnInterface',
    value: 1,
  },
};

// Переход по ссылкам
export const url = {
  [URL_OPEN]: {
    name: 'UrlOpen',
  },
  [UNAUTH_URL_OPEN]: {
    name: 'UnauthUrlOpen',
  },
};

// Actions
export const actions = {
  [ON_AUTH_REQUEST_ERROR_400]: {
    name: 'RequestError400',
    value: 1,
  },
  [TOKENS_UPDATE]: {
    name: 'TokensUpdate',
  },
  [CLOSE_LOADER]: {
    name: 'CloseLoader',
    value: true,
  },
  [LOADER_READY]: {
    name: 'LoaderReady',
  },
  [PROJECT_OPEN]: {
    name: 'ProjectOpen', // открыть готовый проект
  },
  [PROJECT_ACTION]: {
    name: 'ProjectAction',
  },
  [OPEN_PROJECT]: {
    name: 'OpenProject', // открыть один из личных проектов
  },
  [ISSUE_LICENSE]: {
    name: 'IssueLicense', // Предоставить лицензию если есть свободная
  },
  [DISPLAY_INTERFACE]: {
    name: 'DisplayInterface',
  },
  [PAYMENT_SUCCESSFUL]: {
    name: 'PaymentSuccessful', // Отправка после успешной покупки товаров
  },
  [LOGOUT]: {
    name: 'ApplicationLogout', // Разлогин пользователя из кабинета
  },
  [CHANGE_TEAM]: {
    name: 'ChangeTeam', // Смена команды
  },
  [BLOCKING_RECTS_UPDATE]: {
    name: 'BlockingRectsUpdate',
  },
  [PAUSE_TRACK]: {
    name: 'PauseTrack', // Останавливает запись видео
  },
  [RESUME_TRACK]: {
    name: 'ResumeTrack', // Возобновляем запись видео
  },
  [DEBUG_LOG]: {
    name: 'DebugLog', // Запись в лог файл стендалона
  },
  [CHANGE_LANGUAGE]: {
    name: 'ChangeLanguage', // Смена языка
  },
};
