import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
// import { IconArrow } from '../../icons';

export const Notice = ({ notice = {}, parent = null, getDate = () => {}, getCaption = () => {}, onClick = () => {} }) => {
  return (
    <View onClick={() => onClick(notice)} data-id={notice.id}>
        <Image>
          {notice.data.image &&
            <img src={notice.data.image} alt=""/>
          }
          
          {notice.data.preview &&
            <img src={notice.data.preview} alt=""/>
          }

          {notice.settings.icon &&
            <img src={notice.settings.icon} alt=""/>
          }

          {!notice.data.image &&
            <FakeImg />
          }
        </Image>
        <Info>
          <Name>{getCaption(notice)}</Name>
          <Time>{getDate(notice.created * 1000)}</Time>
          {/* <Icon><IconArrow fill={Colors.main_blue} /></Icon> */}
        </Info>
    </View>
  )
};

const Icon = styled.div`
  ${Mixins.transition('opacity')};
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  opacity: 0;
`;
const View = styled.div`
  ${Mixins.transition('border-color')};
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  position: relative;
 
  &:hover {
    border-color: ${Colors.main_blue};
    ${Icon} {
      opacity: 1;
    }
  }
`;
const Image = styled.div`
  width: 96px;
  height: 64px;
  overflow: hidden;
  border-radius: 5px;
  flex-shrink: 0;
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
const FakeImg = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.feldgrau25};
`;
const Info = styled.div`
  margin-left: 10px;  
`;
const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding-right: 24px;
`;
const Time = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${Colors.feldgrau50};
  margin-top: 3px;
`;
