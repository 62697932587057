import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { TooltipEditor } from 'planoplan-ui-kit/core/tooltipEditor';
import { translations } from '@libs';
import { IconDisable } from './icon-disable';
import { IconRemove } from './icon-remove';

export const TableActions = ({ onBlockUser = () => {}, onRemoveUser = () => {}}) => {
  return (
    <View>
      <TooltipEditor render={translations.t('cabinet.members.block_user')} direction="bottom-right" theme="editor_night" nowrap>
        <Action onClick={onBlockUser}>
          <IconDisable />
        </Action>
      </TooltipEditor>
      
      <TooltipEditor render={translations.t('cabinet.members.remove_from_team')} direction="bottom-right" theme="editor_night" nowrap>
        <Action onClick={onRemoveUser}>
          <IconRemove />
        </Action>
      </TooltipEditor>
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Tooltip = styled.div`
  ${Mixins.transition('opacity')};
  margin-top: 10px;
  padding: 4px 10px 6px;
  border-radius: 16px;
  background-color: ${Colors.second_gray};
  color: ${Colors.white};
  position: absolute;
  top: 100%;
  right: 0;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
`;
const Action = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    display: block;
  }
  
  &:not(:last-child) {
    margin-right: 6px;
  }
  
  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;    
    }
  }
`;

