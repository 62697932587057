import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ErrorScreen } from './pages';
import { connect } from 'react-redux';
import { SendLog, TelegramBot } from '@libs';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    const { errorInfo, callback = () => {} } = props;

    this.state = {
      error: null,
      errorInfo: errorInfo || {},
      callback,
    };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    const { callback } = this.state;

    SendLog.sendCrash({
      interface: 'cabinet',
      error: {
        message: error.message,
        info: errorInfo
      },
    });

    TelegramBot.sendError('Критическая ошибка в кабинете!!!', error);

    callback();
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <View>
          <Container>
            <Content>
              <ErrorScreen />
            </Content>
          </Container>
        </View>
      )
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(ErrorBoundary);

const View = styled.div`
  font-family: 'CoFoSans', 'Museo', sans-serif;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.black, 0.4)};
  user-select: none;
`;
const Container = styled.div`
  position: relative;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
`;
