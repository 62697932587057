export class LocalStorage {
  static set(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('LocalStorage Error ' + e);
    }
  }

  static get(key, cbError = () => {}) {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (e) {
      cbError(e);
      console.error('LocalStorage Error ' + e);
    }
  }

  static has(key) {
    return !!window.localStorage.getItem(key);
  }

  static remove(key) {
    window.localStorage.removeItem(key);
  }
}
