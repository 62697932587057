import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { getImage } from 'planoplan-ui-kit/core/get_image';
import { translations } from '@libs';
import { CardsAction } from './cards-action';
import { getImageGroup } from '../helpers';

export const Card = () => {
  const { cards } = useSelector((state) => state);
  const defaultCard = cards.find(item => Boolean(item.is_default));
  const isEmpty = !Boolean(cards.length);
  const [openCardsAction, setOpenCardsAction] = useState(false);

  const handleClick = () => setOpenCardsAction(true);

  return (
    <View>
      <Top>
        <Title>{translations.t('cabinet.subscribe.card.title')}</Title>

        {isEmpty && (
          <EmptyMessage>{translations.t('cabinet.subscribe.card.empty')}</EmptyMessage>
        )}

        {!isEmpty && Boolean(defaultCard) && (
          <InfoCard>
            <Icon>
              <img src={getImageGroup('bank_cards', defaultCard?.info?.card_type || defaultCard?.info?.brand)} alt={defaultCard?.info?.card_type || defaultCard?.info?.brand} />
            </Icon>
            <Numbers>•• <Last>{defaultCard?.info?.last4}</Last></Numbers>
          </InfoCard>
        )}
      </Top>

      <ChangeButton>
        <Button theme="feldgrau" widthText size="S" onClick={handleClick}>{isEmpty ? translations.t('cabinet.subscribe.card.add') : translations.t('cabinet.subscribe.card.change')}</Button>
      </ChangeButton>

      <CardsAction open={openCardsAction} setOpen={setOpenCardsAction} />
    </View>
  );
};

const View = styled.div`
  border: 1px solid ${Colors.main_dark};
  width: 200px;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Top = styled.div``;
const Title = styled.div`
  font-size: 16px;
  line-height: 19px;  
  color: ${Colors.main_dark};
  margin-bottom: 8px;
`;
const EmptyMessage = styled.div`
  color: ${Colors.nobel};
  font-size: 16px;
  line-height: 19px;
`;
const InfoCard = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled.div`
  margin-right: 10px;
`;
const Numbers = styled.div`
  font-size: 16px;
  line-height: 19px;  
  color: ${Colors.main_dark};
  display: flex;
  align-items: center;
`;
const Last = styled.div`
  margin-left: 4px;
`;
const ChangeButton = styled.div`
  button {
    height: 40px;
    font-size: 16px;
    color: ${Colors.main_black};
    border-color: ${Colors.main_gray};

    &:hover {
      background-color: inherit;
      color: inherit;
      box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
    }

    &:active {
      color: ${Colors.white};
      background-color: ${Colors.main_black};
    }
  }
`;
