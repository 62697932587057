import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { LocalStorage } from '@libs';
import { actions } from '@globalInvoke';
import { CHANGE_LANGUAGE } from '@globalConstants';
import { POP_PRICING, POP_BANNERS, POP_TARIFFS } from '@globalLocalStorage';
import { ON_CHANGE_LANGUAGE, ON_LANGUAGE_LOAD } from '@observer/constants';
import { ApiCall } from '@features/form-entry/modules/api-call';
import { analytics } from '@features/form-entry/modules/analytics';
import { getPricing, getBanners, getTariffs } from '@features/banners/modules/effects';
import { getContent } from '@features/unity/screen-entry/modules/getdata';
import { SET_LOCALE } from '../../constants';

const observer = new window.POPObserver();

export const ChooseLocale = () => {
  const dispatch = useDispatch();
  const { settings: { locale, appLanguages = ['ru', 'en'] }} = useSelector(state => state);
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const onChoose = (lang) => {
    setOpen(false);
    setLoad(true);

    setCurrentLocale(lang);
    ApiCall.setSettings({ locale: lang });
    analytics.init({ locale: lang });
    observer.postMessage(ON_CHANGE_LANGUAGE, lang);

    window.invokeEditorAction({
      name: actions[CHANGE_LANGUAGE].name,
      value: lang,
    });

    window.dataLayer.push({
      event: 'ga4_Event',
      evName: 'lang_switch',
      evParam1: `${currentLocale}-${lang}`,
    });
  };

  const setPricing = async (lang) => {
    const pricing = await getPricing(lang);

    LocalStorage.set(POP_PRICING, pricing);
  };
  const setBanners = async (lang) => {
    const banners = await getBanners(lang, 'iinfopoints');

    LocalStorage.set(POP_BANNERS, banners);
  };
  const setTariffs = async (lang) => {
    const tariffsContent = await getTariffs(lang);

    LocalStorage.set(POP_TARIFFS, tariffsContent);
  };

  useEffect(() => {
    observer.addEventListener(ON_LANGUAGE_LOAD, async (lang) => {
      await Promise.all([
        setPricing(lang),
        setBanners(lang),
        setTariffs(lang),
        dispatch(getContent(lang)),
        dispatch({ type: SET_LOCALE, payload: lang }),
      ]);

      setLoad(false);
    });

    return () => {
      observer.removeEventListener(ON_LANGUAGE_LOAD);
    }
  }, []);

  return (
    <View>
      <Locale onClick={() => !load && setOpen(!open)}>{currentLocale}</Locale>

      {(open || load) && (
        <Overlay onClick={() => setOpen(false)}>
          {load && <Preloader width="80px" height="80px" fill={Colors.main_blue} />}
        </Overlay>
      )}

      {open && (
        <List>
          {appLanguages.map((lang) => (
            <Item key={lang} active={currentLocale.toLowerCase() === lang.toLowerCase()} onClick={() => onChoose(lang)}>
              {lang.toUpperCase()}
            </Item>
          ))}
        </List>
      )}
    </View>
  )
};

const View = styled.div`
  position: relative;
  margin-left: 20px;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.white, 0.4)};
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Locale = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.main_dark};
  position: relative;
  cursor: pointer;
  z-index: 5;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${Colors.main_dark};
  }
`;
const List = styled.div`
  padding: 10px 0;
  margin-top: 5px;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background-color: ${Colors.white};
  color: ${Colors.main_dark};
  box-shadow: 1px 2px 4px 0px #2929290A,
              3px 7px 8px 0px #29292908,
              8px 16px 11px 0px #29292905,
              14px 29px 13px 0px #29292903,
              21px 45px 14px 0px #29292900;
  border-radius: 5px;
  z-index: 5;
`;
const Item = styled.div`
  ${Mixins.transition('background-color, color')};
  padding: 5px 20px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  background-color: ${({ active }) => active ? Colors.main_blue : Colors.white};
  color: ${({ active }) => active ? Colors.white : Colors.main_dark};

  &:hover {
    background-color: ${Colors.main_blue};
    color: ${Colors.white};
  }
`;