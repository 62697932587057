import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE } from '@globalConstants';
import { createAppStore, initialState } from './modules/Store';
import { interfaces } from '@globalInvoke';
import { StatusInterfaces } from '@libs';
import { App } from './app';
import { ApiCall } from './modules/api-call';
import { getCountNotifications } from './modules/getdata';
import { POSITIONS } from './constants';

const observer = new window.POPObserver();

export default class UINotificationEvents {
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения уведомлений не указан html элемент');
    this.locale = settings.locale || 'en';
    this.teamId = settings.team_id || 0;

    ApiCall.setSettings({ locale: this.locale, teamId: this.teamId });
  }

  open(params = {}) {
    this.locale = params.locale || 'en';
    this.zoom = params.zoom || 1;
    this.teamId = params.team_id || 0;
    this.position = POSITIONS.topRight;
    this.coordinates = params.coordinates || { x: 0, y: 0 };

    ApiCall.setSettings({ locale: this.locale, teamId: this.teamId });

    this._mount();
    this._editorInvoke();
  }

  _mount() {
    const rootElement = document.getElementById(this.root);
    const store = createAppStore(this._createInitialState());

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
    StatusInterfaces.openInterfaceEvents();
  }

  _createInitialState() {
    return {
      ...initialState,
      settings: {
        zoom: this.zoom,
        locale: this.locale,
        teamId: this.teamId,
        coordinates: this.coordinates,
        onClose: this.close.bind(this),
      }
    }
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  async getCountNotifications() {
    const count = await getCountNotifications();

    return count;
  }

  close() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    StatusInterfaces.closeInterfaceEvents();

    if (!StatusInterfaces.checkSomeoneOpen()) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  }
}

if (!window.UINotificationEvents) {
  window.UINotificationEvents = UINotificationEvents;
}
