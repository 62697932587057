import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { useSelector } from 'react-redux';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const BlockedTeamModal = ({ isOpen, params = {}, onCancel = () => {} }) => {
  const { team } = useSelector(state => state);
  const [text, setText] = useState(translations.t('cabinet.team.blocked'));
  const { teamId } = params;
  const currentTeam = team.list.find(team => teamId === team.id);

  const replaceVariables = (string, ...variables) => {
    let index = 0;
  
    return string.replace(/\$\{(.*?)\}/g, function() {
      return variables[index++];
    });
  };

  useEffect(() => {
    const currentTeam = team.list.find(team => teamId === team.id);
    const text = replaceVariables(translations.t('cabinet.team.blocked'), currentTeam?.caption || '');

    setText(text);
  }, [teamId]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('cabinet.team.blocked.title')}</Header>
      <Text dangerouslySetInnerHTML={{__html: text}} />
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.okay')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Text = styled.p`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 160px;
  }
`;
