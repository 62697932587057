import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { actions, url } from '@globalInvoke';
import { CHANGE_TEAM, URL_OPEN } from '@globalConstants';
import { MODALS } from '@features/unity/constants';
import { notifications } from '@features/notifications';
import { translations, isLocalHost } from '@libs';
import * as action from '../../../modules/Store/action';
import {
  ExpiredTeamModal,
  useModals
} from '@components';
import { PAGES, GET_GOODS, TEAM_CHANGED, SET_TEAM_LOADING } from '../../../constants';
import { IconAddTeam } from '../../icons';

export const Teams = () => {
  const dispatch = useDispatch();
  const [modals, openModal, closeAllModals] = useModals([MODALS.EXPIRED_TEAM]);
  const { profile, team, loading } = useSelector(state => state);
  const defLogoTeam = 'https://uikit.planoplan.com/static/images/icons/ava-team.png';
  const teams = team.list;
  const loadingGoods = loading.getters[GET_GOODS] || {};

  const setTeam = async (id) => {
    if (loadingGoods.processing) {
      return;
    }

    if (isLocalHost) {
      setTimeout(() => {
        window.UIInvokeFunction('TeamChanged', id);
      }, 2000);
    }

    const runChange = () => {
      return new Promise((resolve, reject) => {
        dispatch({ type: SET_TEAM_LOADING, payload: true });

        window.invokeEditorAction({
          name: actions[CHANGE_TEAM].name,
          value: id ? id : -1,
        });

        window.invokeAPI.registerDispatch('CABINET', {
          [TEAM_CHANGED]: (teamId) => {
            const idTeam = teamId === -1 ? 0 : teamId;

            idTeam === id ? resolve() : reject();
          },
        });
      });
    };

    runChange()
      .then(() => {
        dispatch(action.setTeamId(id));
        dispatch(action.setPage(PAGES.waiting));
        dispatch({ type: SET_TEAM_LOADING, payload: false });
      })
      .catch(() => {
        notifications.showError(translations.t('cabinet.notification.team.error'));
        dispatch({ type: SET_TEAM_LOADING, payload: false });
      });
  };

  const onWriteEmail = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: 'mailto:corporate@planoplan.com',
    });

    closeAllModals();
  };

  useEffect(() => {
    if (team.expired) {
      openModal(MODALS.EXPIRED_TEAM);
    }
  }, [team.expired]);

  return (
    <View>
      <Wrap loadingGoods={loadingGoods.processing}>
        <Avatar isActive={!team.id} onClick={() => setTeam(0)}>
          <img src={profile.avatar} alt=""/>
        </Avatar>

        {Boolean(teams && teams.length) && teams.map((item) => (
          <List key={item.id}>
            <Avatar isActive={item.id === team.id} onClick={() => setTeam(item.id)}>
              <img src={item.logo || defLogoTeam} alt={item.caption}/>
            </Avatar>
          </List>
        ))}
      </Wrap>

      <ExpiredTeamModal isOpen={modals[MODALS.EXPIRED_TEAM].isOpen}
                        onCancel={closeAllModals}
                        onWriteEmail={onWriteEmail}/>
    </View>
  )
};

const View = styled.div`
  display: inline-flex;
  width: 100%;
  overflow: auto;
  padding-bottom: 3px;
  
  ::-webkit-scrollbar {
    height: 3px;
  }
  
  ::-webkit-scrollbar-track {
    background: ${Colors.timberwolf25}; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: ${Colors.feldgrau50}; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.feldgrau}; 
  }
`;
const Wrap = styled.div`
  ${Mixins.transition('background-color')};
  display: inline-flex;
  background-color: ${Colors.timberwolf25};
  border-radius: 6px;
  margin-left: -8px;
  
  &:hover {
    background-color: ${Colors.feldgrau};
    
    svg {
      stroke: ${Colors.white};
    }
  }
  
  ${({ loadingGoods }) => loadingGoods && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
const Avatar = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 5px;
  margin-left: 8px;
  border: 2px solid ${({ isActive }) => isActive ? Colors.main_blue : 'transparent'};
  cursor: pointer;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
const List = styled.div`

`;
const AddTeam = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  flex-shrink: 0;
  margin-left: 8px;
`;
