// Screen Entry
export const POP_SCREEN_ENTRY_LOGIN = 'POP_SCREEN_ENTRY_LOGIN';
export const POP_SCREEN_ENTRY_DOMAIN = 'POP_SCREEN_ENTRY_DOMAIN';
export const POP_TRANSLATIONS = 'POP_TRANSLATIONS';
export const POP_LOCALE = 'POP_LOCALE';
export const POP_CURRENT_SUB_DOMAIN = 'POP_CURRENT_SUB_DOMAIN';
export const POP_USER_ID_FROM_TOKEN = 'POP_USER_ID_FROM_TOKEN'; // Временное хранение id пользователя для передачи в аналитику
export const POP_USER_SETTINGS = 'POP_USER_SETTINGS';
export const REGISTRATION_WITHOUT_TRIAL = 'REGISTRATION_WITHOUT_TRIAL'; // Регистрация без триального периода (выводим баннер)
export const POP_APP_LANGUAGES = 'POP_APP_LANGUAGES'; 

// User Widget
export const POP_CURRENT_TEAM = 'POP_CURRENT_TEAM';

// Countdown
export const POP_COUNT_DOWN = 'POP_COUNT_DOWN';

// Banners
export const POP_PRICING = 'POP_PRICING';
export const POP_BANNERS = 'POP_BANNERS';
export const POP_TARIFFS = 'POP_TARIFFS';
export const POP_PROFILE = 'POP_PROFILE';
export const POP_GROUP_BANNERS = 'POP_GROUP_BANNERS';

// Cabinet
export const POP_CABINET_LAYOUTS = 'POP_CABINET_LAYOUTS';
export const POP_OPENED_ID_PROJECT = 'POP_OPENED_ID_PROJECT';
export const POP_PROMO = 'POP_PROMO';

// Notification google
export const POP_GOOGLE_NOTIFICATION = 'POP_GOOGLE_NOTIFICATION';
