import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { createRows } from '../../../modules/normalize';

export const Table = ({ columns, data, sort, sortOrder, tabIndex, isMultiSelectPanel, modify }) => {
  const rows = createRows(data, columns);

  return (
    <TableStyled modify={modify}>
      <thead>
        <tr>
          {columns.map(({ header, sortable, style }, i) => (
            <TH key={i} onClick={() => sortable && sort(i)} sortable={sortable} sortOrder={sortOrder} style={style}>
              {header}
              {tabIndex === i && sortable && <SortIndicator sortOrder={sortOrder} />}
            </TH>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <TR key={i} onClick={row.onClick} isMultiSelect={row.isMultiSelect} isMultiSelectPanel={isMultiSelectPanel}>
            {row.isMultiSelect}
            {row.map((cell, i) => (
              <TD key={i} style={cell.style}>
                <Name>{cell.render}</Name>
              </TD>
            ))}
          </TR>
        ))}
      </tbody>
    </TableStyled>
  );
};

const Name = styled.div`
  padding-top: 1px;
`;

const TableStyled = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: initial;

  tbody td {
    vertical-align: ${({ modify }) => (modify === 'alignTop' ? 'top' : 'middle')};
    border-bottom: 1px solid ${Colors.second_light_gray};

    &:first-child {
      border-left: 1px solid ${Colors.second_light_gray};
    }

    &:last-child {
      border-right: 1px solid ${Colors.second_light_gray};
    }

    ${({ modify }) =>
      modify === 'alignTop' &&
      `
      height: auto;
      padding: 20px 10px 20px 20px;
    `}
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const TD = styled.td`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.feldgrau};
  height: 48px;
  position: relative;
  padding: 10px 20px;
  vertical-align: middle;
`;

const TR = styled.tr`
  border-radius: 10px;
  opacity: ${({ isMultiSelectPanel }) => (isMultiSelectPanel ? '0.5' : '1')};

  &:first-child td {
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
        border-top-right-radius: 10px;
    }
    border-top: 1px solid ${Colors.second_light_gray};
  }

  &:last-child td {
    &:first-child {
        border-bottom-left-radius: 10px;
    }
    &:last-child {
        border-bottom-right-radius: 10px;
    }
  }
`;

const TH = styled(TD)`
  font-weight: 400;
  line-height: normal;
  background: none;
  color: ${Colors.second_gray};
  height: 59px;
  white-space: nowrap;
  max-width: ${(p) => p.style && p.style.width};
  user-select: none;

  ${(p) =>
    p.sortable &&
    css`
      cursor: pointer;
    `}

  &:not(:last-child):after {
    display: none;
  }
`;

const SortIndicator = styled.div`
  ${Mixins.transition('transform')};
  margin-left: 5px;
  display: inline-block;
  width: 12px;
  height: 10px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiNBQkFCQUIiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE2LjU5IDguNTlMMTIgMTMuMTcgNy40MSA4LjU5IDYgMTBsNiA2IDYtNnoiLz4gICAgICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiLz4gICAgPC9nPjwvc3ZnPg==);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(${(p) => (p.sortOrder ? '180deg' : '0deg')});
  transform-origin: 50% 50%;
`;
