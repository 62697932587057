import React from 'react';
import { UNAUTH_URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { ButtonAction, Header, Text } from '../../components';

export const BookDemoScreen = ({ onSubmit }) => {
  const onBookDemoClick = (e) => {
    //window.open('https://telegram.me/planoplan_business_bot', '_blank');
    setTimeout(() => {
      window.invokeEditorAction({
        name: url[UNAUTH_URL_OPEN].name,
        value: 'https://telegram.me/planoplan_business_bot',
      });
    }, 2500);
    onSubmit(e);
  };

  return (
    <>
      <Header title={'Запишитесь на демо'} />
      <Text>
        Хотите понять, как Планоплан может помочь именно вашему бизнесу? Напишите нам в корпоративный телеграм{' '}
        <a
          href="#"
          onClick={() =>
            window.invokeEditorAction({
              name: url[UNAUTH_URL_OPEN].name,
              value: 'https://telegram.me/planoplan_business_bot',
            })
          }>
          @planoplan_business_bot
        </a>
        , и мы в комфортное для вас время расскажем о всех преимуществах продукта. Удобная оплата по счету
        для юридических лиц, персональный менеджер и другие выгодные условия для вас.
      </Text>
      <ButtonAction onClick={onBookDemoClick}>Оставить заявку</ButtonAction>
      <ButtonAction theme="secondary" onClick={onSubmit} top="15px">
        Пропустить
      </ButtonAction>
    </>
  );
};
