import React, { useEffect, useState, forwardRef, useImperativeHandle }  from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { debounce } from 'throttle-debounce';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import ru from '../../modules/ru_short';
import en from 'timeago.js/lib/lang/en_US';
import * as timeago from 'timeago.js';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { translations, LocalStorage, getMediaWithZoom } from '@libs';
import { Analytics } from '../../modules/analytics';
import { POP_CABINET_LAYOUTS } from '@globalLocalStorage';
import {
  DeleteProjectModal,
  DuplicateProjectModal,
  MoveProjectModal,
  MoveProjectToTeamModal,
  CreateFolderModal,
  ShortageTeamLicense,
  useModals
} from '@components';
import { MODALS } from '@features/unity/constants';
import { notifications } from '@features/notifications';
import { ON_OPEN_GALLERY, ON_SHORTAGE_TEAM_LICENSE, ON_OPEN_DESIGN_PROJECT } from '@observer/constants';
import { OPEN_PROJECT, URL_OPEN, ISSUE_LICENSE } from '@globalConstants';
import { actions, url } from '@globalInvoke';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TARIFF_ALIAS } from '../../constants';
import { SORTING, VIEWMODE, ROW_TYPE, HEADER_MODES, PER_PAGE } from './constants';
import { laptop_blank, laptop_wide_blank, full_hd_second_blank } from '../../modules/breakpoints';
import * as getData from '../../modules/getdata';
import * as action from '../../modules/Store/action';
import * as effects from './effects';
import { rail_y, thumb_y } from '../../components/scroll';
import { Close } from '../../components/Close';
import { Search } from './organism/search';
import { Select } from './organism/select';
import { Breadcrumbs } from './organism/breadcrumbs';
import { Sorting } from './organism/sorting';
import { Viewmode } from './organism/viewmode';
import { Notification } from './organism/notification';
import { Create } from './organism/create';
import { ProjectCard } from './organism/projectCart';
import { ProjectTable } from './organism/projectTable';

const debounceFunc = debounce(750, (fun) => fun());
const observer = new window.POPObserver();

export const PageLayouts = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const saveData = LocalStorage.has(POP_CABINET_LAYOUTS) ? JSON.parse(LocalStorage.get(POP_CABINET_LAYOUTS)) : {};
  const { projects: { items, more }, tariff = {}, team, settings: { projectId, zoom, onClose, teamId }} = useSelector(state => state);
  const [modals, openModal, closeAllModals] = useModals([
    MODALS.DELETE,
    MODALS.DUPLICATE,
    MODALS.MOVE,
    MODALS.MOVE_TO_TEAM,
    MODALS.CREATE_FOLDER,
    MODALS.SHORTAGE_TEAM_LICENSE,
  ]);
  const [currentHeaderMode, setCurrentHeaderMode] = useState(saveData.searchText ? HEADER_MODES.search : HEADER_MODES.default);
  const [page, setPage] = useState(saveData.page || 1);
  const [folderId, setFolderId] = useState(saveData.folderId || 0);
  const [searchText, setSearchText] = useState(saveData.searchText || '');
  const [loading, setLoading] = useState({
    active: 0,
    full: 0,
  });
  const isHasProjects = items ? Boolean(items.filter(item => item.row_type === 'project').length) : false;
  const disableDesignProject = !isHasProjects || tariff.alias === TARIFF_ALIAS.VIEW_ONLY || tariff.alias === TARIFF_ALIAS.BASIC;

  const getSorting = (saveSorting) => {
    if (!saveSorting || Object.keys(saveSorting).length === 0) return null;

    const sorting = {};

    for(const type in saveSorting) {
      if (Object.prototype.hasOwnProperty.call(saveSorting, type)) {
        sorting[type] = SORTING[type].items[saveSorting[type].alias];
      }
    }

    return sorting;
  };
  const [sorting, setSorting] = useState(getSorting(saveData.sorting) || {
    [SORTING.type.alias]: SORTING.type.items.all,
    [SORTING.base.alias]: SORTING.base.items.modified,
  });

  const [viewmode, setViewmode] = useState(saveData.viewmode || VIEWMODE.card);
  const [firstLoad, setFirstLoad] = useState(true);
  const editListFolder = [
    {
      name: translations.t('cabinet.projects.pinFolder'),
      alias: 'pin',
      func: async (id) => {
        await dispatch(effects.onPin(id, ROW_TYPE.folder, 1));
        Analytics.pinProject(`f_${id}`);
        await getProjects({ refresh: true });
      }
    },
    {
      name: translations.t('cabinet.projects.unPinFolder'),
      alias: 'unpin',
      func: async (id) => {
        await dispatch(effects.onPin(id, ROW_TYPE.folder, 0));
        Analytics.unPinProject(`f_${id}`);
        await getProjects({ refresh: true });
      }
    },
    {
      name: translations.t('cabinet.projects.duplicate'),
      alias: 'duplicate',
      disabled: tariff.alias === TARIFF_ALIAS.VIEW_ONLY,
      func: (id) => openModalDuplicate({ ids: [`f_${id}`] })
    },
    {
      name: translations.t('cabinet.projects.moveToFolder'),
      alias: 'move',
      func: (id) => openModalMove({ ids: [`f_${id}`] })
    },
    {
      name: translations.t('cabinet.projects.select'),
      alias: 'select',
      func: (id) => {
        dispatch(effects.onSelect(id, ROW_TYPE.folder));
        setCurrentHeaderMode(HEADER_MODES.select);
      }
    },
    {
      name: translations.t('cabinet.projects.delete'),
      alias: 'delete',
      func: (id) => openModalDelete({ ids: [`f_${id}`] })
    }
  ];
  const editListProject = [
    {
      name: 'ID ${id}',
      alias: 'id',
      func: (id) => {
        copy(id);
        notifications.showSuccess(translations.t('cabinet.notification.copyProjectId'));
      }
    },
    {
      name: translations.t('cabinet.projects.pinProject'),
      alias: 'pin',
      func: async (id) => {
        await dispatch(effects.onPin(id, ROW_TYPE.project, 1));
        Analytics.pinProject(`p_${id}`);
        await getProjects({ refresh: true });
      }
    },
    {
      name: translations.t('cabinet.projects.unPinProject'),
      alias: 'unpin',
      func: async (id) => {
        await dispatch(effects.onPin(id, ROW_TYPE.project, 0));
        Analytics.unPinProject(`p_${id}`);
        await getProjects({ refresh: true });
      }
    },
    {
      name: translations.t('cabinet.projects.duplicate'),
      alias: 'duplicate',
      disabled: tariff.alias === TARIFF_ALIAS.VIEW_ONLY,
      func: (id) => openModalDuplicate({ ids: [`p_${id}`] })
    },
    {
      name: translations.t('cabinet.projects.moveToFolder'),
      alias: 'move',
      func: (id) => openModalMove({ ids: [`p_${id}`] })
    },
    {
      name: translations.t('cabinet.projects.select'),
      alias: 'select',
      func: (id) => {
        dispatch(effects.onSelect(id, ROW_TYPE.project));
        setCurrentHeaderMode(HEADER_MODES.select);
      }
    },
    {
      name: translations.t('cabinet.projects.delete'),
      alias: 'delete',
      func: (id) => openModalDelete({ ids: [`p_${id}`] })
    }
  ];

  const openGallery = (id) => observer.postMessage(ON_OPEN_GALLERY, { projectId: id });
  const openLink = (link) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };
  const onChangeSearch = (value) => {
    setSearchText(value);
  };
  const getProjects = async ({ loadMore = false, refresh = false, showAll = false } = {}) => {
    setLoading({
      active: 1,
      full: 0,
    });

    const count = refresh ? PER_PAGE * page : PER_PAGE;
    const offset = refresh ? 0 : PER_PAGE * page - PER_PAGE;

    await dispatch(getData.getProjects({
      count: showAll ? 'all': count,
      offset: showAll ? '0' : offset,
      folder_id: folderId,
      team_id: team.id,
      sortBy: sorting.base.param, // created/modified/name
      sortType: sorting.base.param === SORTING.base.items.name.param ? 'asc' : 'desc', // asc/desc
      'filter[type]': sorting.type.param, // project/folder
      'filter[name]': searchText, // search string
    }, showAll ? false : loadMore));

    saveDataInLocalStorage({ showAll });
    setLoading({
      active: 0,
      full: 0,
    });
  };

  const openModalDelete = (params) => openModal(MODALS.DELETE, params);
  const openModalDuplicate = (params) => openModal(MODALS.DUPLICATE, params);
  const openModalMove = (params) => openModal(MODALS.MOVE, params);
  const openModalMoveToTeam = (params) => openModal(MODALS.MOVE_TO_TEAM, params);

  const onDeleteFromModal = async ({ ids }) => {
    closeAllModals();
    await onDelete(ids);
    await getProjects({ refresh: true });

    Analytics.deleteProject(ids.join(', '));

    if (currentHeaderMode === HEADER_MODES.select) {
      setCurrentHeaderMode(HEADER_MODES.default);
    }

    if (ids.includes(`p_${projectId}`)) {
      dispatch(action.setOpenedProjectId(null));
    }
  };
  const onDuplicateFromModal = async ({ ids }, { withContent }) => {
    closeAllModals();
    await onDuplicate(ids, withContent);
    await getProjects({ refresh: true });

    Analytics.duplicateProject(ids.join(', '));

    if (currentHeaderMode === HEADER_MODES.select) {
      setCurrentHeaderMode(HEADER_MODES.default);
    }
  };
  const onMoveFromModal = async ({ ids }, { folderId }) => {
    closeAllModals();
    await onMove(ids, folderId);
    await getProjects({ refresh: true });

    Analytics.moveProject(ids.join(', '));

    if (currentHeaderMode === HEADER_MODES.select) {
      setCurrentHeaderMode(HEADER_MODES.default);
    }
  };
  const onMoveToTeamFromModal = async ({ ids }, { teamId }) => {
    closeAllModals();
    await onMoveToTeam(ids, teamId);
    await getProjects({ refresh: true });

    Analytics.moveProject(ids.join(', '));

    if (currentHeaderMode === HEADER_MODES.select) {
      setCurrentHeaderMode(HEADER_MODES.default);
    }
  };
  const onCreateFolderFromModal = async (params) => {
    closeAllModals();
    await dispatch(effects.onCreateFolder(params));
    await getProjects({ refresh: true });
  };

  const onDelete = (ids) => dispatch(effects.onDelete(ids));
  const onDuplicate = (ids, withContent) => dispatch(effects.onDuplicate(ids, withContent));
  const onMove = (ids, folderId) => dispatch(effects.onMove(ids, folderId));
  const onMoveToTeam = (ids, teamId) => dispatch(effects.onMoveToTeam(ids, teamId));
  const onCreateFolder = () => openModal(MODALS.CREATE_FOLDER);
  const onCreateLayout = async () => {
    if (team && team.id) {
      await onCreateLayoutTeam();
    } else {
      await onCreateLayoutUser();
    }
  };
  const onCreateLayoutTeam = async () => {
    setLoading({
      active: 1,
      full: 1,
    });
    window.invokeEditorAction({
      name: actions[ISSUE_LICENSE].name,
    });

    observer.removeEventListener('ON_UPDATE_TEAM_LICENSE');
    observer.removeEventListener('OPEN_PROJECT_UNITY');

    observer.addEventListener('ON_UPDATE_TEAM_LICENSE', async (license) => {
      if (license) {
        const { id } = await dispatch(effects.onCreateProject(license));

        if (id) {
          Analytics.createProject(id);
          /* Ждем когда откроется проект, для очередности аналитики */
          observer.addEventListener('OPEN_PROJECT_UNITY', () => Analytics.openProject(id));
          onOpenProject(id);
          await getProjects({ refresh: true });
        } else {
          setLoading({
            active: 0,
            full: 0,
          });
        }
      } else {
        notifications.showError(translations.t('form_entry.team.noLicense'));

        setLoading({
          active: 0,
          full: 0,
        });
      }
    });
  };
  const onCreateLayoutUser = async () => {
    setLoading({
      active: 1,
      full: 1,
    });

    observer.removeEventListener('OPEN_PROJECT_UNITY');

    const { id } = await dispatch(effects.onCreateProject());

    if (id) {
      Analytics.createProject(id);
      /* Ждем когда откроется проект, для очередности аналитики */
      observer.addEventListener('OPEN_PROJECT_UNITY', () => Analytics.openProject(id));
      onOpenProject(id);
      await getProjects({ refresh: true });
    } else {
      setLoading({
        active: 0,
        full: 0,
      });
    }
  };
  const onChangePage = () => setPage(page + 1);
  const onShowAll = () => getProjects({ showAll: true });
  const onOpenProject = (id) => {
    if (Number(projectId) === Number(id)) {
      onClose();

      return;
    }
    window.invokeEditorAction({
      name: actions[OPEN_PROJECT].name,
      value: `${id}`,
    });

    setLoading({
      active: 1,
      full: 1,
    });
  };
  const saveDataInLocalStorage = ({ showAll } = {}) => {
    const saveData = {
      page,
      folderId,
      searchText,
      sorting,
      viewmode,
      showAll
    };

    LocalStorage.set(POP_CABINET_LAYOUTS, JSON.stringify(saveData));
  };
  const onExportPdf = () => observer.postMessage(ON_OPEN_DESIGN_PROJECT, { folderId });

  useEffect(() => {
    saveDataInLocalStorage({ showAll: saveData.showAll });
  }, [viewmode]);

  useEffect(() => {
    if (firstLoad) return;

    /* Если страница не меняется, то запрашиваем проекты. Если меняется то запрашивает проект useEffect на page
       Если в поиске что то введено то делаем запрос с задержкой*/
    if (page === 1) {
      debounceFunc(getProjects);
    } else {
      setPage(1);
    }
  }, [searchText]);

  useEffect(() => {
    if (firstLoad) return;

    // Сбрасываем сортировку типов и поиск
    setSorting({ ...sorting, [SORTING.type.alias]: SORTING.type.items.all });
    setSearchText('');
    setCurrentHeaderMode(HEADER_MODES.default);

    if (page === 1) {
      getProjects();
    } else {
      setPage(1);
    }
  }, [folderId]);

  useEffect(() => {
    if (firstLoad) return;

    getProjects({
      refresh: true,
      showAll: saveData.showAll
    });
  }, [sorting]);

  useEffect(() => {
    if (page === 1) {
      if (searchText) {
        debounceFunc(getProjects);
      } else {
        getProjects({ showAll: saveData.showAll });
      }
    } else {
      getProjects({
        refresh: firstLoad,
        loadMore: !firstLoad,
        showAll: saveData.showAll
      });
    }

    if (firstLoad) setFirstLoad(false);
  }, [page]);

  useEffect(() => {
    timeago.register('en', en);
    timeago.register('ru', ru);

    Analytics.viewLayout();

    observer.addEventListener(ON_SHORTAGE_TEAM_LICENSE, () => {
      openModal(MODALS.SHORTAGE_TEAM_LICENSE);
      setLoading({
        active: 0,
        full: 0,
      });
    });

    return () => {
      observer.removeEventListener(ON_SHORTAGE_TEAM_LICENSE);
      dispatch(effects.onAllSelected(false));
    }
  }, []);

  // Вызов из родителя | Плохая практика но так пришлось
  useImperativeHandle(ref, () => ({
    updateProjects() {
      getProjects({ refresh: true });
    }
  }));

  return (
    <View>
      <Header isHasOffset={projectId && teamId === team.id && currentHeaderMode !== HEADER_MODES.select}>
        <HeaderWrap isFull={currentHeaderMode === HEADER_MODES.search}>
          {currentHeaderMode !== HEADER_MODES.select &&
            <SearchWrap isActive={currentHeaderMode === HEADER_MODES.search}>
              <Search value={searchText}
                      setValue={onChangeSearch}
                      isOpen={currentHeaderMode === HEADER_MODES.search}
                      onOpen={() => setCurrentHeaderMode(HEADER_MODES.search)}
                      onClose={() => setCurrentHeaderMode(HEADER_MODES.default)} />
            </SearchWrap>
          }

          {currentHeaderMode === HEADER_MODES.default &&
            <BreadcrumbsWrap>
              <Breadcrumbs setFolderId={setFolderId} />
            </BreadcrumbsWrap>
          }
        </HeaderWrap>

        <HeaderWrap isFull={currentHeaderMode === HEADER_MODES.select}>
          {currentHeaderMode === HEADER_MODES.default &&
            <SortingWrap>
              <Sorting currents={sorting} setSorting={setSorting} />
              <Viewmode current={viewmode} setViewmode={setViewmode} />
            </SortingWrap>
          }

          {Boolean(folderId) &&
            <ExportWrap>
              <ExportPdf onClick={onExportPdf} disabled={disableDesignProject}>{translations.t('cabinet.projects.exportPdf')}</ExportPdf>
            </ExportWrap>
          }

          <SelectWrap isFull={currentHeaderMode === HEADER_MODES.select}>
            <Select isOpen={currentHeaderMode === HEADER_MODES.select}
                    onOpen={() => setCurrentHeaderMode(HEADER_MODES.select)}
                    onClose={() => setCurrentHeaderMode(searchText ? HEADER_MODES.search : HEADER_MODES.default)}
                    onDelete={openModalDelete}
                    onDuplicate={openModalDuplicate}
                    moveToFolder={openModalMove}
                    moveToTeam={openModalMoveToTeam}/>
          </SelectWrap>

          {currentHeaderMode !== HEADER_MODES.select && (
            <>
              <NotificationWrap><Notification /></NotificationWrap>
              <Close fixed />
            </>
          )}
        </HeaderWrap>
      </Header>

      <Content>
        {Boolean(loading.active) &&
          <Loader isFull={loading.full}>
            <Preloader width="80px" height="80px" fill={Colors.main_blue} />
          </Loader>
        }
        <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
          <ProjectsWrap>
            {viewmode === VIEWMODE.card &&
              <ProjectsCard zoom={zoom}>
                <Card zoom={zoom}>
                  <CarFlex>
                    <CarFlexContainer>
                      <Create mode={VIEWMODE.card} onCreateLayout={onCreateLayout} onCreateFolder={onCreateFolder} />
                    </CarFlexContainer>
                  </CarFlex>
                </Card>

                {items && items.map((project) => (
                  <Card key={project.id} zoom={zoom}>
                    <CarFlex>
                      <CarFlexContainer>
                        <ProjectCard data={project}
                                     editListFolder={editListFolder}
                                     editListProject={editListProject}
                                     currentHeaderMode={currentHeaderMode}
                                     setFolderId={setFolderId}
                                     onOpenProject={onOpenProject}
                                     openGallery={openGallery}
                                     openLink={openLink}/>
                      </CarFlexContainer>
                    </CarFlex>
                  </Card>
                ))}
                {more &&
                  <LoadMoreWrap>
                    <LoadMore onClick={onChangePage}>{translations.t('cabinet.projects.more')}</LoadMore>
                    <LoadMore onClick={onShowAll}>{translations.t('cabinet.projects.all')}</LoadMore>
                  </LoadMoreWrap>
                }
              </ProjectsCard>
            }

            {viewmode === VIEWMODE.list &&
              <ProjectList>
                <ProjectListTop>
                  <Create mode={VIEWMODE.list} onCreateLayout={onCreateLayout} onCreateFolder={onCreateFolder} />
                </ProjectListTop>

                <ProjectListTable>
                  <ProjectTable items={items}
                                editListFolder={editListFolder}
                                editListProject={editListProject}
                                currentHeaderMode={currentHeaderMode}
                                setFolderId={setFolderId}
                                onOpenProject={onOpenProject}
                                openGallery={openGallery}
                                openLink={openLink}/>
                  {more &&
                    <LoadMoreWrap>
                      <LoadMore onClick={onChangePage}>{translations.t('cabinet.projects.more')}</LoadMore>
                      <LoadMore onClick={onShowAll}>{translations.t('cabinet.projects.all')}</LoadMore>
                    </LoadMoreWrap>
                  }
                </ProjectListTable>
              </ProjectList>
            }
          </ProjectsWrap>
        </ScrollBar>
      </Content>

      <DeleteProjectModal isOpen={modals[MODALS.DELETE].isOpen}
                          onCancel={closeAllModals}
                          onDelete={() => onDeleteFromModal(modals[MODALS.DELETE].params)}/>

      <DuplicateProjectModal isOpen={modals[MODALS.DUPLICATE].isOpen}
                             onCancel={closeAllModals}
                             onDuplicate={(params) => onDuplicateFromModal(modals[MODALS.DUPLICATE].params, params)}/>

      <MoveProjectModal isOpen={modals[MODALS.MOVE].isOpen}
                        onCancel={closeAllModals}
                        params={modals[MODALS.MOVE].params}
                        onMove={(params) => onMoveFromModal(modals[MODALS.MOVE].params, params)}/>

      <MoveProjectToTeamModal isOpen={modals[MODALS.MOVE_TO_TEAM].isOpen}
                              onCancel={closeAllModals}
                              onMove={(params) => onMoveToTeamFromModal(modals[MODALS.MOVE_TO_TEAM].params, params)}/>

      <CreateFolderModal isOpen={modals[MODALS.CREATE_FOLDER].isOpen}
                         onCancel={closeAllModals}
                         onCreateFolder={(params) => onCreateFolderFromModal(params)}/>

      <ShortageTeamLicense isOpen={modals[MODALS.SHORTAGE_TEAM_LICENSE].isOpen}
                           onCancel={closeAllModals}/>
    </View>
  );
});

const View = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Header = styled.div`
  flex-shrink: 0;
  padding: 20px 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${({ isHasOffset }) => isHasOffset ? '70px' : '20px'};
`;
const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  flex-grow: ${({ isFull }) => isFull ? 1 : 0 };
  flex-shrink: 1;
`;
const SearchWrap = styled.div`
  width: ${({ isActive }) => isActive ? '100%' : 'auto' };
  margin-right: ${({ isActive }) => isActive ? '40px' : '12px' };
`;
const BreadcrumbsWrap = styled.div`
`;
const SortingWrap = styled.div`
  margin-right: 13px;
  display: flex;
  align-items: center;
`;
const SelectWrap = styled.div`
  margin-right: ${({ isFull }) => isFull ? '0' : '10px' };
  flex-grow: ${({ isFull }) => isFull ? 1 : 0 };
`;
const NotificationWrap = styled.div``;
const ExportWrap = styled.div`
  margin-right: 10px;
`;
const ExportPdf = styled.div`
  ${Mixins.transition('color, background-color, box-shadow')};
  height: 40px;
  padding: 0 14px;
  border-radius: 55px;
  border: 1px solid ${Colors.main_dark};
  font-size: 16px;
  color: ${Colors.main_dark};
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    color: ${Colors.white};
    background-color: ${Colors.main_black};
    box-shadow: none;
  }
  
  &:not(:last-child) {
    margin-right: 10px;
  }
  
  ${({ disabled }) => disabled && `
    pointer-events: none;
    border: 1px solid ${Colors.light_gray};
    color: ${Colors.light_gray};
  `}
`;
const Loader = styled.div`
  position: ${({ isFull }) => isFull ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.timberwolf25};
  opacity: 0.8;
  z-index: 3;
  display:flex; 
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  overflow: hidden;
  padding: 0 5px 0 20px;
  height: 100%;
  position: relative;
`;
const ProjectsWrap = styled.div`
  padding-right: 15px;
`;
const ProjectsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
  
  ${({ zoom }) => `
    @media ${getMediaWithZoom(laptop_blank, zoom)} {
      margin: -10px;
    }
    
    @media ${getMediaWithZoom(laptop_wide_blank, zoom)} {
      margin: -10px;
    }
  `};
`;
const Card = styled.div`
  width: 50%;
  padding: 15px;
  
  ${({ zoom }) => `
    @media ${getMediaWithZoom(laptop_blank, zoom)} {
      width: 33.33%;
      padding: 10px;
    }
    
    @media ${getMediaWithZoom(laptop_wide_blank, zoom)} {
      padding: 10px;
      width: 25%;
    }
    
    @media ${getMediaWithZoom(full_hd_second_blank, zoom)} {
      width: 20%;
    }
  `}
`;
const CarFlex = styled.div`
  width: 100%;
  height: 0;
  padding-top: 73.1%;
  position: relative;
`;
const CarFlexContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const LoadMore = styled.div`
  ${Mixins.transition('color, background-color, border-color')};
  margin: 15px;
  height: 40px;
  width: 100%;
  padding: 0 14px;
  border-radius: 5px;
  border: 1px solid ${Colors.light_gray};
  font-size: 16px;
  color: ${Colors.feldgrau};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    border-color: ${Colors.main_blue};
    background-color: ${Colors.main_blue};
    color: ${Colors.white};
  }
  
  &:not(:last-child) {
    margin-right: 10px;
  }
  &:first-child {
    margin-right: 5px;
  }
  &:last-child {
    margin-left: 5px;
  }
`;
const LoadMoreWrap = styled.div`
  display: flex;
  width: 100%;
  
  ${LoadMore} {
  }
`;
const ProjectList = styled.div`
`;
const ProjectListTop = styled.div`
  margin-bottom: 20px;
`;
const ProjectListTable = styled.div`
`;
