import 'url-polyfill';
import 'url-search-params-polyfill';
import 'core-js/es/object';
import 'core-js/es/array';
import 'core-js/es/string/includes';
import '@observer';
import '@api';
import { isProdHost, isLocalHost, LocalStorage, SessionStorage, request, parseStringToObject, Cookie, translations, SendLog, StatusInterfaces } from '@libs';
import { POP_CABINET_LAYOUTS, POP_CURRENT_SUB_DOMAIN, POP_CURRENT_TEAM, POP_PROFILE, POP_OPENED_ID_PROJECT, POP_USER_SETTINGS, REGISTRATION_WITHOUT_TRIAL, POP_APP_LANGUAGES } from '@globalLocalStorage';
import { OPEN_PROJECT, STABLE__STANDALONE_VERSION } from '@globalConstants';
import UIStore from '@features/store';
import UIQuickStore from '@features/quick-store';
import UIGallery from '@features/gallery';
import UIDesignProject from '@features/design-project';
import UILoader from '@features/loader';
import UIProjects from '@features/projects';
import UIImageEditor from '@features/image-editor';
import UITourEditor from '@features/tour-editor';
import UIBanners from '@features/banners';
import UICabinet from '@features/cabinet';
import UIAssistant from '@features/assistant';
import UIGettingStarted from '@features/getting-started';
import UINotificationEvents from '@features/notification-events';
import { STORE_OPEN } from '@features/store/constants';
import { QUICK_STORE_OPEN } from '@features/quick-store/constants';
import { GALLERY_OPEN, GALLERY_CLOSE } from '@features/gallery/constants';
import { DESIGN_PROJECT_OPEN, DESIGN_PROJECT_CLOSE } from '@features/design-project/constants';
import { LOADER_OPEN, LOADER_CLOSE, LOADER_READY } from '@features/loader/constants';
import { PROJECTS_OPEN, PROJECTS_CLOSE, BUTTONS } from '@features/projects/constants';
import { IMAGE_EDITOR_OPEN, IMAGE_EDITOR_CLOSE } from '@features/image-editor/constants';
import { TOUR_EDITOR_OPEN, TOUR_EDITOR_CLOSE } from '@features/tour-editor/constants';
import { SHOW_BANNER } from '@features/banners/constants';
import { CABINET_OPEN, CABINET_CLOSE } from '@features/cabinet/constants';
import { GETTING_STARTED_OPEN, GETTING_STARTED_CLOSE } from '@features/getting-started/constants';
import { ASSISTANT_OPEN, ASSISTANT_CLOSE, LOCAL_STORE_ASSISTANT_DATA } from '@features/assistant/constants';
import { NOTIFICATION_EVENTS_OPEN, NOTIFICATION_EVENTS_CLOSE } from '@features/notification-events/constants';
import {
  ON_OPEN_STORE,
  ON_OPEN_STORE_CART,
  ON_OPEN_GALLERY,
  ON_CLOSE_APP,
  ON_OPEN_BANNER,
  ON_OPEN_CABINET,
  ON_CLOSE_CABINET,
  ON_OPEN_IMAGE_EDITOR,
  ON_SAVE_IMAGE_EDITOR,
  ON_OPEN_DESIGN_PROJECT,
  ON_OPEN_TOUR_EDITOR,
  ON_CLOSE_TOUR_EDITOR,
  ON_SUCCESS_LOGIN,
  ON_SUCCESS_SIGNUP,
  ON_SHORTAGE_TEAM_LICENSE,
  ON_UPDATE_TEAM_LICENSE,
  ON_CHANGE_ZOOM,
  ON_GALLERY_HIDE,
  USEDESK_CALLBACK,
  ON_OPEN_QUICK_STORE,
  ON_OPEN_GETTING_STARTED,
  ON_CHANGE_LANGUAGE,
  ON_LANGUAGE_LOAD,
  ON_LANGUAGE_LOAD_FOR_PAGE,
  ON_OPEN_NOTIFICATION_EVENTS,
  ON_CLOSE_NOTIFICATION_EVENTS,
  ON_CALL_COUNT_NOTIFICATION_EVENTS,
  ON_SEND_COUNT_NOTIFICATION_EVENTS,
} from '@observer/constants';
import ScreenEntry from './screen-entry';
import Layout from './layout';
import { INTERFACE_INIT, SET_ZOOM, TOKENS_UPDATE, PROJECT_WAS_OPENED, SHORTAGE_TEAM_LICENSE, LICENSE_UPDATE, LANGUAGE_CHANGE } from './constants';
import { TRANSLATIONS_PATTERNS } from './screen-entry/constants';
import { ApiCall } from '@features/cabinet/modules/api-call';
import { actions } from '@globalInvoke';
import { initUsedesk } from './layout/modules/helper';
import { checkAndOpenGettingStarted } from './effects';
import { mappedButtons } from '@features/projects/modules/invoke';

export default class Interface {
  constructor() {
    this.layout = null;
    this.store = null;
    this.gallery = null;
    this.screenEntryOpen = null;
    this.loader = null;
    this.projects = null;

    const globalEvents = {
      [INTERFACE_INIT]: (settings) => this._interfaceInit(settings),
      // [LAYOUT_INIT]: (params) => this._layoutInit(params),
      [STORE_OPEN]: (params) => this._storeOpen(params),
      [QUICK_STORE_OPEN]: (params) => this._quickStoreOpen(params),
      [GALLERY_OPEN]: (params) => this._galleryOpen(params),
      [GALLERY_CLOSE]: () => this._galleryClose(),
      [DESIGN_PROJECT_OPEN]: (params) => this._designProjectOpen(params),
      [DESIGN_PROJECT_CLOSE]: () => this._designProjectClose(),
      [TOKENS_UPDATE]: (tokens) => this._setTokens(tokens),
      [LOADER_OPEN]: () => this._loaderOpen(),
      [LOADER_CLOSE]: () => this._loaderClose(),
      [LOADER_READY]: () => this._loaderReady(),
      [PROJECTS_OPEN]: (params) => this._projectsOpen(params),
      [PROJECTS_CLOSE]: () => this._projectsClose(),
      [SET_ZOOM]: (zoom) => this._setZoom(zoom),
      [IMAGE_EDITOR_OPEN]: (params) => this._imageEditorOpen(params),
      [IMAGE_EDITOR_CLOSE]: () => this._imageEditorClose(),
      [TOUR_EDITOR_OPEN]: (params) => this._tourEditorOpen(params),
      [TOUR_EDITOR_CLOSE]: (params) => this._tourEditorClose(params),
      [SHOW_BANNER]: (params) => this._showBanner(params),
      [CABINET_OPEN]: (params) => this._cabinetOpen(params),
      [CABINET_CLOSE]: () => this._cabinetClose(),
      [ASSISTANT_OPEN]: (params) => this._assistantOpen(params),
      [ASSISTANT_CLOSE]: () => this._assistantClose(),
      [PROJECT_WAS_OPENED]: (params) => this._projectWasOpened(params),
      [SHORTAGE_TEAM_LICENSE]: () => this._shortageTeamLicense(),
      [LICENSE_UPDATE]: (params) => this._licenseUpdate(params),
      [LANGUAGE_CHANGE]: (params) => this._languageChange(params),
      [GETTING_STARTED_OPEN]: () => this._gettingStartedOpen(),
      [GETTING_STARTED_CLOSE]: () => this._gettingStartedClose(),
      [NOTIFICATION_EVENTS_OPEN]: (params) => this._notificationEventsOpen(params),
      [NOTIFICATION_EVENTS_CLOSE]: () => this._notificationEventsClose(),
    };

    window.invokeAPI.registerDispatch('GLOBAL', globalEvents);

    LocalStorage.remove(POP_OPENED_ID_PROJECT);
    LocalStorage.remove(POP_CABINET_LAYOUTS);
    LocalStorage.remove(POP_USER_SETTINGS);
    LocalStorage.remove(LOCAL_STORE_ASSISTANT_DATA);
  }

  /**
   * Получение глобальный данных
   */
  get global() {
    return {
      team_id: LocalStorage.get(POP_CURRENT_TEAM) || '',
    };
  }

  /**
   * Метод содержит подписки на события из других интерфейсов
   * @private
   */
  _subscribes() {
    const observer = new window.POPObserver();

    observer.addEventListener(ON_OPEN_STORE, (params) => {
      this._storeOpen({
        initialScreen: 'pricing',
        team_id: this.global.team_id,
        ...params,
      });
    });

    observer.addEventListener(ON_OPEN_STORE_CART, () => {
      this._storeOpen({
        initialScreen: 'cart',
        team_id: this.global.team_id,
      });
    });

    observer.addEventListener(ON_OPEN_GALLERY, (payload) => {
      const { projectId, openImageId, previewUrl, imageUrl, folderId, onlyDesignProject = false, page } = payload;

      this._galleryOpen({
        projectId,
        folderId,
        onlyDesignProject,
        openImageId,
        previewUrl,
        imageUrl,
        page,
        team_id: this.global.team_id,
      });
    });

    observer.addEventListener(ON_CLOSE_APP, () => {
      this.layout = null;
      this.store = null;
      this.gallery = null;
      this.screenEntryOpen = null;
      this.loader = null;
      this.projects = null;
    });

    // Открытие редактора изображения с галереи.
    observer.addEventListener(ON_OPEN_IMAGE_EDITOR, (payload) => {
      this._imageEditorOpen(payload);
      observer.postMessage(ON_GALLERY_HIDE);
    });

    // Сохранение изображения после редактирования в галереи.
    observer.addEventListener(ON_SAVE_IMAGE_EDITOR, (payload) => {
      if (this.gallery) {
        this.gallery.saveImage(payload);
      }
    });

    // Открытие дизайн проекта
    observer.addEventListener(ON_OPEN_DESIGN_PROJECT, (payload) => {
      this._designProjectOpen(payload);
      observer.postMessage(ON_GALLERY_HIDE);
    });

    // Открытие редактора туров с галереи.
    observer.addEventListener(ON_OPEN_TOUR_EDITOR, (payload) => {
      this._tourEditorOpen(payload);
      observer.postMessage(ON_GALLERY_HIDE);
    });

    observer.addEventListener(ON_CLOSE_TOUR_EDITOR, () => {
      if (this.gallery) {
        this.gallery.updateGallery();
      }
    });

    observer.addEventListener(ON_OPEN_BANNER, (payload) => {
      this._showBanner(payload);
    });

    observer.addEventListener(ON_OPEN_CABINET, (payload) => {
      this._galleryClose();
      this._cabinetOpen(payload);
    });

    observer.addEventListener(ON_CLOSE_CABINET, () => {
      this._cabinetClose();
    });

    observer.addEventListener(ON_SUCCESS_LOGIN, () => {
      observer.postMessage(USEDESK_CALLBACK);
      this._cabinetOpen();
      this._checkVersionAndDisplayWarningBanner();
    });

    observer.addEventListener(ON_SUCCESS_SIGNUP, () => {
      observer.postMessage(USEDESK_CALLBACK);
      this._createProject();
      this._checkVersionAndDisplayWarningBanner();
      this._checkRegistrationWithoutTrial();
    });

    observer.addEventListener(ON_OPEN_QUICK_STORE, (payload) => {
      this._quickStoreOpen(payload);
    });

    observer.addEventListener(ON_OPEN_GETTING_STARTED, () => {
      this._gettingStartedOpen();
    });
    
    observer.addEventListener(ON_CHANGE_LANGUAGE, (locale) => {
      this._languageChange(locale);
    });
    
    observer.addEventListener(ON_OPEN_NOTIFICATION_EVENTS, (payload) => {
      this._notificationEventsOpen(payload);
    });
    
    observer.addEventListener(ON_CLOSE_NOTIFICATION_EVENTS, () => {
      this._notificationEventsClose();
    });
    
    observer.addEventListener(ON_CALL_COUNT_NOTIFICATION_EVENTS, (params) => {
      this._notificationEventsSendCount(params);
    });
  }

  /**
   * Инциализация всех интерфейсов ('InterfaceInit')
   * @param {object} settings - объект с настройками и редактора
   * @private
   */
  async _interfaceInit(settings = {}) {
    try {
      this.settings = this._parseExternalData(settings);

      const { unity_clientID } = settings;
      const { tokens, subDomain = '', zoom = 1 } = this.settings;

      this._setFingerprint(unity_clientID);
      this._setSubDomain(subDomain);
      this._setZoom(zoom);

      SendLog.init(settings);

      if (tokens) {
        this._setTokens(tokens);
      }

      this._setAnalytics();

      if (this.settings.showEntry) {
        this._screenEntryOpen();
      }

      this._subscribes();
      this._setPlatform();
      this._setAppLanguages();
      this._checkAndHideReCaptcha();
      this.addUsedesk();

      return true;
    } catch (error) {
      return error;
    }
  }

  /**
   * Будущая инициализация лэйаута редактора
   * @private
   */
  _layoutInit() {
    if (isProdHost || this.layout) {
      return;
    }

    const { locale } = this.settings;
    this.layout = new Layout();

    this.layout.init({
      root: 'layout',
      locale,
    });

    this.layout.open();
  }

  /**
   * Проверяет внешние данные на тип и всегда возвращает объект
   * @param {object || string} data - объект с настройками и редактора
   * @return {object} - готовые к работе данные
   * @private
   */
  _parseExternalData(data) {
    return typeof data === 'string' || data instanceof String ? JSON.parse(data) : data;
  }

  // /**
  //  * Установка куки пользователя на страницу /ui/editor
  //  * @private
  //  */
  // _setCookie(cookie) {
  //   if (cookie) {
  //     document.cookie = `${cookie}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  //   }
  // }

  /**
   * Установка токенов
   * @param tokens - объект с access_token refresh_token expires_in
   * @private
   */
  _setTokens(tokens) {
    request.setCookieTokens(tokens);
  }

  /**
   * Установка поддомена для запросов
   * @param domain - поддомен, для planoplan.com = "", для остальных например 'pro'
   * @private
   */
  _setSubDomain(domain) {
    SessionStorage.set(POP_CURRENT_SUB_DOMAIN, domain);
  }

  /**
   * Устанавливает масштаб браузера
   * @param zoom - масштаб из редактора
   * @private
   */
  _setZoom(zoom) {
    const observer = new window.POPObserver();
    const url = new URL(window.location.href);

    this.zoom = zoom;
    document.body.style.zoom = zoom;
    url.searchParams.set('zoom', zoom);
    window.history.pushState(null, '', url.toString());

    if (this.loader) {
      this.loader.setZoom(zoom);
    }

    observer.postMessage(ON_CHANGE_ZOOM, zoom);
  }

  /**
   * Устанвливает платформу для юнити
   * @private
   */
  _setPlatform() {
    const { platform, version } = parseStringToObject(this.settings.platform, ':');

    this.platform = platform || '';
    this.version = version || '';
  }
  
  _setAppLanguages() {
    const { appLanguages } = this.settings;

    if (appLanguages) {
      LocalStorage.set(POP_APP_LANGUAGES, appLanguages);
    }
  }
  
  _setAppLanguages() {
    const { appLanguages } = this.settings;

    if (appLanguages) {
      LocalStorage.set(POP_APP_LANGUAGES, appLanguages);
    }
  }

  /**
   * Добавляет скрипт для usedesk
   */
  addUsedesk() {
    if (isLocalHost) {
      return;
    }

    const getUrlUsedesk = (lang) => {
      const srcDev = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_155720_57900.js';
      const srcRu = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_155720_54383.js';
      const srcEn = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_155720_54653.js';
      const srcEs = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_155720_61214.js';
      
      switch(lang) {
        case 'ru': return isProdHost ? srcRu : srcDev;
        case 'en': return srcEn;
        case 'es': return srcEs;
        default: return srcRu;
      }
    }

    const { locale } = this.settings;
    const firstScript = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    
    script.src = getUrlUsedesk(locale);
    script.async = true;
    script.onload = () => initUsedesk({ zoom: this.zoom });
    firstScript.parentNode.insertBefore(script, firstScript);
  }

  /**
   * Устанвливает глобальную неизменяемою переменную для fingerprint (unity_clientID)
   * @private
   */
  _setFingerprint(unity_clientID) {
    Object.defineProperty(window, 'unity_clientID', {
      value: `${unity_clientID}`,
      writable: false, // делаем переменную неизменяемой (нельзя перезаписать)
      configurable: false, // запрещаем изменение атрибутов переменной
      enumerable: false // переменная не будет видна при перечислении свойств объекта window
    });
  }

  /**
   * Установка аналитики
   * todo Далее должна быть переработана на логин из FormEntry
   * @private
   */
  _setAnalytics() {
    const getTariffName = (user_id, tariff_name, localProfile) => {
      try {
        const localTariff = (localProfile && Object.keys(localProfile).length) ? localProfile.tariff : {};
        const tariffName = tariff_name || (localTariff.alias === 'proplus' && localTariff.is_trial) ? 'trialproplus' : localTariff.alias;
        const userId = user_id || (localProfile & localProfile.id) ? localProfile.id : '';

        return { tariffName, userId };
      } catch (error) {
        return { tariffName: '', userId: '' };
      }
    };

    try {
      const { user_id, tariff_name, platform, unity_clientID, web_clientID, firstEntry, installer_filename } = this.settings;
      const localProfile = LocalStorage.get(POP_PROFILE) || {};
      const { tariffName, userId } = getTariffName(user_id, tariff_name, localProfile);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        UserID: userId,
        Tarif: tariffName,
        Platform: platform || '',
        unity_clientID: unity_clientID || '',
        clientID_WEB: web_clientID || '',
      });

      if (firstEntry) {
        window.dataLayer.push ({
          'event': 'ga4_Event',
          'evName': 'standalone',
          'evParam1': 'first_start',
          'evParam2': installer_filename,
          'clientID_WEB': web_clientID || ''
        });
      }

      window.dataLayer.push({
        'event':'ga4_Event',
        'evName':'standalone',
        'evParam1':'start'
      });

      Cookie.set('ga_source', web_clientID, { expires: 30, secure: false });

      const onLoadScript = () => {
        if (window.ym && window.ga) {
          window.ym(19529638, 'setUserID', userId);
          window.yaParams = {
            'UA-ClientId': window.ga.getAll()[0].get('clientId'),
            'Status': 'Зарегистрированный',
            'Tarif': tariffName,
            'LTV': '0.00',
          };

          window.ym(19529638, 'params', window.yaParams);
        }
      };

      // т.к window.dataLayer c UserID и другими днными нужно установить до загрузки gtm
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        j.async = true;
        j.onload = onLoadScript();
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TGM9VWS');
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Открытие магазина из редактора ('StoreOpen')
   * @param {object} params - параметры для открытия из редактоа
   * @private
   */
  _storeOpen(params) {
    this.store = new UIStore();

    const { locale, user_id, unity_clientID } = this.settings;
    const {
      initialCart,
      initialScreen,
      openProduct,
      orderid,
      team_id,
      cartTariff,
      promocode,
      onlyInitialCart,
      closeAfterPay,
      callbackAfterSuccessPay,
    } = params;

    this.store.init({
      root: 'store',
      mode: 'unity',
      locale,
      user_id,
      unity_clientid: unity_clientID,
    });

    this.store.open({
      initialScreen: initialScreen ? initialScreen.toLowerCase() : initialScreen,
      openProduct,
      initialCart,
      onlyInitialCart: initialCart ? onlyInitialCart : false,
      team_id,
      orderid,
      cartTariff,
      zoom: this.zoom,
      promocode,
      closeAfterPay,
      callbackAfterSuccessPay,
    });
  }

  /**
   * Открытие быстрого магазина ('QuickStoreOpen')
   * @param {object} params - параметры для открытия
   * @private
   */
  _quickStoreOpen(params) {
    this.quickStore = new UIQuickStore();

    this.quickStore.emit({
      settings: {
        root: 'quick-store',
        ...this.settings,
      },
      params,
    });
  }

  /**
   * Открытие галерии из редактора ('GalleryOpen')
   * @param {object} params - параметры для открытия из редактоа
   * @private
   */
  _galleryOpen(params) {
    const { locale } = this.settings;
    const { projectId, folderId, openImageId, team_id, imageUrl, previewUrl, page } = this._parseExternalData(params);

    /* Удалить после релиза */
    if (page === 'design') {
      this._designProjectOpen({ projectId, folderId });
    }

    this.gallery = new UIGallery();

    this.gallery.init({
      root: 'gallery',
      mode: 'unity',
      platform: this.platform,
      locale,
    });

    this.gallery.open({
      projectId,
      openImageId,
      team_id,
      imageUrl,
      previewUrl,
      page,
      zoom: this.zoom,
    });
  }

  /**
   * Закрытие галерии из редактора ('GalleryСlose')
   * @private
   */
  _galleryClose() {
    if (this.gallery) {
      this.gallery.close();
    }
  }

  _designProjectOpen(params) {
    const { locale } = this.settings;
    const { projectId, folderId } = this._parseExternalData(params);

    this.designProject = new UIDesignProject();

    this.designProject.open({
      root: 'design-project',
      locale,
      projectId,
      folderId,
      zoom: this.zoom,
    });
  }

  /**
   * Закрытие галерии из редактора ('GalleryСlose')
   * @private
   */
  _designProjectClose() {
    if (this.designProject) {
      this.designProject.close();
    }
  }

  /**
   * Открытие загрузчика из редактора ('LoaderOpen')
   * @private
   */
  _loaderOpen() {
    const { locale } = this.settings;

    // todo: каждый раз при открытии создается новый экземпляр; то же самое с магазином
    this.loader = new UILoader();
    this.loader.init({
      root: 'loader', // id дом элемента, куда будет монироваться компонент
      locale,
      zoom: this.zoom,
    });
  }

  /**
   * Закрытие загрузчика из редактора ('LoaderСlose')
   * @private
   */
  _loaderClose() {
    if (this.loader) {
      this.loader.close();
    }
  }

  _loaderReady() {
    if (this.loader) {
      this.loader.ready();
    }
  }

  /**
   * Открытие окна входа из редактора ('ScreenEntryOpen')
   * @private
   */
  _screenEntryOpen() {
    if (this.screenEntryOpen) {
      return;
    }

    const { locale, updateStatus, platform, unity_clientID, ver, autoInput = false, social, firstEntry, appLanguages } = this.settings;

    this.screenEntryOpen = new ScreenEntry();

    this.screenEntryOpen.init({
      root: 'screen-entry',
      ver,
      locale,
      updateStatus,
      platform,
      unity_clientID,
      autoInput,
      social,
      firstEntry,
      appLanguages,
    });

    this.screenEntryOpen.open();
  }

  _projectsOpen(params) {
    // Если не был показал интерфейс Getting Started то "Проекты" не выводим
    const isShowGettingStarted = checkAndOpenGettingStarted({
      callback: this._gettingStartedOpen.bind(this)
    });

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.PROJECTS_CLOSE].name,
      value: mappedButtons[BUTTONS.PROJECTS_CLOSE].value,
    });

    if (isShowGettingStarted) {
      return;
    }

    /* Выпиливаем открытие интерфейса готовых проектов из-за замены его на интерфейс getting-started */
    /*const { locale } = this.settings;

    this.projects = new UIProjects();
    this.projects.init({
      root: 'projects', // id дом элемента, куда будет монироваться компонент
      mode: 'unity',
      locale,
    });

    this.projects.open(params);*/
  }

  _projectsClose() {
    if (this.projects) {
      this.projects.close();
    }
  }

  /**
   * Открытие обработки изображения из редактора ('ImageEditorOpen')
   * @private
   */
  _imageEditorOpen(params) {
    const { locale } = this.settings;

    this.imageEditor = new UIImageEditor();
    this.imageEditor.init({
      root: 'image-editor',
      mode: 'unity',
      locale,
      zoom: this.zoom,
      webgl: false
    });

    this.imageEditor.open(params);
  }

  /**
   * Закрытие обработки изображения из редактора ('ImageEditorClose')
   * @private
   */
  _imageEditorClose() {
    this.imageEditor.close();
  }

  /**
   * Открытие обработки тура из редактора ('TourEditorOpen')
   * @private
   */
  _tourEditorOpen(params) {
    const { locale } = this.settings;

    this.tourEditor = new UITourEditor();
    this.tourEditor.init({
      root: 'tour-editor',
      locale,
      zoom: this.zoom,
    });

    this.tourEditor.open(params);
  }

  /**
   * Закрытие обработки тура из редактора ('TourEditorClose')
   * @private
   */
  _tourEditorClose() {
    this.tourEditor.close();
  }

  /**
   * Открытие баннера из редактора ('ShowBanner')
   * @private
   */
  _showBanner(params) {
    const { locale } = this.settings;
    const { alias, groups, action, banner } = params;

    this.banner = new UIBanners;
    this.banner.init({
      root: 'banners',
      mode: 'unity',
      zIndex: 444,
      locale,
      team_id: this.global.team_id,
      alias,
      groups,
      action
    });

    if (banner) {
      this.banner.createPointsWithBanner({ banner });
    } else {
      this.banner.createPoints({
        team_id: this.global.team_id,
        alias,
        groups
      });
    }
    
    this.banner.on(SHOW_BANNER);
  }

  /**
   * Открытие кабинета из редактора ('OpenCabinet')
   * @private
   */
  _cabinetOpen(params = {}) {
    const { locale, app_id, appLanguages } = this.settings;
    
    if (StatusInterfaces.checkAnyOneOpen(['cabinet'])) {
      this.cabinet.close();
    }

    this.cabinet = new UICabinet;
    this.cabinet.init({
      root: 'cabinet',
      locale,
      zoom: this.zoom,
      path: params.path || '',
      teamId: params.teamId ? params.teamId : 0,
      projectId: params.projectId,
      page: params.page,
      app_id,
      appLanguages,
      version: this.version,
    });
    this.cabinet.open();
  }

  /**
   * Создает проект
   * @private
   */
  async _createProject() {
    const { app_id } = this.settings;

    try {
      const { data } = await ApiCall.onCreateProject({
        team_id: 0,
        folder_id: 0,
        license: null,
        app_id,
      });

      if (!data.success) {
        this._cabinetOpen();
      }

      const { id } = data.data;

      window.invokeEditorAction({
        name: actions[OPEN_PROJECT].name,
        value: `${id}`,
      });
    } catch (error) {
      this._cabinetOpen();
    }
  }

  /**
   * Закрытие кабинета
   * @private
   */
  _cabinetClose() {
    if (StatusInterfaces.checkAnyOneOpen(['cabinet'])) {
      this.cabinet.close();
    }
  }

  /**
   * Открытие помошника из редактора
   * @private
   */
  _assistantOpen(params = {}) {
    const { locale } = this.settings;

    this.assistant = new UIAssistant;

    this.assistant.init({
      root: 'assistant',
      locale,
      zoom: this.zoom
    });
    this.assistant.open({
      alias: params.alias,
      run: params.run || false,
      hintOpen: typeof params.hintOpen === 'undefined' ? true : params.hintOpen,
    });
  }

  /**
   * Закрытие кабинета
   * @private
   */
  _assistantClose() {
    this.assistant.close();
  }

  /**
   * Callback после открытия проекта
   * @private
   */
  _projectWasOpened(params) {
    const { locale } = this.settings;
    const observer = new window.POPObserver();

    if (StatusInterfaces.checkAnyOneOpen(['cabinet'])) {
      this._cabinetClose();
    }

    /* Временно тут игнорим т.к. ждем релиза */
    /*checkAndOpenGettingStarted({
      callback: this._gettingStartedOpen.bind(this)
    });*/

    /* Analytic */
    if (params.id) {
      LocalStorage.set(POP_OPENED_ID_PROJECT, params.id);

      window.dataLayer.push({
        event: 'Pageview',
        pagePath: `/#${locale}/projects/${params.id}`,
        pageTitle: `«${params.name}» - Мои проекты (standalone) `
      });

      observer.postMessage('OPEN_PROJECT_UNITY');
    }
  }

  /**
   * Callback если при открытии проекта нету свободных лицензий в команде
   * @private
   */
  _shortageTeamLicense() {
    const observer = new window.POPObserver();

    observer.postMessage(ON_SHORTAGE_TEAM_LICENSE);
  }

  /**
   * Callback при создании проекта идет проверка на свободные лицензии
   * @private
   */
  _licenseUpdate(params) {
    const observer = new window.POPObserver();

    observer.postMessage(ON_UPDATE_TEAM_LICENSE, params);
  }

  /**
   * Callback при смене языка
   * @private
   */
  async _languageChange(locale) {
    const observer = new window.POPObserver();

    this.settings.locale = locale;
    await translations.load(locale, TRANSLATIONS_PATTERNS);
    observer.postMessage(ON_LANGUAGE_LOAD, locale);
    observer.postMessage(ON_LANGUAGE_LOAD_FOR_PAGE, locale);
  }

  _checkVersionAndDisplayWarningBanner() {
    const observer = new window.POPObserver();

    const getInfo = () => {
      try {
        return this.settings.platform.split(';').reduce((acc, item) => {
          const [key, val] = item.split(':');

          return {
            ...(acc || {}),
            [key]: val,
          };
        }, {});
      } catch (error) {
        return null
      }
    };
    const checkPrettyVersion = (version) => {
      try {
        const ver = version.split('.');

        return ver.length > 2;
      } catch (error) {
        return false;
      }
    };
    const compareVersion = (version) => {
      try {
        // Разбиваем версии на массивы чисел
        const v1Parts = version.split('.').map(Number);
        const v2Parts = STABLE__STANDALONE_VERSION.split('.').map(Number);

        // Определяем максимальную длину массива версий
        const maxLength = Math.max(v1Parts.length, v2Parts.length);

        // Дополняем массивы нулями, если их длины различаются
        while (v1Parts.length < maxLength) {
          v1Parts.push(0);
        }
        while (v2Parts.length < maxLength) {
          v2Parts.push(0);
        }

        // Сравниваем каждую часть версии
        for (let i = 0; i < maxLength; i++) {
          if (v1Parts[i] < v2Parts[i]) {
            return -1; // Версия меньше версии STABLE__STANDALONE_VERSION
          } else if (v1Parts[i] > v2Parts[i]) {
            return 1; // Версия больше версии STABLE__STANDALONE_VERSION
          }
        }

        return 0; // Версии равны
      } catch (error) {
        return false;
      }
    };

    const info = getInfo();

    if (!info) return;

    if (!checkPrettyVersion(info.version)) return;

    if (compareVersion(info.version) === -1) {
      observer.postMessage(ON_OPEN_BANNER, {
        groups: ['update_to_release'],
      });
    }
  }

  _checkRegistrationWithoutTrial() {
    const observer = new window.POPObserver();
    const regWithoutTrial = LocalStorage.get(REGISTRATION_WITHOUT_TRIAL);

    if (regWithoutTrial) {
      observer.postMessage(ON_OPEN_QUICK_STORE, {
        tariffAlias: 'proplus',
        bannerAlias: 'registration_without_trial',
      });

      LocalStorage.remove(REGISTRATION_WITHOUT_TRIAL);
    }
  }

  /**
   * Скрывает ошибку от recaptcha. Она выводится в body и без какие либо класов или id.
   * Так что приходится делать через setTimeout
   * Отшибка обычно выводится когда плохая связь с интернетом
   */
  _checkAndHideReCaptcha() {
    setInterval(() => {
      // Получаем все div элементы внутри body
      const divs = document.querySelectorAll('body > div > div');

      // Проходим по каждому div и проверяем текстовое содержимое
      divs.forEach((div) => {
        if (div.innerText.includes('reCAPTCHA') && div.children.length === 0) {
          div.style.display = 'none'; // Скрываем div, если он содержит текст 'reCAPTCHA'
        }
      });
    }, 5000); // Проверка каждые 5000 миллисекунд (5 секунд)
  }

  _gettingStartedOpen() {
    const { locale } = this.settings;

    this.gettingStarted = new UIGettingStarted;
    this.gettingStarted.init({
      root: 'getting-started',
      locale,
      zoom: this.zoom,
    });
    this.gettingStarted.open();
  }

  _gettingStartedClose() {
    this.gettingStarted.close();
  }
  
  _notificationEventsOpen(params = {}) {
    const { locale } = this.settings;
    const openParams = typeof params === 'object' ? params : {};

    this.notificationEvents = new UINotificationEvents;
    this.notificationEvents.init({
      root: 'notification-events',
    });
    this.notificationEvents.open({
      locale,
      zoom: this.zoom,
      ...openParams,
    });
  }
  
  _notificationEventsClose() {
    this.notificationEvents.close();
  }
  
  async _notificationEventsSendCount({ teamId = 0 }) {
    const { locale } = this.settings;

    this.notificationEvents = new UINotificationEvents;
    this.notificationEvents.init({
      root: 'notification-events',
      team_id: teamId,
      locale,
    });

    const observer = new window.POPObserver();
    const count = await this.notificationEvents.getCountNotifications();

    observer.postMessage(ON_SEND_COUNT_NOTIFICATION_EVENTS, count);
  }
}

if (!window.POPInterface) {
  window.POPInterface = Interface;
}
