import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { translations, LocalStorage } from '@libs';
import { POP_PROMO } from '@globalLocalStorage';
import * as effects from '../../modules/effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ON_OPEN_STORE } from '@observer/constants';
import { PAGES, TEAM_ROLES } from '../../constants';
import { ChooseLocale } from '../ChooseLocale';
import { Teams } from './organism/teams';
import { ILogo } from 'planoplan-ui-kit/core/icons/i-logo';
import { UserTariff } from '../UserTariff';
import { MenuItem } from './organism/MenuItem';
import {
  IconLayouts,
  IconBalance,
  IconProfile,
  IconLogin,
  IconSubscribe,
  IconHistory,
  IconLogout,
  IconStore,
  IconTeam,
} from '../icons';
import { TARIFF_ALIAS } from '../../constants';
import { Banner } from './organism/banner';

const observer = new window.POPObserver();

export const Navigation = () => {
  const dispatch = useDispatch();
  const {
    team,
    tariff,
    settings: { locale },
  } = useSelector((state) => state);
  const [banner, setBanner] = useState(null);
  const currentTeam = team.current;

  const userTariff = tariff.is_trial ? TARIFF_ALIAS.TRIAL_PRO : tariff.alias;
  const promoBanners = LocalStorage.get(POP_PROMO) || [];
  const filteredPromoBanners = promoBanners.filter((i) => i.image !== "" ).filter((i) => i.data.tariff?.includes(userTariff) || i.data.tariff === undefined);
  const showBanner = filteredPromoBanners.length >= 1 ? filteredPromoBanners[filteredPromoBanners.length - 1] : null;
  
  const isMember = currentTeam && currentTeam?.role === TEAM_ROLES.member;
  const isAdmin = currentTeam && (currentTeam.role === TEAM_ROLES.admin || currentTeam.role === TEAM_ROLES.owner);

  const openStore = () => observer.postMessage(ON_OPEN_STORE, { team_id: team.id });
  const logout = () => dispatch(effects.logout());

  useEffect(() => {
    if (showBanner?.id) {
      setBanner(showBanner);
    }
  }, [showBanner?.id])

  return (
    <View>
      <Content>
        <LogoWrap>
        <ILogo
            width="163"
            height="40"
            fillBrend={Colors.main_blue}
            fillSeconds={Colors.main_dark}
            rebrand={true}
            locale={locale}
          />
          <LocaleWrap><ChooseLocale /></LocaleWrap>
        </LogoWrap>
        <TeamsWrap><Teams /></TeamsWrap>

        <List>
          <MenuItem alias={PAGES.layouts} text={translations.t('cabinet.projects.title')} Icon={IconLayouts} />

          {isAdmin && <MenuItem alias={PAGES.members} text={translations.t('cabinet.members.title')} Icon={IconTeam} />}

          <Separator />

          {((currentTeam && isAdmin) || !currentTeam) && <MenuItem alias={PAGES.balance} text={translations.t('cabinet.balance.title')} Icon={IconBalance} />}

          {((currentTeam && isAdmin) || !currentTeam) && <MenuItem alias={PAGES.subscribe} text={translations.t('cabinet.subscribe.title')} Icon={IconSubscribe} />}

          {!currentTeam && (
            <MenuItem
              alias={PAGES.purchaseHistory}
              text={translations.t('cabinet.purchase_history.title')}
              Icon={IconHistory}
            />
          )}

          <Separator />

          {!currentTeam && (
            <MenuItem alias={PAGES.profile} text={translations.t('cabinet.profile.title')} Icon={IconProfile} />
          )}

          {isAdmin && (
            <MenuItem
              alias={PAGES.profileTeam}
              text={translations.t('cabinet.team.profile.title')}
              Icon={IconProfile}
            />
          )}

          {!currentTeam && (
            <MenuItem
              alias={PAGES.loginSettings}
              text={translations.t('cabinet.login_settings.title')}
              Icon={IconLogin}
            />
          )}

          {!isMember && <Separator />}

          {!currentTeam && <MenuItem text={translations.t('cabinet.nav.store')} Icon={IconStore} onClick={openStore} />}

          {!currentTeam || (isAdmin && <Separator />)}

          <MenuItem text={translations.t('cabinet.nav.logout')} Icon={IconLogout} onClick={logout} />
        </List>
      </Content>

      {banner && Boolean(banner?.id) && (
        <Banner banner={banner} />
      )}

      <Bottom>
        <UserTariff />
      </Bottom>
    </View>
  );
};

const View = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div``;
const Bottom = styled.div`
  margin-top: 12px;
`;
const LogoWrap = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: center;
`;
const TeamsWrap = styled.div`
  margin-bottom: 16px;
`;
const List = styled.div``;
const Separator = styled.div`
  margin-bottom: 12px;
  width: 100%;
  height: 1px;
  background-color: ${Colors.timberwolf25};
`;
const LocaleWrap = styled.div`
  margin-left: 20px;
`;
