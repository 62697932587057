const pushEvent = (mode, locale, action, title, label = 'view') => {
  const modeTitle = mode === 'unity' ? 'standalone' : 'web';

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'gaEvent',
    gaCategory: `Store-${modeTitle}`,
    gaAction: action,
    gaLabel: label,
  });

  window.dataLayer.push({
    event: 'Pageview',
    pagePath: `/#${locale}/${action}`,
    pageTitle: `${title} (${modeTitle})`,
  });
};

const pushEventGA4 = (evName, evParam1, evParam2, evParam3, evParam4) => {
  window.dataLayer = window.dataLayer || [];

  const params = {
    'event': 'ga4_Event',
    'evName': evName,
    'evParam1': evParam1,
    'evParam2': evParam2,
    'evParam3': evParam3,
    'evParam4': evParam4
  };

  for (let i = 1; i <= 4; i++) {
    if (typeof params[`evParam${i}`] === 'undefined') {
      delete params[`evParam${i}`];
    }
  }

  window.dataLayer.push(params);
};

const pushEcommerce = (event = '', items = [], additional = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: items,
      ...additional,
    }
  });
};

/* Для некоторых событий аналитики */
const getCartData = (state) => {
  try {
    const { cart } = state;
    const tariff = cart.find((goods) => goods.alias === 'tariff');
    const productsName = cart.filter((goods) => goods.alias !== 'tariff').map((product) => product.caption).join(', ');
    const sum = cart.map((goods) => goods.price * goods.view_count).reduce((current, val) => current + val);

    return {
      tariff: tariff ? tariff.caption : '', productsName,
      listGoods: productsName,
      sum: `${sum}${state.profileGoods.view_currency}`,
    }
  } catch (error) {
    return {
      tariff: '',
      listGoods: '',
      sum: 0
    };
  }
};

export const Analytics = {
  viewPricing: (mode, locale) => {
    pushEvent(mode, locale, 'store/tariff-managment', 'Тарифы - Магазин', 'view');
  },

  viewRenders: (mode, locale) => {
    pushEvent(mode, locale, 'store/renders', 'Рендеры - Магазин', 'view');
  },

  viewPackages: (mode, locale) => {
    pushEvent(mode, locale, 'store/packets', 'Пакеты - Магазин', 'view');
  },

  viewWidget: (mode, locale) => {
    pushEvent(mode, locale, 'store/widgets', 'Виджеты - Магазин', 'view');
  },

  viewCart: (mode, locale) => {
    pushEvent(mode, locale, 'store/basket', 'Корзина - Магазин', 'view');
  },

  clickSubscribe: (mode, locale, oldTariff, newTariff, alias = 'tariff-payment') => {
    pushEventGA4('tariff_subscription', oldTariff, newTariff);
  },

  getPay: (mode, locale, state, payments, promo) => {
    const { tariff, listGoods, sum } = getCartData(state);
    const { profileGoods: { tariff_alias }} = state;

    pushEvent(mode, locale, `payment-${payments}`, 'Перейти к оплате', promo);
    pushEventGA4('store_payment', tariff_alias, tariff, listGoods, sum);
  },

  statusPaySuccess: (mode, locale, state, cartList) => {
    const { tariff, listGoods, sum } = getCartData(state);
    const { profileGoods: { tariff_alias }} = state;

    pushEvent(mode, locale, `success-${cartList}`, `${cartList} - Оплата прошла успешно`, 'view');
    pushEventGA4('store_success', tariff_alias, tariff, listGoods, sum);
  },

  successUsePromo: (name, start = '', end = '', discount = '') => {
    const startDate = start ? new Date(start * 1000).toLocaleDateString() : '';
    const endDate = end ? new Date(end * 1000).toLocaleDateString() : '';

    pushEventGA4('apply_promo_code', name, startDate, endDate, discount);
  },

  clearCart: (state) => {
    const { tariff, listGoods, sum } = getCartData(state);

    pushEventGA4('store_clear_cart', tariff, listGoods, sum);
  },

  ecommerceView: (items) => {
    pushEcommerce('view_item_list', items);
  },

  ecommerceAddCart: (items) => {
    pushEcommerce('add_to_cart', items);
  },

  ecommerceRemoveCart: (items) => {
    pushEcommerce('remove_from_cart', items);
  },

  ecommerceViewCart: (items) => {
    pushEcommerce('view_cart', items);
  },

  ecommercePay: (items) => {
    pushEcommerce('begin_checkout', items);
  },

  ecommerceViewItem: (items) => {
    pushEcommerce('view_item', items);
  }
};
