import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { notifications } from '@features/notifications';
import * as actions from '../modules/Store/action';
import { SCREENS } from '../constants';
import { Back } from '../components/Back';

export const PaypalScreen = memo(() => {
  const dispatch = useDispatch();
  const { payData, settings: { zoom }} = useSelector(state => state);
  const { amount, currency_code, client_id, orderid } = payData;
  const [loading, seLoading] = useState(true);

  const initPaypal = () => {
    seLoading(false);

    if (!window.paypal) {
      return;
    }

    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: `${amount}`
            },
            custom_id: orderid,
          }]
        });
      },
      onApprove: async (data, payActions) => {
        const order = await payActions.order.capture();
        const { id, status } = order;

        if (status === 'COMPLETED') {
          dispatch(actions.setPayData({...payData, tx_id: id}));
          dispatch(actions.setScreen(SCREENS.pending));
        } else {
          dispatch(actions.setScreen(SCREENS.error));
          notifications.showError(translations.t('store.common.payment_wrong'));
        }
      },
      onError:  (error) => {
        // Closed pay popup
        if (error.message.includes('close')) {
          return;
        }
        dispatch(actions.setScreen(SCREENS.error));
        notifications.showError(translations.t('store.common.payment_wrong'));
        console.error(error);
      }
    }).render('#paypal-button-container');
  };

  useEffect(() => {
    if (!window.paypal) {
      const script = document.createElement('script');

      script.src = `https://www.paypal.com/sdk/js?client-id=${client_id}&currency=${currency_code}`;
      script.async = true;
      script.onload = () => initPaypal();

      document.body.appendChild(script);
    } else {
      initPaypal();
    }
  }, []);

  return (
    <View>
      <Back />

      {loading &&
        <Loader>
          <Preloader width="80px" height="80px" fill={Colors.planoplan_blue} />
        </Loader>
      }

      <Content zoom={zoom}>
        <Buttons zoom={zoom} id="paypal-button-container" />
      </Content>
    </View>
  );
});

const View = styled.div`
  width: 720px;
  height: 430px;
  background-color: ${Colors.white};
  padding: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
`;
const Content = styled.div`
  width: 100%;
  max-width: 460px;
  height: 100%;
  flex-shrink: 0;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;
const Buttons = styled.div`
  width: ${({ zoom }) => 100 / zoom}%;
`;
const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 3;
  background-color: ${Colors.night};
`;
