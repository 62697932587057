import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { deviceDetected } from 'planoplan-ui-kit/core/device-detected';
import { themes } from './themes';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Textarea = ({
  id,
  name,
  placeholder,
  value,
  required,
  disabled,
  children,
  theme = 'primary',
  size = 'M',
  hasError,
  minRows = 5,
  maxRows = 50,
  bottom = null,
  validation = {},
  trigger = 0,
  device = {},
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  onKeyPress = () => {},
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [error, setError] = useState('');
  const [firstChange, setFirstChange] = useState(true);
  const { errorEmpty } = validation;
  const isMobile = device.isMobile || deviceDetected.isMobile;

  // проверка на пустоту
  const checkRequired = (value) => (required && value.length ? '' : errorEmpty);

  // запуск валидации
  const setValidation = (value) => {
    const error = checkRequired(value);

    if (!firstChange) {
      setError(error);
    }

    return error;
  };

  const returnProps = (event = {}) => {
    const eventValue = typeof event.target !== 'undefined' ? event.target.value : value;

    return {
      name,
      value: eventValue,
      error: setValidation(eventValue),
      isValid: !setValidation(eventValue),
      firstChange,
      event,
    };
  };

  const onInput = (event) => onChange(returnProps(event));
  const onPress = (event) => onKeyPress(returnProps(event));

  const onStartChange = (event) => {
    onFocus(returnProps(event));
    setIsFocus(true);
  };

  const onEndChange = (event) => {
    setFirstChange(false);
    setIsFocus(false);

    onBlur(returnProps(event));
  };

  useEffect(() => {
    if (trigger) {
      onChange(returnProps());
    }
  }, [trigger]);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  return (
    <ThemeProvider theme={{ theme: themes.theme[theme], size: themes.size[size], isMobile }}>
      <View>
        {children && <Label>{children}</Label>}
        <Container isFocus={isFocus} hasError={error} disabled={disabled}>
          <TextareaAutosize
            id={id}
            name={name}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            minRows={minRows}
            maxRows={maxRows}
            onFocus={onStartChange}
            onBlur={onEndChange}
            onChange={onInput}
            onKeyPress={onPress}
            value={value}
          />

          <Bottom>{bottom}</Bottom>
        </Container>

        {hasError && <Error>{error}</Error>}
      </View>
    </ThemeProvider>
  );
};

export const View = styled.label`
  color: ${(p) => p.theme.theme.color};
  font-size: ${(p) => p.theme.size.view.fontSize};
  line-height: ${(p) => p.theme.size.view.lineHeight};
  display: block;

  textarea {
    color: ${(p) => p.theme.theme.color};
    outline: 0;
    background: none;
    border: 0;
    resize: none;
    font-weight: 300;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;

    &::placeholder {
      color: ${Colors.nobel};
    }

    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${Colors.nobel};
    }
  }
`;

const Container = styled.div`
  ${Mixins.transition('border-color, box-shadow, background-color')};
  position: relative;
  color: ${Colors.nobel};
  padding: ${(p) => p.theme.size.textarea.padding};
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  background-color: ${Colors.white};
  border-color: ${(p) => p.theme.theme.textarea.borderColor};
  font-weight: 300;
  font-size: inherit;
  line-height: inherit;
  color: ${Colors.main_dark};
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border-color: ${(p) => !p.theme.isMobile && p.theme.theme.hover.borderColor};
    border-width: 1px;
    background: none;
  }

  ${(p) =>
    p.isFocus &&
    css`
      border-color: ${(p) => p.theme.theme.focus.borderColor};
      border-width: 1px;
      background: none;
    `}

  ${(p) =>
    p.isFocus &&
    p.hasError &&
    css`
      border-color: ${Colors.dull_red};
      box-shadow: inset 0 0 0 1px ${Colors.dull_red};
    `}

  ${(p) =>
    p.hasError &&
    css`
      border-color: ${Colors.colar};

      &:hover {
        border-color: ${(p) => !p.theme.isMobile && Colors.dull_red};
      }
    `}

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      background-color: ${Colors.timberwolf25};
      color: ${Colors.light_gray};

      &:hover {
        border-color: ${(p) => !p.theme.isMobile && Colors.whisper};
      }
    `}
`;

const Label = styled.div`
  margin-bottom: ${(p) => p.theme.size.label.marginBottom};
  margin-left: 4px;
  font-size: 14px;
`;

const Bottom = styled.div`
  margin-top: 5px;
`;

const Error = styled.div`
  font-size: ${(p) => p.theme.size.error.fontSize};
  line-height: ${(p) => p.theme.size.error.lineHeight};
  margin-top: ${(p) => p.theme.size.error.marginTop};
  margin-left: 4px;
  color: ${Colors.colar};
  font-weight: 300;
  position: ${(p) => !p.theme.isMobile && 'absolute'};
`;
