import React, { useState } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconNotification } from '../../../components/icons';
import { Notification as NotificationList } from '../../../components/Notification';

export const Notification = () => {
  const { notification } = useSelector(state => state);
  const [isOpen, seIsOpen] = useState(false);
  const count = notification.list.length;

  return (
    <View>
      <Button onClick={() => seIsOpen(true)}>
        <IconNotification />
        {Boolean(count) && <Count>{count}</Count>}
      </Button>

      <NotificationList isOpen={isOpen} onClose={() => seIsOpen(false)} />
    </View>
  )
};

const View = styled.div`
  position: relative;
`;
const Button = styled.div`
  ${Mixins.transition('background-color, border-color, box-shadow')};    
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.main_dark};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  
  svg {
    ${Mixins.transition('fill')};
  }
  
  &:hover {
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
      3px 4px 5px 0px ${Colors.main_dark}12,
      6px 9px 7px 0px ${Colors.main_dark}0A,
      11px 16px 8px 0px ${Colors.main_dark}03,
      17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    background-color: ${Colors.main_black};
    box-shadow: none;
    
    svg path {
      stroke: ${Colors.white};
    }
  }
`;
const Count = styled.div`
  position: absolute;
  top: -3px;
  right: -6px;
  padding: 4px;
  border-radius: 10px;
  background-color: ${Colors.main_blue};
  color: ${Colors.white};
  font-size: 12px;
  line-height: 12px;
  height: 20px;
  min-width: 20px;
  text-align: center;
  font-family: 'FavoritPro';
`;
