import { request } from '@libs';
import { setGetParamsToUrl } from './helpers';

const getData = async (urls, params, method = 'post') => {
  try {
    const response = await request.call_auth(method, urls, params);
    const { data } = response;

    if (!Object.values(data).length) {
      throw new Error('server');
    }

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    if (!data.success && data.errorText) {
      throw new Error(data.errorText);
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',

  setSettings({ locale, teamId = 0 }) {
    this.locale = locale;
    this.teamId = teamId;
  },

  getNotifications(params) {
    const sendParams = {...params, team_id: this.teamId};
    const path = this.teamId ? `teams` : `users`;
    const urls = { local: './json/cabinet/events.json', prod: setGetParamsToUrl(`/${this.locale}/${path}/events/`, sendParams) };

    return getData(urls);
  },
  
  setUserSettings(params, data) {
    const path = this.teamId ? `teams` : `users`;
    const urls = { local: './json/cabinet/getsettings.json', prod: setGetParamsToUrl(`/${path}/settings`, params)};
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, data, settings);
  },
};
