import React, { useRef, useEffect } from 'react';
import { UNAUTH_URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { ButtonAction, Header, Text } from '../../components';

const content = {
  business: {
    text: `Хотите понять, как Планоплан может помочь именно вашему бизнесу? Напишите нам в корпоративный телеграм 
          <a
            href="https://telegram.me/planoplan_business_bot"
            class="business-link">
              @planoplan_business_bot
          </a>, и мы в комфортное для вас время расскажем о всех преимуществах продукта. Удобная оплата по счету для юридических лиц, персональный менеджер и другие выгодные условия для вас.`,
    buttonText: 'Запишитесь на демо',
    botLink: 'https://telegram.me/planoplan_business_bot',
  },
  education: {
    text: `Хотите узнать, почему Планоплан лучше всего подходит для&nbsp;использования в&nbsp;обучении? Записаться на&nbsp;бесплатное демо
        можно, написав нам <nobr>в телеграм-бот</nobr> 
        <a
          href="https://telegram.me/planoplan_education_bot"
          class="education-link">
          @planoplan_education_bot
        </a> или на почту 
        <a
          href="mailto:education@planoplan.com"
          class="education-link">
          education@planoplan.com.
        </a> Мы ответим на&nbsp;все ваши вопросы, а&nbsp;также познакомим с&nbsp;приложением и&nbsp;его возможностями.`,
    buttonText: 'Написать в бот',
    botLink: 'https://telegram.me/planoplan_education_bot',
  },
};

export const BookDemoScreen = ({ onSubmit, userCategory }) => {
  const contentVarian = userCategory === 'teach-students' ? content.education : content.business;
  const onBookDemoClick = (e) => {
    setTimeout(() => {
      window.invokeEditorAction({
        name: url[UNAUTH_URL_OPEN].name,
        value: contentVarian.botLink,
      });
    }, 2500);
    onSubmit(e);
  };

  const contentRef = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      window.invokeEditorAction({
        name: url[UNAUTH_URL_OPEN].name,
        value: event.srcElement.href,
      });
    };

    const linkElements = contentRef.current.querySelectorAll('a');
    linkElements.forEach((linkElement) => {
      linkElement.addEventListener('click', handleClick);
    });

    return () => {
      linkElements.forEach((linkElement) => {
        linkElement.removeEventListener('click', handleClick);
      });
    };
  }, [contentVarian.botLink]);

  return (
    <>
      <Header title={'Запишитесь на демо'} />
      <div ref={contentRef}>
        <Text dangerouslySetInnerHTML={{ __html: contentVarian.text }} />
      </div>
      <ButtonAction onClick={onBookDemoClick}>{contentVarian.buttonText}</ButtonAction>
      <ButtonAction theme="secondary" onClick={onSubmit} top="15px">
        Пропустить
      </ButtonAction>
    </>
  );
};