import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { SendLog, translations } from '@libs';
import { Close } from './components/Close';
import { TextWithLinks } from '@components/TextWithLinks';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    const { errorInfo, callback = () => {}, onClose = () => {} } = props;

    this.state = {
      error: null,
      errorInfo: errorInfo || {},
      callback,
      onClose,
    };
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    const { callback } = this.state;

    SendLog.sendCrash({
      interface: 'image-editor',
      error: {
        message: error.message,
        info: errorInfo
      },
    });

    callback();
  }

  render() {
    const { error, onClose } = this.state;

    if (error) {
      return (
        <Container>
          <Wrapper>
            <Inner>
              <Editor>
                <Title>{translations.t('image_editor.error.title')}</Title>
                <TextWithLinks Tag={Text} text={translations.t('image_editor.error.text')} />
              </Editor>
              <Controls>
                <Close onClose={onClose} />
              </Controls>
            </Inner>
          </Wrapper>
        </Container>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  background-color: ${rgba(Colors.black, 0.4)};
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 60px 80px;
  min-height: 650px;
`;
const Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const Editor = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  padding: 100px;
  color: ${Colors.white};
`;
const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 24px;
`;
const Text = styled.div`
  max-width: 900px;
  font-weight: 300;
`;
