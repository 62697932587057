import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as effects from '../modules/effects';
import { StatusLayout } from '../layouts/status';
import { IconPending } from '@icons';
import { translations } from '@libs';

export const PendingScreen = memo(() => {
  const dispatch = useDispatch();
  const { payData } = useSelector(state => state);

  useEffect(() => {
    dispatch(effects.checkStatusPay());
  }, []);

  return (
    <StatusLayout title={translations.t('quickstore.pay.pending.title')}
                  text={translations.t(payData.redirect ? 'quickstore.pay.pending.text.redirect' : 'quickstore.pay.pending.text')}
                  redirect={payData.redirect}>
      <IconPending />
    </StatusLayout>
  );
});
