import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { UrlButton } from '@components';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const PointModal = ({ banner, mode, zIndex, isOpen, onClose, onClick }) => {
  const { url = '', image, caption, description, button_title } = banner;
  const linkToCabinet = new URL(url).pathname.search('/cabinet') !== -1;

  return (
    <Modal isOpen={isOpen} onClose={onClose} zIndex={zIndex}>
      <Header>{caption}</Header>
      <Image>
        <img src={image} />
      </Image>
      <Text>{description}</Text>

      <UrlButton href={linkToCabinet ? '' : url} onClick={() => onClick(url)} mode={mode}>
        <Button theme="primary" size="M">
          {button_title}
        </Button>
      </UrlButton>
    </Modal>
  );
};

const Image = styled.div`
  margin-top: 25px;
`;

const Text = styled.p`
  margin-top: 20px;
  margin-bottom: 64px;
`;
