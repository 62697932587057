import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Input = ({ id, label, ...restProps }) => (
  <View>
    {label && <Label htmlFor={id}>{label}</Label>}
    <InputForm type="input" id={id} {...restProps} />
  </View>
);

const View = styled.label`
  display: flex;
  flex-direction: column;
`;
const Label = styled.label`
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.main_dark};
  margin-bottom: 2px;
`;

const InputForm = styled.input`
  ${Mixins.transition('border-color, background-color')};
  width: 100%;
  height: 40px;
  background-color: ${Colors.white};
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 0 10px;
  color: ${Colors.feldgrau};
  border: 1px solid ${Colors.main_beige};
  
  &::placeholder {
    color: ${Colors.feldgrau50};
  }
  
  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: ${Colors.main_blue};
      background-color: ${Colors.timberwolf25};
    }
  }
  
  &:disabled {
    background-color: ${rgba('#F0EDEA', 0.5)};
  }
`;
