import { throttle } from 'throttle-debounce';
import { MODE_ALIAS, SITE, UNITY, POINTER_ON_INTERFACE, URL_OPEN } from '@globalConstants';
import { interfaces, url } from '@globalInvoke';
import { ON_BINDING_CARD_SUCCESS } from '@observer/constants';
import { StatusInterfaces } from '@libs';
import {
  replaceValueInArray,
  removeValueInArray,
  setPricingForTariff,
  getAnalyticParamsRender,
  getAnalyticParamsTariff
} from './helpers';
import {
  SET_PRODUCTS,
  SET_SCREEN,
  SET_PRODUCT_SCREEN,
  CHANGE_COUNT_PRODUCT,
  CHANGE_PRICE_FOR_SET,
  ADD_TO_CART,
  REMOVE_TO_CART,
  SET_PAYMENT_STATUS,
  BUTTONS,
  LIST,
  DETAIL,
  CART,
  SCREEN_ALIAS,
  PAY_STATUSES,
  SET_LOADING_ON,
  SET_LOADING_OFF,
  GET_PAYMENT_STATUS,
  GET_PROFILE,
  PRICING,
  SET_AVAILABLE_SCREENS,
  WIDGET,
  REVIEW,
  SET_PRODUCTS_REVIEW,
  SET_CHECKSUM,
  BINDING,
  SUCCESS_BINDING,
  REQUIRE,
  SET_VIDEO_POPUP_SRC,
  GOODS_TARIFF_ALIAS,
  SET_SAVED_CART,
  TARIFF_JOIN_GOODS,
  SET_SCROLL_Y,
} from '../constants';
import { mappedButtons } from './invoke';
import { toFixed } from './helpers';
import { normalizeProducts, normalizeProductsReview } from './normalize';
import { ApiCall } from './api-call';
import { Analytics } from './analytics';
import * as payEffects from '../screens/Cart/effects';

const observer = new window.POPObserver();

export const setProducts = (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_PRODUCTS } });
  const { profileGoods, pricing, lastBuy } = state;

  const currentTariff = profileGoods.tariff_alias;
  const currentData = pricing.find((item) => item.alias === currentTariff);
  const favoriteGoods = profileGoods.favorite_goods;

  // useEffect на дальнейшую работу только если products.length
  const goods = currentData ? normalizeProducts('goods', currentData, state) : ['empty'];
  const sets = currentData ? normalizeProducts('sets', currentData, state) : ['empty'];
  const products = [...goods, ...sets];
  const productsReview = normalizeProductsReview(favoriteGoods, lastBuy, products);

  dispatch({ type: SET_PRODUCTS, payload: products });
  dispatch({ type: SET_PRODUCTS_REVIEW, payload: productsReview });
  dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PRODUCTS } });
};

export const setAvailableScreens = (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_AVAILABLE_SCREENS } });
  const { profileGoods, products, banners, productsReview } = state;
  const { disable_tabs } = profileGoods;

  const productScreen = products.reduce((acc, currentValue) => {
    const isNeedWidgetScreen = profileGoods.tariff_alias === 'start' || profileGoods.tariff_alias === 'pro';

    // Проверяем не указан ли алиас экрана в настройках для текущего тарифа в админке
    if (disable_tabs.includes(currentValue.view_screen)) {
      return acc;
    }

    if (currentValue.view_screen && !acc.includes(currentValue.view_screen)) {
      acc.push(currentValue.view_screen);
    }

    // для про и старт виджет показываем в любом случае
    if (!acc.includes(WIDGET) && !acc.includes(currentValue.view_screen) && isNeedWidgetScreen) {
      acc.push(WIDGET);
    }

    return acc;
  }, []);

  const availableScreens = [...productScreen, PRICING, CART, BINDING, REQUIRE];

  // Обзор только когда есть баннеры и товары в превью
  if (banners.length || productsReview.length) {
    availableScreens.unshift(REVIEW);
  }

  dispatch({ type: SET_AVAILABLE_SCREENS, payload: availableScreens });
  dispatch({ type: SET_LOADING_OFF, payload: { name: SET_AVAILABLE_SCREENS } });
};

export const checkInitialScreen = (dispatch, state) => {
  const { availableScreens, savedCart } = state;
  const { initialScreen, openProduct } = state.settings;

  if (openProduct || savedCart.length) {
    return;
  }

  const screen = SCREEN_ALIAS[initialScreen] ? SCREEN_ALIAS[initialScreen] : SCREEN_ALIAS.pricing;

  // если есть корзина или товар для просмотра ничего не делаем
  if (screen && availableScreens.includes(screen)) {
    dispatch({ type: SET_SCREEN, payload: screen });
  } else {
    dispatch({ type: SET_SCREEN, payload: PRICING });
  }
};

export const checkInitialCart = (dispatch, state, customScreen) => {
  const { savedCart, products, checksum } = state;
  const { initialCart, initialScreen } = state.settings;

  if (!Object.keys(initialCart).length && !Object.keys(savedCart).length) {
    return;
  }

  const goodsIds = Object.values(TARIFF_JOIN_GOODS).reduce((acc, item) => acc.concat([`goods_${item.year}`, `goods_${item.month}`]), []);
  const isHasInitialCartTariff = !!Object.keys(initialCart).find((key) => goodsIds.includes(key));
  const normalSavedCart = {...savedCart};

  if (isHasInitialCartTariff) {
    for (let storeId in savedCart) {
      if (Object.prototype.hasOwnProperty.call(savedCart, storeId) && goodsIds.includes(storeId)) {
        delete normalSavedCart[storeId];
      }
    }
  }

  const cart = [];
  const goods = Object.keys(initialCart).length ? initialCart : normalSavedCart;

  for (let i in goods) {
    const id = i;
    const count = goods[i];
    const product = products.find((item) => item.store_id === id);

    if (product) {
      product.view_count = count;
      product.view_price = toFixed(product.price * product.view_count, 2);
      //product.activeCount = 0;

      if (product.over_price) {
        product.view_over_price = toFixed(product.over_price * product.view_count, 2);
      }

      if (GOODS_TARIFF_ALIAS.includes(product.alias)) {
        setPricingForTariff(product, products);
      }

      cart.push(product);
    }
  }

  if (cart.length) {
    // Показываем кастомный экран, если он есть. В других случаях - экран ревью, привязки карты, или корзина.
    let screen;

    if (customScreen && SCREEN_ALIAS[customScreen]) {
      screen = SCREEN_ALIAS[customScreen];
    } else if (SCREEN_ALIAS[initialScreen] === PRICING) {
      screen = PRICING;
    } else if (SCREEN_ALIAS[initialScreen] === BINDING) {
      screen = BINDING;
    } else {
      screen = CART;
    }

    dispatch({ type: ADD_TO_CART, payload: cart });
    dispatch({ type: SET_SCREEN, payload: screen });
  }

  saveCart(cart, checksum, dispatch);
};

export const checkOpenProduct = (dispatch, state) => {
  const { openProduct } = state.settings;
  const { products } = state;

  if (!openProduct) {
    return;
  }

  const data = products.find((item) => item.store_id === openProduct);

  if (data) {
    const { view_screen } = data;

    dispatch({ type: SET_SCREEN, payload: view_screen });
    dispatch({
      type: SET_PRODUCT_SCREEN,
      payload: { screen: DETAIL, data, screenBack: view_screen },
    });
  }
};
/**
 * Проверка статуса платежа
 * @param {function} dispatch
 * @param {Object} state
 * @param {string} tx_id - id оплаты от paypal
 */
export const checkPayStatus = async (dispatch, state, tx_id = '') => {
  const { settings } = state;
  const { clearUrl, redirectStatus, paymentIntent, paymentIntentClientSecret } = settings;
  let { orderid } = settings;

  if (!orderid) {
    orderid = state.payment && state.payment.orderid;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PAYMENT_STATUS } });

  try {
    await payEffects.checkStatusPay(dispatch, state, { orderid, redirectStatus, paymentIntent, paymentIntentClientSecret, tx_id });

    dispatch({ type: SET_SCREEN, payload: CART });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PAYMENT_STATUS } });
    clearUrl(['orderid', 'payment_intent', 'payment_intent_client_secret', 'redirect_status']); // Некоторые параметры приходят от stripe
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE, error: error.message } });
  }
};

export const checkBindingStatus = async (dispatch, state) => {
  const { settings } = state;
  const { clearUrl, redirectStatus, paymentIntent, paymentIntentClientSecret } = settings;
  let { orderid } = settings;

  if (!orderid) {
    orderid = state.payment && state.payment.orderid;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PAYMENT_STATUS } });

  try {
    await payEffects.checkStatusPay(dispatch, state, {orderid, redirectStatus, paymentIntent, paymentIntentClientSecret});

    observer.postMessage(ON_BINDING_CARD_SUCCESS);

    dispatch({ type: SET_SCREEN, payload: SUCCESS_BINDING });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PAYMENT_STATUS } });
    clearUrl(['orderid', 'payment_intent', 'payment_intent_client_secret', 'redirect_status']); // Некоторые параметры приходят от stripe
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE, error: error.message } });
  }
};

export const setScreen = (dispatch, state, screen) => {
  dispatch({ type: SET_SCREEN, payload: screen });
  dispatch({ type: SET_PRODUCT_SCREEN, payload: { screen: LIST } });
  dispatch({
    type: SET_PAYMENT_STATUS,
    payload: { status: (state.payment.status === PAY_STATUSES.succeeded || state.payment.status === PAY_STATUSES.wrong) ? '' : state.payment.status },
  });
};

// id - id товара по которому кликнули
// productScreen - LIST/DETAIL
// screenBack - экран на который будем переход по клику на назад
export const setProductScreen = (dispatch, state, id, productScreen, screenBack = '') => {
  const { products } = state;
  const data = products.find((item) => item.store_id === id);

  if (!data) {
    return;
  }

  const { view_screen } = data;

  dispatch({ type: SET_SCREEN, payload: view_screen });
  dispatch({
    type: SET_PRODUCT_SCREEN,
    payload: { screen: productScreen, data, screenBack: screenBack ? screenBack : view_screen },
  });
};

export const addToCart = (dispatch, state, id) => {
  const { cart, checksum } = state;
  const product = state.products.find((item) => item.store_id === id);
  let cartObj = cart;
  product.view_count = 1;
  product.view_price = toFixed(product.price * product.view_count, 2);

  if (GOODS_TARIFF_ALIAS.includes(product.alias)) {
    cartObj = cartObj.filter(item => !GOODS_TARIFF_ALIAS.includes(item.alias));
    setPricingForTariff(product, state.products);
  }

  if (!cartObj.includes(product)) {
    cartObj.push(product);
  }

  dispatch({ type: ADD_TO_CART, payload: cartObj });
  saveCart(cartObj, checksum, dispatch);
};

export const removeFromCart = (dispatch, state, id) => {
  const { products, cart, checksum } = state;
  const product = products.find((item) => item.store_id === id);

  product.view_count = 1;
  product.view_price = product.price;

  if (product.over_price) {
    product.view_over_price = product.over_price;
  }

  const normalizeProducts = replaceValueInArray(products, product, 'store_id');
  const normalizeCart = removeValueInArray(cart, product, 'store_id');

  dispatch({
    type: REMOVE_TO_CART,
    payload: {
      products: normalizeProducts,
      cart: normalizeCart,
    },
  });

  saveCart(normalizeCart, checksum, dispatch);
};

export const changeCountProduct = (dispatch, state, item, type, { hoverCount, inCart, afterRemoveFromCart = () => {} } = {}) => {
  const id = item.store_id;
  const { products, cart, checksum } = state;
  const product = products.find((item) => item.store_id === id);

  product.view_count = type === 'add' ? product.view_count + 1 : product.view_count - 1;
  product.view_price = toFixed(product.price * product.view_count, 2);

  if (product.view_count <= 0) {
    removeFromCart(dispatch, state, id);

    if (hoverCount) {
      Analytics.ecommerceRemoveCart({
        ...item.analyticParams,
        quantity: hoverCount,
      });
    }

    if (inCart) {
      const analyticItems = cart.filter(goods => goods.store_id !== id).map(goods => {
        if (goods.alias === 'tariff' || goods.alias === 'businessvr') {
          return getAnalyticParamsTariff(goods);
        }

        return getAnalyticParamsRender(goods);
      });

      Analytics.ecommerceViewCart(analyticItems);
    }

    afterRemoveFromCart();

    return;
  }

  if (product.over_price) {
    product.view_over_price = toFixed(product.over_price * product.view_count, 2);
  }

  const normalizeProducts = replaceValueInArray(products, product, 'store_id');
  const normalizeCart = replaceValueInArray(cart, product, 'store_id');

  dispatch({
    type: CHANGE_COUNT_PRODUCT,
    payload: {
      products: normalizeProducts,
      cart: normalizeCart,
    },
  });

  saveCart(normalizeCart, checksum, dispatch);
};

export const clearCart = (dispatch, state) => {
  const { products, checksum } = state;

  if (products.includes('empty')) {
    return;
  }

  const normalizeProducts = products.map((product) => {
    product.view_count = 1;
    product.view_price = product.price;

    return product;
  });

  dispatch({
    type: REMOVE_TO_CART,
    payload: {
      products: normalizeProducts,
      cart: [],
    },
  });
  dispatch({ type: SET_SAVED_CART, payload: '' });

  saveCart([], checksum, dispatch);
};

export const changePriceForSet = (dispatch, state, id, price, index) => {
  const { products, cart, checksum } = state;
  const product = products.find((item) => item.store_id === id);
  cart.map((item) => {
    const inSetsItem = item?.in_sets?.find((setsItem) => setsItem.store_id === id);
    if(inSetsItem) {
      item.activeCount = index;
      item.price = price;
      item.view_price = toFixed(price * item.view_count, 2);
    }
  });

  product.price = price;
  product.view_price = toFixed(price * product.view_count, 2);
  product.activeCount = index;

  const normalizeProducts = replaceValueInArray(products, product, 'store_id');
  const normalizeCart = replaceValueInArray(cart, product, 'store_id');

  dispatch({
    type: CHANGE_PRICE_FOR_SET,
    payload: {
      products: normalizeProducts,
      cart: normalizeCart,
    },
  });

  saveCart(normalizeCart, checksum, dispatch);
};

const saveCart = throttle(3000, async (cart, settingsChecksum, dispatch) => {
  const body = cart.reduce((acc, current) => {
    acc[current.store_id] = current.view_count;

    return acc;
  }, {});
  const basket = JSON.stringify(body);

  const request = async (checksum) => {
    try {
      const response = await ApiCall.saveCart({ basket, checksum });
      const { data: { success, errorText, data } } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);
          return;
        } else {
          throw new Error(errorText);
        }
      }

      const { unitySettingsChecksum = '' } = data;

      if (dispatch) {
        dispatch({
          type: SET_CHECKSUM,
          payload: unitySettingsChecksum
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  await request(settingsChecksum);
});

export const saveLastBuy = async (ids, settingsChecksum, dispatch) => {
  const goods = JSON.stringify(ids);

  const request = async (checksum) => {
    try {
      const response = await ApiCall.saveLastBuy({ goods, checksum });
      const { data: { success, errorText, data } } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);
          return;
        } else {
          throw new Error(errorText);
        }
      }

      const { unitySettingsChecksum = '' } = data;

      if (dispatch) {
        dispatch({
          type: SET_CHECKSUM,
          payload: unitySettingsChecksum
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  await request(settingsChecksum);
};

export const closeStore = (state) => {
  const { settings } = state;

  settings.unMount();

  window.invokeEditorAction({
    name: mappedButtons[BUTTONS.STORE_CLOSE].name,
    value: mappedButtons[BUTTONS.STORE_CLOSE].value,
  });

  if (!StatusInterfaces.checkSomeoneOpen()) {
    window.invokeEditorAction({
      name: interfaces[POINTER_ON_INTERFACE].name,
      value: interfaces[POINTER_ON_INTERFACE].value,
    });
  }
};

export const setSrcVideoPopup = (dispatch, src) => {
  window.invokeEditorAction({
    name: url[URL_OPEN].name,
    value: src,
  });

  // dispatch({ type: SET_VIDEO_POPUP_SRC, payload: src });
};

export const setScrollY = (dispatch, scrollY) => {
  dispatch({ type: SET_SCROLL_Y, payload: scrollY });
};
