import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ILogo } from 'planoplan-ui-kit/core/icons/i-logo';
import { translations } from '@libs';
import { TextWithLinks } from '@components/TextWithLinks';

export const ErrorScreen = () => {
  const { settings: { locale }} = useSelector((state) => state);

  const title = translations.t('cabinet.error.title');
  const text = translations.t('cabinet.error.text');
  const defTitle = 'К сожалению, возникла ошибка в вашем личном кабинете.';
  const defText = 'Для устранения проблемы, пожалуйста, попробуйте перезапустить Planoplan. Если проблема сохраняется, обратитесь в нашу службу поддержки, отправив подробное описание проблемы на <a href="mailto:support@planoplan.com">support@planoplan.com</a>.<br/>Мы приложим все усилия для того, чтобы оперативно решить данную ситуацию и предоставим вам необходимую помощь. Благодарим за ваше терпение и понимание.';

  return (
    <View>
      <Sidebar>
        <ILogo width="163" height="40" fillBrend={Colors.main_blue} fillSeconds={Colors.main_dark} rebrand={true} locale={locale} />
      </Sidebar>
      <Content>
        <Wrapper>
          <Title>{title !== 'cabinet.error.title' ? title : defTitle}</Title>
          <TextWithLinks Tag={Text} text={text !== 'cabinet.error.text' ? text : defText} />
        </Wrapper>
      </Content>
    </View>
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${Colors.white};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100; 
  font-family: 'CoFoSans', 'sans-serif';
  color: ${Colors.feldgrau};
`;
const Sidebar = styled.div`
  width: 230px;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
  padding: 36px 20px 16px;
`;
const Content = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 10px 10px 10px 0;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.timberwolf25};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 12px;
`;
const Text = styled.div`
  max-width: 900px;
  font-weight: 300;
`;
