import React, { useEffect } from 'react';
import { BannerModal } from './components/BannerModal';
import { useModal } from '@components';
import { URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import Banners from '@features/banners';
import { SHOW_BANNER } from './constants';

export const App = ({ locale, banner, settings, tariffsContent, goods, teamId, count, amountBanners }) => {
  const observer = new window.POPObserver();
  const [isOpenModal, openModal, closeModal] = useModal();
  const { mode, zIndex = 1, action } = settings;
  const tariffsItems = tariffsContent.data.tariffs.blocks.short_banners.items;
  const { data: pricing} = goods;

  const onRouteCabinet = (link) => {
    closeModal('banners');

    if (!link) {
      if(!count || count >= amountBanners) return;

      const banner = new Banners;
      const { root, mode, zIndex, team_id, alias, groups, action} = settings;

      banner.init({ root, mode, zIndex, locale, team_id, alias, groups, action, count });
      banner.createPoints({ team_id, alias, groups, action });
      banner.on(SHOW_BANNER);

      return;
    }

    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };

  useEffect(() => {
    openModal();
  }, [banner]);

  return (
    <BannerModal
      locale={locale}
      banner={banner}
      tariffsItems={tariffsItems}
      pricing={pricing}
      mode={mode}
      zIndex={zIndex}
      isOpen={isOpenModal}
      onClose={closeModal}
      onClick={onRouteCabinet}
      action={action}
    />
  );
};
