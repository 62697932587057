import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getImageGroup } from '../helpers';
import { IconDelete } from '../../../components/icons';
import { ITrash2 } from 'planoplan-ui-kit/core/icons/i-trash-2';
import { translations } from '@libs';
import { setDefaultCard, removeCard } from '../effects';

export const CardItem = ({ card = {}, setLoading = () => {} }) => {
  const dispatch = useDispatch();
  const { tariff } = useSelector(state => state);
  const [hoverDelete, setHoverDelete] = useState(false);
  const info = card.info || {};
  const isSubscribed = Boolean(tariff.goods_id);

  const handleDelete = async () => {
    // setLoading(true);
    await dispatch(removeCard(card.id));
    // setLoading(false);
  };
  const handleSelect = async () => {
    if (card.is_default) return;

    // setLoading(true);
    await dispatch(setDefaultCard(card.id));
    // setLoading(false);
  };

  return (
    <View isDefault={card.is_default}>
      <ForSelect onClick={handleSelect} />

      <Left>
        <img src={getImageGroup('bank_cards', info.card_type || info.brand)} alt={info.card_type || info.brand} />
        <Number>•••• <NumberLast>{info.last4}</NumberLast></Number>
      </Left>

      <Right>
        {card.is_default && <Caption>{translations.t('cabinet.subscribe.card_popup.active')}</Caption>}
        {(!card.is_default || !isSubscribed) && (
          <Delete onClick={handleDelete} onMouseEnter={() => setHoverDelete(true)} onMouseLeave={() => setHoverDelete(false)}>
            <IconDelete width={24} height={24} fill={hoverDelete ? Colors.black : Colors.main_dark} />
          </Delete>
          )}
      </Right>
    </View>
  );
};

const View = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: ${({ isDefault }) => isDefault ? `inset 0 0 0 2px ${Colors.main_blue}` : `inset 0 0 0 1px ${Colors.feldgrau25}`};
  position: ${({ isDefault }) => isDefault ? `relative` : `static`};
  margin-top: -1px;
  cursor: pointer;
  position: relative;
  z-index: ${({ isDefault }) => isDefault ? 2 : 1};
    
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  &:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  ${({ isDefault }) => !isDefault && `
    &:hover {
      box-shadow: inset 0 0 0 1px ${Colors.main_blue};
      z-index: 2;
    }
  `}
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.white, 0.5)};
`;
const ForSelect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Number = styled.div`
  margin-left: 10px;
  color: ${Colors.main_dark};
  font-size: 16px;
  line-height: 19px;
`;
const NumberLast = styled.div`
  margin-left: 4px;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
`;
const Delete = styled.div`
  margin-left: 10px;
  position: relative;
  z-index: 1;
  
  svg {
    display: block;
  }
`;
const Caption = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.main_blue};
`;
