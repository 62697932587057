// Env
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const ISPRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
export const ISLOCAL = process.env.REACT_APP_ENVIRONMENT === 'local';
export const ISDEVELOPMENT = process.env.REACT_APP_ENVIRONMENT === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';
export const DOMAIN = process.env.REACT_APP_PUBLIC_PATH;
export const PHPSESSID = process.env.REACT_APP_PHPSESSID;

// Mode
export const UNITY = 'UNITY';
export const SITE = 'SITE';
export const DEMO = 'DEMO';

export const MODE_ALIAS = {
  unity: UNITY,
  site: SITE,
  demo: DEMO,
};

// платфоомрмы для юни - веб - вебжл редакторв
export const PLATFORMS = {
  WEB: 'WEB',
  STANDALONE: 'STANDALONE',
};

export const PLATFORMS_ALIAS = {
  standalone: PLATFORMS.STANDALONE,
  web: PLATFORMS.WEB,
};

export const STABLE__STANDALONE_VERSION = '3.2.0';

export const APP_ALIAS = {
  planoplan: 'planoplan',
  arhist: 'arhist',
};

// Invoke
export const POINTER_ON_INTERFACE = 'POINTER_ON_INTERFACE';
export const POINTER_OFF_INTERFACE = 'POINTER_OFF_INTERFACE';
export const URL_OPEN = 'URL_OPEN';
export const UNAUTH_URL_OPEN = 'UNAUTH_URL_OPEN';
export const TOKENS_UPDATE = 'TOKENS_UPDATE';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const LOADER_READY = 'LOADER_READY';
export const PROJECT_OPEN = 'PROJECT_OPEN';
export const PROJECT_ACTION = 'PROJECT_ACTION';
export const OPEN_PROJECT = 'OPEN_PROJECT';
export const DISPLAY_INTERFACE = 'DISPLAY_INTERFACE';
export const PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL';
export const LOGOUT = 'LOGOUT';
export const CHANGE_TEAM = 'CHANGE_TEAM';
export const ISSUE_LICENSE = 'ISSUE_LICENSE';
export const PAUSE_TRACK = 'PAUSE_TRACK'; // останавливает запись видео
export const RESUME_TRACK = 'RESUME_TRACK'; // возобновляем запись видео
export const DEBUG_LOG = 'DEBUG_LOG'; // возобновляем запись видео
export const BLOCKING_RECTS_UPDATE = 'BLOCKING_RECTS_UPDATE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const CLOSE_LOADER_WEBGL_FN = 'LoaderObjInvokeFunction';
export const CLOSE_LOADER_WEBGL_PARAMS = 'ExitLoaderObj';

// Params
export const MODAL_ROOT = '#modal';
export const MODAL_TIMEOUT = 300;
export const NOTIFICATION_DELAY = 4000;

// Notification
export const NOTIFICATION_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

// OS
export const OS_ALIAS = {
  WINDOWS: 'WINDOWS',
  MAC: 'MAC',
};

export const OS_OPTIONS = {
  [OS_ALIAS.MAC]: {
    title: 'Mac',
    downloadLink: 'https://files.planoplan.com/upload/standalone_build/releases/PlanoplanEditorSetup.pkg',
  },
  [OS_ALIAS.WINDOWS]: {
    title: 'Windows',
    downloadLink: 'https://files.planoplan.com/upload/standalone_build/releases/PlanoplanEditorSetup.exe',
  },
};

export const PROFILE_STATUS = {
  DISABLED: 0,
  CONFIRM: 1,
  NOT_CONFIRM: 2,
};

export const DOMAINS = {
  PROD: 'https://planoplan.com',
  DEV: 'https://pp.ksdev.ru',
  PROD_SHORT: 'planoplan.com',
  DEV_SHORT: 'pp.ksdev.ru',
};

// Расширение файла-установочника
export const DOWNLOAD_EXTENSION = {
  [OS_ALIAS.MAC]: 'mac-pkg',
  [OS_ALIAS.WINDOWS]: 'win-exe',
};

export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  TEAM2: 'teamedu',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
};

export const TYPES_LOG = {
  CRASH: 'crash',
};