import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector } from 'react-redux';
import { rgba } from 'polished';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconFolder, IconPlus, IconLocked } from '../../../components/icons';
import { laptop_wide } from '../../../modules/breakpoints';
import { VIEWMODE } from '../constants';
import { TARIFF_ALIAS } from '../../../constants';
import { ON_OPEN_QUICK_STORE } from '@observer/constants';

/**
 * @param {String} mode - type view ('card', 'list')
 * @param {function} onCreateLayout - click on Create
 * @param {function} onCreateFolder - click on Folder
 */
export const Create = ({ mode = 'card', onCreateLayout = () => {}, onCreateFolder = () => {} }) => {
  const { tariff = {}, settings: { teamId, projectId }} = useSelector(state => state);

  const isDisabled = TARIFF_ALIAS.VIEW_ONLY === tariff.alias;
  const observer = new window.POPObserver();
  const onOpenBanner = () => {
    observer.postMessage(ON_OPEN_QUICK_STORE, {
      tariffAlias: TARIFF_ALIAS.PROPLUS,
      bannerAlias: 'upgradeStartNewProject',
      team_id: teamId,
      project_id: projectId,
    });
  };

  return (
    <View>
      {mode === VIEWMODE.card &&
        <Wrapper>
          {!isDisabled &&
            <>
              <CreateLayout onClick={onCreateLayout}>
                <Icon><IconPlus fill={Colors.main_blue}/></Icon>
                <Text>{translations.t('cabinet.projects.project.create')}</Text>
              </CreateLayout>

              <CreateFolder onClick={onCreateFolder}>
                <Icon><IconFolder fill={Colors.main_gray} /></Icon>
                <Text>{translations.t('cabinet.projects.folder.create')}</Text>
              </CreateFolder>
            </>
          }

          {isDisabled &&
            <Disabled isCard={true} onClick={onOpenBanner}>
              <IconLocked fill={Colors.nobel} width={110} height={110}/>
              <p>{translations.t('cabinet.projects.blocked')}</p>
            </Disabled>
          }
        </Wrapper>
      }

      {mode === VIEWMODE.list &&
        <WrapperList>
          {!isDisabled &&
            <>
              <CreateLayoutList onClick={onCreateLayout}>
                <IconList><IconPlus fill={Colors.main_blue} width={48} height={48} /></IconList>
                <TextList>{translations.t('cabinet.projects.project.create')}</TextList>
              </CreateLayoutList>

              <CreateFolderList onClick={onCreateFolder}>
                <IconList><IconFolder fill={Colors.main_gray} width={32} height={30} /></IconList>
                <TextList>{translations.t('cabinet.projects.folder.create')}</TextList>
              </CreateFolderList>
            </>
          }
          {isDisabled &&
            <Disabled isCard={false} onClick={onOpenBanner}>
              <IconLocked fill={Colors.nobel} width={50} height={50}/>
              <p>{translations.t('cabinet.projects.blocked')}</p>
            </Disabled>
          }
        </WrapperList>
      }
    </View>
  )
};

const Disabled = styled.div`
  display: flex;
  height: 100%;
  width: ${({ isCard }) => isCard ? '100%' : '450px'};
  border-radius: 5px;
  overflow: hidden;
  background-color: ${Colors.solitude};
  flex-direction: ${({ isCard }) => isCard ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ isCard }) => isCard ? 'center' : 'flex-start'};
  padding-left: ${({ isCard }) => isCard ? '0' : '5px'};
  color: ${Colors.nobel};
  font-size: 16px;
  line-height: 19px;
  text-align: ${({ isCard }) => isCard ? 'center' : 'left'};
  cursor: pointer;
  
  p {
    width: ${({ isCard }) => isCard ? '190px' : '100%'};
    margin-left: ${({ isCard }) => isCard ? '0' : '23px'};
  }
`;
const View = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
`;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
`;
const CreateWrap = styled.div`
  ${Mixins.transition('color, background-color')};
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  
  svg {
    ${Mixins.transition('fill')};
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`;
const CreateLayout = styled(CreateWrap)`
  width: 60%;
  background-color: ${Colors.white};
  color: ${Colors.main_dark};
  
  &:hover {
    background-color: ${Colors.main_blue};
    color: ${Colors.white};

    svg {
      fill: ${Colors.white};
    }
  }
  
  @media ${laptop_wide} {
    width: 55%;
  }
`;
const CreateFolder = styled(CreateWrap)`
  width: 40%;
  background-color: #E1E1E1;
  color: ${Colors.main_dark};
  
  &:hover {
    color: ${Colors.white};
    background-color: ${Colors.main_gray};

    svg {
      fill: ${Colors.white};
    }
  }
  
  @media ${laptop_wide} {
    width: 45%;
  }
`;

const Icon = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 10px;
`;
const Text = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
`;
const WrapperList = styled(Wrapper)`
  height: 68px;
  justify-content: flex-start;
  border: none;
`;
const IconList = styled(Icon)`
  flex-grow: 0;
  margin-right: 28px;
`;
const TextList = styled(Text)`
  width: auto;
  margin-bottom: 0;
  margin-top: 0;
  flex-grow: 1;
`;
const CreateLayoutList = styled(CreateLayout)`
  width: auto;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px 20px 10px 10px;
  border: 1px solid #e1e1e1;
  
  ${IconList} {
    margin-right: 4px;
  }

  ${TextList} {
    margin-top: -1px;
  }
`;
const CreateFolderList = styled(CreateFolder)`
  width: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: left;
  padding: 10px 20px 10px 10px;
  border: 1px solid #e1e1e1;

  ${IconList} {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
  }
`;
