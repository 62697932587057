import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { TooltipEditor } from 'planoplan-ui-kit/core/tooltipEditor';
import { translations } from '@libs';
import * as effects from '../effects';
import { TEAM_ROLES } from '../../../constants';
import { IconDisable } from './icon-disable';
import { IconRemove } from './icon-remove';
import { IconAdmin } from './icon-admin';

export const TableActions = ({ 
  member = {}, 
  onRemoveUser = () => {}
}) => {
  const dispatch = useDispatch();
  const { team: { current }} = useSelector(state => state);
  
  const isAdmin = member.role === TEAM_ROLES.admin;
  const isOwner = member.role === TEAM_ROLES.owner;
  const isUser = member.role === TEAM_ROLES.member;
  const isBlocked = member.blocked;
  const iAmOwner = current.role === TEAM_ROLES.owner;

  const onAddAdmin = () => dispatch(effects.addAdminInTeam(member.id));
  const onRemoveAdmin = () => dispatch(effects.removeAdminInTeam(member.id));
  const onBlockUser = () => dispatch(effects.blockUserInTeam(member.id));
  const onUnblockUser = () => dispatch(effects.unBlockUserInTeam(member.id));

  const handleClickAdminUser = () => {
    if (!iAmOwner || (isBlocked && !isAdmin)) return;

    if (isAdmin) {
      onRemoveAdmin();
    } else {
      onAddAdmin();
    }
  };

  const handleClickBlockUser = () => {
    if (isAdmin && !iAmOwner) return;

    if (isBlocked) {
      onUnblockUser();
    } else {
      onBlockUser();
    }
  };

  const handleClickRemoveUser = () => {
    if (isAdmin && !iAmOwner) return;

    onRemoveUser(member.id);
  };

  if (isOwner) {
    return (
      <View>
        <Caption>{translations.t('cabinet.members.owner')}</Caption>
      </View>
    )
  }

  return (
    <View>
      <TooltipEditor render={translations.t(isAdmin ? 'cabinet.members.admin_user_remove' : 'cabinet.members.admin_user')} direction="bottom-right" theme="editor_night" nowrap>
        <Action onClick={handleClickAdminUser} isClickable={iAmOwner && !(isBlocked && !isAdmin)}>
          <IconAdmin fill={(isBlocked && !isAdmin) ? Colors.second_gray : isAdmin ? Colors.main_blue : iAmOwner ? Colors.main_dark : Colors.second_gray} />
        </Action>
      </TooltipEditor>

      <TooltipEditor render={translations.t(isBlocked ? 'cabinet.members.unblock_user' : 'cabinet.members.block_user')} direction="bottom-right" theme="editor_night" nowrap>
        <Action onClick={handleClickBlockUser} isClickable={isAdmin ? iAmOwner : true}>
          <IconDisable fill={isBlocked ? Colors.main_blue : isAdmin ? iAmOwner ? Colors.main_dark : Colors.second_gray : Colors.main_dark} />
        </Action>
      </TooltipEditor>
      
      <TooltipEditor render={translations.t('cabinet.members.remove_from_team')} direction="bottom-right" theme="editor_night" nowrap>
        <Action onClick={handleClickRemoveUser} isClickable={isAdmin ? iAmOwner : true}>
          <IconRemove fill={isAdmin ? iAmOwner ? Colors.main_dark : Colors.second_gray : Colors.main_dark} />
        </Action>
      </TooltipEditor>
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Caption = styled.div`
  color: ${Colors.second_gray};
  font-size: 16px;
  line-height: 20px;
`;
const Tooltip = styled.div`
  ${Mixins.transition('opacity')};
  margin-top: 10px;
  padding: 4px 10px 6px;
  border-radius: 16px;
  background-color: ${Colors.second_gray};
  color: ${Colors.white};
  position: absolute;
  top: 100%;
  right: 0;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
`;
const Action = styled.div`
  position: relative;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : 'default'};

  svg {
    display: block;
  }
  
  &:not(:last-child) {
    margin-right: 6px;
  }
  
  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;    
    }
  }
`;

