import React from 'react';
import { translations } from '@libs';
import { ENTRY_STEPS, CHECKING_MODE } from '../../constants';
import { ButtonAction, Header, Text, Image } from '../../components';

export const Success = ({ checkingMode, setStep }) => {
  const isAccountRecovery = checkingMode === CHECKING_MODE.ACCOUNT_RECOVERY

  return (
    <>
      <Header title={translations.t('form_entry.success.header')} />
      <Image normal="form/success.jpg" retina="form/success@2x.jpg" alt="Success" />
      <Text>{translations.t(isAccountRecovery ? 'form_entry.success.recovery.text' : 'form_entry.success.text')}</Text>

      <ButtonAction onClick={() => setStep(ENTRY_STEPS.LOGIN)} data-test="button_back-login">
        {translations.t('form_entry.back.login')}
      </ButtonAction>
    </>
  );
};
