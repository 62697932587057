import React, { useState, useEffect } from 'react';
import { Link } from '@components/link';
import { actions } from '@globalInvoke';
import { PAUSE_TRACK, RESUME_TRACK } from '@globalConstants';
import { translations } from '@libs';
import { ButtonAction, Field, Form, Header, Text, Image, InputField, Error } from '../../components';
import { ENTRY_STEPS, ERROR_TYPES, CHECKING_MODE } from '../../constants';

export const NewPassword = ({
  setStep,
  checkingEmail,
  isProcessing,
  onNewPassword,
  error = {},
  checkingMode = '',
  onClearError = () => {},
}) => {
  const [field, setField] = useState({
    value: '',
    error: '',
  });
  const isError = error.type === ERROR_TYPES.LOCAL;
  const isAccountRecovery = checkingMode === CHECKING_MODE.ACCOUNT_RECOVERY

  const onChange = ({ value, error }) => {
    if (isError) {
      onClearError();
    }
    setField({ ...field, value, error });
  };
  const onSubmit = (event) => {
    event.preventDefault();

    if (!field.error) {
      onNewPassword(field.value);
    }
  };

  useEffect(() => {
    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
    }
  }, []);

  return (
    <>
      <Header title={translations.t(isAccountRecovery ? 'form_entry.recovery.account.header' : 'form_entry.recovery.header')}>
        <Link size="S" onClick={() => setStep(ENTRY_STEPS.LOGIN)} data-test="button_back-login">
          {translations.t('form_entry.back.login')}
        </Link>
      </Header>

      <Image normal="form/new-password.jpg" retina="form/new-password@2x.jpg" alt="New password" />
      <Text>Email: {checkingEmail}</Text>
      <Text>{translations.t('form_entry.recovery.newPassword')}</Text>

      <Form onSubmit={onSubmit} isMarginTop>
        <Field>
          <InputField
            placeholder={translations.t('form_entry.new_password.placeholder')}
            type="password"
            name="password"
            value={field.value}
            hasError={field.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            complexityPass={
              !isError && {
                easy: translations.t('form_entry.password.easy'),
                medium: translations.t('form_entry.password.medium'),
                hard: translations.t('form_entry.password.hard'),
              }
            }
            autoComplete="new-password"
            isEye
            required>
            {translations.t('form_entry.new_password.title')}
          </InputField>
        </Field>

        {isError && <Error>{error.message}</Error>}

        <ButtonAction type="submit" loading={isProcessing} disabled={field.error || !field.value} useButtonLoader>
          {translations.t('form_entry.continue')}
        </ButtonAction>
      </Form>
    </>
  );
};
