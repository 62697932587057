import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TooltipEditor } from 'planoplan-ui-kit/core/tooltipEditor';
import { translations } from '@libs';
import { IconArrow } from '../icons';
import { PAGES } from '../../constants';

export const Close = ({ fixed = false }) => {
  const { currentPage, team, settings: { projectId, onClose, teamId }} = useSelector(state => state);

  if (!Boolean(projectId && teamId === team.id)) {
    return <></>
  }

  return currentPage.alias === PAGES.layouts ? (
    <TooltipEditor theme={'editor_night'} render={translations.t('cabinet.return-in_project')} with={'300px'} direction={'bottom-right'} nowrap>
      <View fixed={fixed} onClick={onClose}>
        <IconArrow fill={Colors.white} />
      </View>
    </TooltipEditor>
  ) : (
    <ReturnClose fixed={fixed} onClick={onClose}>
      <span>{translations.t('cabinet.return-in_project')}</span><IconArrow fill={Colors.white} />
    </ReturnClose>
  );
};

const View = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color, border-color, box-shadow')};
  position: ${({ fixed }) => fixed ? 'fixed' : 'static'};
  z-index: 2;
  top: ${({ fixed }) => fixed ? '30px' : '0'};
  right: ${({ fixed }) => fixed ? '30px' : '0'};
  width: 40px;
  height: 40px; 
  border-radius: 50%;
  background-color: ${Colors.main_blue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  svg {
    ${Mixins.transition('fill')};
  }

  &:hover {
    box-shadow: 0px 2px 4px 0px ${Colors.main_blue}17,
        0px 7px 7px 0px ${Colors.main_blue}14,
        0px 16px 9px 0px ${Colors.main_blue}0D,
        0px 28px 11px 0px ${Colors.main_blue}03,
        0px 43px 12px 0px ${Colors.main_blue}00;
  }
  
  &:active {
    background-color: ${Colors.second_blue};
    border-color: ${Colors.second_blue};
    box-shadow: none;

    svg {
      fill: ${Colors.white};
    }
  }
`;

const ReturnClose = styled(View)`
  height: 40px;
  width: auto;
  border-radius: 25px;
  padding-left: 14px;
  padding-right: 18px;
  color: ${Colors.white};
  font-size: 16px;

  span {
    margin-right: 5px;
    margin-top: -2px;
  }
`;
