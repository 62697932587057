import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { debounce } from 'throttle-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { translations } from '@libs';
import { ON_OPEN_GALLERY, ON_OPEN_STORE, ON_SEND_COUNT_NOTIFICATION_EVENTS } from '@observer/constants';
import * as getData from './modules/getdata';
import * as effects from './modules/effects';
import { setNotifications, setLastNoticeId } from './modules/Store/action';
import { rail_y, thumb_y } from './components/scroll';
import { Notice } from './components/notice';

const observer = new window.POPObserver();
const debounceFunc = debounce(750, (fun) => fun());

export const App = () => {
  const dispatch = useDispatch();
  const { notifications, lastIdNotification, settings: { onClose, locale, coordinates }} = useSelector(state => state);
  const listRef = useRef();
  const [loading, seLoading] = useState(false);
  const [scrollRef, setScrollRef] = useState(null);

  const getDate = (date) => {
      return new Date(Number(date)).toLocaleString(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC'
      });
    };
  const getCaption = (item) => {
    try {
      const caption = item.data.caption;

      if (caption) {
        if (caption[locale]) {
          return caption[locale];
        }

        if (typeof caption === 'string') return caption;

        let findCaption = '';

        for (let key in caption) {
          if (Object.prototype.hasOwnProperty.call(caption, key)) {
            if (caption[key]) {
              findCaption = caption[key];
            }
          }
        }

        return findCaption;
      }

      return item.settings.caption ? item.settings.caption : '';
    } catch (error) {
      console.error(error);

      return '';
    }
  };
  const openLink = (link) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };
  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, {});
  };
  const openGallery = (projectId, openImageId) => {
    observer.postMessage(ON_OPEN_GALLERY, {
      projectId,
      openImageId,
    });
  };
  const onClick = (item) => {
    if (item.data.project_id) {
      return openGallery(item.data.project_id, item.data.shId);
    }

    if (item.data.link) {
      if (typeof item.data.link === 'object' && item.data.link[locale]) {
        return openLink(item.data.link[locale]);
      }

      if (typeof item.data.link === 'string') {
        return openLink(item.data.link);
      }
    }

    if (item.data.tariff) {
      return openStore();
    }
  };
  const onScrollList = (container) => {
    const containerSize = container.getBoundingClientRect();
    const bottomContainer = containerSize.top + containerSize.height;
    const items = container.querySelectorAll('div[data-id]');

    items.forEach((item) => {
      const itemSize = item.getBoundingClientRect();
      const bottomItem = itemSize.y + itemSize.height - 10;

      if (bottomItem <= bottomContainer) {
        if (lastIdNotification < Number(item.dataset.id)) {
          dispatch(setLastNoticeId(Number(item.dataset.id)));
        }
      }
    });
  };
  const getScrollRef = (container) => {
    setScrollRef(container);
  };
  const onRemoveAll = () => {
    const lastNotify = notifications[notifications.length-1];

    dispatch(effects.setUserSettings({ lastNoticeId: lastNotify.id }));
    dispatch(setNotifications([]));

    onClose();
  };
  const removeViewedNotification = () => {
    const notViewList = notifications.filter((notify) => notify.id > lastIdNotification);

    observer.postMessage(ON_SEND_COUNT_NOTIFICATION_EVENTS, notViewList.length);
    dispatch(setNotifications(notViewList));
  };
  const beforeClose = () => {
    removeViewedNotification();
    onClose();
  };
  const loadData = async () => {
    seLoading(true);
    await dispatch(getData.getNotifications());
    seLoading(false);
  };

  useEffect(() => {
    if (scrollRef) {
      onScrollList(scrollRef);
    }
  }, [scrollRef]);

  useEffect(() => {
    if (lastIdNotification) {
      debounceFunc(() => {dispatch(effects.setUserSettings({ lastNoticeId: lastIdNotification }))});
    }
  }, [lastIdNotification]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <View>
      <Overlay onClick={beforeClose} />

      {loading && (
        <Content coordinates={coordinates}>
          <Loading>
            <Preloader width="45px" height="45px" fill={Colors.main_blue} />
          </Loading>
        </Content>
      )}

      {!loading && Boolean(notifications && notifications.length) && (
        <Content coordinates={coordinates}>
          <List ref={listRef}>
            <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150}} onScrollY={onScrollList} containerRef={getScrollRef} rail_y={rail_y} thumb_y={thumb_y}>
              {notifications.map((notice) => (
                <Notice key={notice.id}
                        notice={notice}
                        getDate={getDate}
                        getCaption={getCaption}
                        onClick={onClick} />
              ))}
            </ScrollBar>
          </List>
          <DeleteWrap>
            <Delete onClick={onRemoveAll}>{translations.t('cabinet.notifications.clear')}</Delete>
          </DeleteWrap>
        </Content>
      )}

      {!loading && !Boolean(notifications && notifications.length) && (
        <Content coordinates={coordinates}>
          <Text>{translations.t('cabinet.notifications.not')}</Text>
        </Content>
      )}
    </View>
  );
};

const View = styled.div`
  font-family: 'CoFoSans', 'sans-serif';
  font-weight: 400;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.black, 0.5)};
  z-index: 1;
`;
const Content = styled.div`
  width: 280px;
  background-color: ${Colors.white};
  max-height: 420px;
  position: fixed;
  top: ${({ coordinates }) => typeof coordinates?.top === 'number' ? `${coordinates.top}px` : 'auto'};
  left: ${({ coordinates }) => typeof coordinates?.left === 'number' ? `${coordinates.left}px` : 'auto'};
  right: ${({ coordinates }) => typeof coordinates?.right === 'number' ? `${coordinates.right}px` : 'auto'};
  bottom: ${({ coordinates }) => typeof coordinates?.bottom === 'number' ? `${coordinates.bottom}px` : 'auto'};
  z-index: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
const List = styled.div`
  overflow: hidden;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
`;
const DeleteWrap = styled.div`
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  text-align: center;
  color: ${Colors.blueBell};
`;
const Delete = styled.span`
  cursor: pointer;
`;
const Text = styled.div`
  padding: 28px 10px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;