import React from 'react';
import styled from 'styled-components';
import { Table } from './Table';
import { Pagination } from './Pagination';
import { translations, formatDate } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { Close } from '../../../components/Close';

export const TableContainer = ({ history, processing, pageCount, currentPage, onPageChange, email, locale }) => {
  const columns = [
    {
      header: translations.t('cabinet.history.table.date'),
      accessor: 'date',
      style: {
        width: '10%',
        alignItems: 'start',
      },
      sortable: false,
      render: ({ date }) => <Col>{formatDate(date)}</Col>,
    },
    {
      header: translations.t('cabinet.history.table.order'),
      accessor: 'id',
      style: {
        width: '8%',
      },
      sortable: false,
      render: ({ id }) => <InnerCol><ColBold>{id}</ColBold></InnerCol>,
    },
    {
      header: translations.t('cabinet.history.table.name'),
      accessor: 'name',
      style: {
        width: '23%',
      },
      sortable: false,
      render: ({ items }) => (
        <InnerCol>
          {items.length === 1 ? (
            <ColBold title={locale === 'ru' ? items[0].name.ru : items[0].name.en}>
              {locale === 'ru' ? items[0].name.ru : items[0].name.en}
            </ColBold>
          ) : (
            <ColBold>{translations.t('cabinet.history.table.composition')}</ColBold>
          )}
          {items.length > 1 &&
            items.map((item, index) => (
              <Col title={locale === 'ru' ? item.name.ru : item.name.en} key={index}>
                {locale === 'ru' ? item.name.ru : item.name.en}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.count'),
      accessor: 'count',
      sortable: false,
      style: {
        width: '7%',
        textAlign: 'right',
      },
      render: ({ items }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].count}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.count}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.price'),
      accessor: 'price',
      sortable: false,
      style: {
        width: '7%',
        textAlign: 'right',
      },
      render: ({ items, currency }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].price} ${getCurrency(currency)}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.price} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.vat'),
      accessor: 'vat',
      sortable: false,
      style: {
        width: '7%',
        textAlign: 'right',
      },
      render: ({ items, currency }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].vat} ${getCurrency(currency)}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.vat} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.sum'),
      accessor: 'sum',
      sortable: false,
      style: {
        width: '7%',
        textAlign: 'right',
      },
      render: ({ items, currency, sum }) => (
        <InnerCol>
          <ColBold>
            {sum} {getCurrency(currency)}
          </ColBold>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.total} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: '',
      accessor: 'account',
      style: {
        color: Colors.feldgrau25,
        width: '7%',
        textAlign: 'center',
      },
      render: () => (
        <InvoiceCol>
          {translations.t('cabinet.history.table.account')}
        </InvoiceCol>
      ),
    },
  ];

  return (
    <Container>
      <Top>
        <Title>{translations.t('cabinet.history.title')}</Title>
        <Close />
      </Top>
      <Bottom>
        {!history.length && <NotOptions>{translations.t('cabinet.history.noPayments').replace('\\','')}</NotOptions>}

        {history.length ? <Table data={history} columns={columns} modify={'alignTop'} isSort={false} /> : null}
        {pageCount > 1 && <Pagination pageCount={pageCount} currentPage={currentPage} pageRange={2} onPageChange={onPageChange} />}
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
`;
const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 10px;
  margin-left: 6px;
`;
const Bottom = styled.div`
  padding-right: 80px;
`;
const NotOptions = styled.p`
  font-size: 28px;
  color: ${Colors.nobel};
  margin: 0 0 30px;
`;

const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceCol = styled.div`
  color: ${Colors.feldgrau25};
`;

const Col = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const ColBold = styled(Col)`
  font-weight: 400;
`;
