import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { DIRECTIONS } from '../../../constants';
import { getDirectionElement } from '../../../modules/helpers';

export const ListEdit = ({ id,  list, onClose }) => {
  const {settings: { zoom }} = useSelector(state => state);
  const listRef = useRef(null);
  const [direction, setDirection] = useState(DIRECTIONS.rightBottom);
  const [visible, setVisible] = useState(false);

  const onClickAction = (func) => {
    onClose();
    func(id);
  };

  useEffect(() => {
    setDirection(getDirectionElement(listRef.current, zoom));
    setVisible(true);
  }, [listRef]);

  return (
    <View>
      <Overlay onClick={onClose} />

      <List ref={listRef} direction={direction} visible={visible}>
        {list.map((item, index) => (
          <Item isDisabled={item.disabled} key={index} onClick={() => onClickAction(item.func)}>{item.name}</Item>
        ))}
      </List>
    </View>
  )
};

const View = styled.div`
  position: relative;
`;
const Overlay = styled.div`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const List = styled.ul`
  ${Mixins.resetList};
  position: absolute;
  margin-top: 2px;
  z-index: 4;
  padding: 10px 0;
  overflow: hidden;
  min-width: 140px;
  background-color: ${Colors.white};
  box-shadow: 1px 2px 4px 0px #2929290A,
    3px 7px 8px 0px #29292908,
    8px 16px 11px 0px #29292905,
    14px 29px 13px 0px #29292903,
    21px 45px 14px 0px #29292900;

  border-radius: 5px;
  opacity: ${({ visible }) => visible ? 1 : 0};
  
  ${({ direction }) => {
    switch (direction) {
      case DIRECTIONS.rightBottom: return `
        top: 0;
        left: 0;
      `;
      case DIRECTIONS.leftBottom: return `
        top: 0;
        right: 0;
      `;
      case DIRECTIONS.rightTop: return `
        bottom: 27px;
        left: 0;
      `;
      case DIRECTIONS.leftTop: return `
        bottom: 27px;
        right: 0;
      `;
    }  
  }}
`;
const Item = styled.li`
  padding: 5px 20px;
  white-space: nowrap;
  cursor: pointer;
  
  ${({ isDisabled }) => isDisabled && `
    color: ${Colors.light_gray};
    pointer-events: none;
  `}
  
  &:hover {
    background-color: ${Colors.main_blue};
    color: ${Colors.white};
  }
`;
