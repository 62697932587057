let instance = null;

class AnalyticsInstance {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.messages = {};

    return instance;
  }

  init({ locale }) {
    this.locale = locale;
  }

  _pushView(page, title) {
    if (!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'Pageview',
      pagePath: `/#${this.locale}/${page}`,
      pageTitle: `${title} (standalone)`,
    });
  };

  _pushEventGA4(evName, evParam1, evParam2, evParam3, evParam4) {
    if (!window.dataLayer) {
      return;
    }

    const params = {
      'event': 'ga4_Event',
      'evName': evName,
      'evParam1': evParam1,
      'evParam2': evParam2,
      'evParam3': evParam3,
      'evParam4': evParam4
    };

    for (let i = 1; i <= 4; i++) {
      if (typeof params[`evParam${i}`] === 'undefined') {
        delete params[`evParam${i}`];
      }
    }

    window.dataLayer.push(params);
  };

  viewLayout() {
    this._pushView(`cabinet/`, 'Проекты');
  }

  viewBalance() {
    this._pushView('cabinet/balance/', 'Баланс');
  };

  viewProfile() {
    this._pushView('cabinet/profile/', 'Профиль');
  };

  viewLoginSettings() {
    this._pushView('cabinet/login-settings/', 'Параметры входа');
  };

  viewPaymentMethods() {
    this._pushView('cabinet/payment-methods/', 'Способы оплаты');
  };

  viewPurchaseHistory() {
    this._pushView('cabinet/purchase-history/', 'История платежей');
  };

  viewSubscribe() {
    this._pushView('cabinet/subscriptions/', 'Управление подпиской');
  };

  viewBanner(name = '') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'ga4_Event',
      'evName': `uiBanner`,
      'evParam1': 'view',
      'evParam2': name
    });
  };

  createProject(projectId) {
    this._pushEventGA4('project_event', 'create', projectId);
  };

  openProject(projectId) {
    this._pushEventGA4('project_event', 'open', projectId);
  };

  duplicateProject(projectId) {
    this._pushEventGA4('project_event', 'duplicate', projectId);
  };

  moveProject(projectId) {
    this._pushEventGA4('project_event', 'move', projectId);
  };

  deleteProject(projectId) {
    this._pushEventGA4('project_event', 'delete', projectId);
  };

  pinProject(projectId) {
    this._pushEventGA4('project_event', 'pin', projectId);
  };

  unPinProject(projectId) {
    this._pushEventGA4('project_event', 'unpin', projectId);
  };

  clickExtend(currentAlias, newAlias) {
    this._pushEventGA4('tariff_subscription', currentAlias, newAlias);
  };

  successUsePromo(name, start = '', end = '') {
    const startDate = start ? new Date(start * 1000).toLocaleDateString() : '';
    const endDate = end ? new Date(end * 1000).toLocaleDateString() : '';

    this._pushEventGA4('apply_promo_code', name, startDate, endDate, 100);
  };

  clickChangeOrExtend(currentAlias) {
    this._pushEventGA4('subscription_change', currentAlias);
  };

  clickCancelSubscribe(currentAlias) {
    this._pushEventGA4('subscription_cancel_begin', currentAlias);
  };

  clickCancelSubscribeStep(currentAlias, stepCount) {
    this._pushEventGA4(`subscription_cancel_${stepCount}`, currentAlias);
  };

  clickCancelSubscribeStepEnd(currentAlias) {
    this._pushEventGA4(`subscription_cancel_end`, currentAlias);
  };

  clickChangeLanguage(prevLang, newLang) {
    this._pushEventGA4(`lang_switch`, `${prevLang}-${newLang}`);
  };

  followingBannerLink(name = '') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'ga4_Event',
      'evName': 'uiBanner',
      'evParam1': 'click',
      'evParam2': name
    });
  };

  openStoreFromBanner(tariff, selectedTariff) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'ga4_Event',
      'evName': 'tariff_subscription',
      'evParam1': tariff,
      'evParam2': selectedTariff
    });
  };
}

export const Analytics = new AnalyticsInstance();
