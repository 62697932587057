import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { POP_SCREEN_ENTRY_LOGIN } from '@globalLocalStorage';
import { translations, LocalStorage, replaceVariables } from '@libs';
import { ENTRY_STEPS } from '@features/form-entry/constants';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const ConfirmRecoveryAccount = ({ isOpen, params = {}, setStep = () => {}, onRecovery = () => {}, onContinue = () => {}, onClose = () => {} }) => {
  const { email, deletedByEmail = false, deleted = true, cbContinue = () => {} } = params;

  const handleRecovery = () => {
    onClose();
    onRecovery({ email });
  };
  const handleContinue = () => {
    onClose();
    cbContinue();
  };
  const handleSupport = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: 'mailto:support@planoplan.com',
    });
  };
  const handleGoLogin = () => {
    onClose();

    if (email) {
      LocalStorage.set(POP_SCREEN_ENTRY_LOGIN, email);
    }
    
    setStep(ENTRY_STEPS.LOGIN);
  };

  const content = {
    a: {
      title: translations.t('form_entry.recovery.a.title'),
      text: translations.t('form_entry.recovery.a.text'),
      buttons: [{
        theme: 'secondary',
        text: translations.t('form_entry.recovery.return'),
        click: handleGoLogin
      }, {
        theme: 'primary',
        text: translations.t('form_entry.recovery.support'),
        click: handleSupport
      }]
    },
    b: {
      title: translations.t('form_entry.recovery.b.title'),
      text: translations.t('form_entry.recovery.b.text'),
      buttons: [{
        theme: 'primary',
        text: translations.t('form_entry.recovery.restore'),
        click: handleRecovery,
      }, {
        theme: 'secondary',
        text: translations.t('form_entry.recovery.continue'),
        click: handleContinue,
      }]
    },
    c: {
      title: translations.t('form_entry.recovery.c.title'),
      text: translations.t('form_entry.recovery.c.text'),
      buttons: [{
        theme: 'primary',
        text: translations.t('form_entry.recovery.restore'),
        click: handleRecovery,
      }, {
        theme: 'secondary',
        text: translations.t('form_entry.recovery.continue'),
        click: handleContinue,
      }]
    },
    d: {
      title: translations.t('form_entry.recovery.d.title'),
      text: translations.t('form_entry.recovery.d.text'),
      buttons: [{
        theme: 'primary',
        text: translations.t('form_entry.recovery.login'),
        click: handleGoLogin,
      }, {
        theme: 'secondary',
        text: translations.t('form_entry.recovery.continue'),
        click: handleContinue,
      }]
    },
  }

  const getContent = () => {
    if (!email) return content.a;

    if (deleted) {
      if (deletedByEmail) return content.b;

      return content.c;
    } else {
      return content.d;
    }
  }

  const currentContent = getContent();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Header>{currentContent.title}</Header>
      <Content dangerouslySetInnerHTML={{ __html: replaceVariables(currentContent.text, { email }) }} />
      <Buttons>
        {currentContent.buttons.map((button, index) => (
          <Button key={index} theme={button.theme} size="M" onClick={button.click}>{button.text}</Button>
        ))}
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
