import axios from 'axios';
import { isProdHost, isBeta, isDevHost, isLocalHost } from '@libs';

class TelegramBotClass {
  constructor() {
    this.token = '7628080312:AAFVpo7QSBM_nuc7xg9L9I-bnQmbADU6VIY'; // Токен вашего бота
    this.chatId = '-1002409122910'; // Chat ID канала или группы
    this.apiUrl = `https://api.telegram.org/bot${this.token}/sendMessage`;
  }

  /**
   * Отправляет сообщение в Telegram-канал.
   * @param {string} text - Текст сообщения.
   * @returns {Promise<void>}
   */
  async sendMessage(text) {
    if (isLocalHost) return;

    const url = `${this.apiUrl}?chat_id=${this.chatId}&text=${encodeURIComponent(text)}&parse_mode=HTML`;

    try {
      const response = await axios.get(url);

      if (!response.ok) {
        throw new Error(`Ошибка при отправке сообщения: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  }

  /**
   * Отправляет сообщение об ошибке в Telegram-канал.
   * @param {Error} error - Объект ошибки.
   * @returns {Promise<void>}
   */
  async sendError(title = '', error) {
    const mode = this.getTextHostMode();
    const stackLines = error.stack.split('\n').slice(0, 10).join('\n');
    const errorMessage = `<b>${mode}</b>\n${title}\nОшибка: ${error.message}\nСтек: ${stackLines}`;

    await this.sendMessage(errorMessage);
  }

  getTextHostMode() {
    if (isProdHost) return 'PRODUCTION';
    if (isBeta) return 'BETA';
    if (isDevHost) return 'DEV';
    if (isLocalHost) return 'localhost';
  }
}

export const TelegramBot = new TelegramBotClass();