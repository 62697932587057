import { POP_CURRENT_TEAM } from '@globalLocalStorage';
import { LocalStorage } from '@libs';
import {
  SET_PAGE,
  SET_TEAM_ID,
  SET_PROFILE,
  SET_CURRENT_TEAM,
  SET_RENDER_GOODS,
  SET_WIDGET_GOODS,
  SET_SUBSCRIPTIONS,
  SET_HISTORY_PAYMENT_DATA,
  SET_PROCESSING,
  SET_CARDS,
  SET_OPENED_PROJECT,
} from '../../constants';

export const setPage = (alias) => (dispatch, getState) => {
  dispatch({ type: SET_PAGE, payload: alias });
};

export const setTeamId = (id) => (dispatch, getState) => {
  dispatch({ type: SET_TEAM_ID, payload: id });

  LocalStorage.set(POP_CURRENT_TEAM, id);
};

export const setCurrentTeam = (team) => (dispatch, getState) => {
  dispatch({ type: SET_CURRENT_TEAM, payload: team });
};

export const removeProfile = () => (dispatch) => {
  dispatch({ type: SET_PROFILE, payload: null });
};

export const setRenderGoods = (goods) => (dispatch) => {
  dispatch({ type: SET_RENDER_GOODS, payload: goods });
};

export const setWidgetGoods = (goods) => (dispatch) => {
  dispatch({ type: SET_WIDGET_GOODS, payload: goods });
};

export const setSubscriptions = (notificatons) => (dispatch) => {
  dispatch({ type: SET_SUBSCRIPTIONS, payload: notificatons});
}

export const setHistoryPaymentData = (data) => (dispatch) => {
  dispatch({ type: SET_HISTORY_PAYMENT_DATA, payload: data });
}

export const setProcessing = (data) => (dispatch) => {
  dispatch({ type: SET_PROCESSING, payload: data });
}

export const setCards = (data) => (dispatch) => {
  dispatch({ type: SET_CARDS, payload: data });
}

export const setOpenedProjectId = (id) => (dispatch) => {
  dispatch({ type: SET_OPENED_PROJECT, payload: id });
}