import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Analytics } from '../../modules/analytics';
import { rail_y, thumb_y } from '../../components/scroll';
import { TableContainer } from './organism/TableContainer';
import * as effects from './effects';

export const PagePurchaseHistory = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    historyPayment: {
      list, processing, pageCount, perPage
    },
    settings: {
      locale,
    },
  } = useSelector((state) => state);

  const onPageChange = (id) => {
    setCurrentPage(id);
    dispatch(effects.getHistory(id - 1));
  };

  useEffect(() => {
    Analytics.viewPurchaseHistory();
  }, []);

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <Content>
          <TableContainer
              history={list}
              processing={processing}
              pageCount={pageCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
              locale={locale}
            />
        </Content>
      </View>
    </ScrollBar>
  );
};

const View = styled.div``;

const Content = styled.div`
  overflow: hidden;
  padding: 20px 0 20px 20px;
`;
