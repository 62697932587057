import { translations, LocalStorage } from '@libs';
import { POP_PROFILE } from '@globalLocalStorage';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PROFILE,
  SET_GOODS,
  SET_TEAMS,
  SET_TEAM_MEMBERS,
  SET_EXPIRED_TEAM,
  SET_SUBSCRIPTIONS,
  SET_RENDER_GOODS,
  SET_WIDGET_GOODS,
  SET_MORE_PROJECTS_ITEMS,
  SET_PROJECTS,
  RESET_PROJECTS,
  GET_TRANSLATES,
  GET_PROFILE,
  GET_GOODS,
  GET_TEAMS,
  GET_TEAM_MEMBERS,
  GET_PROJECTS,
  GET_SUBSCRIPTIONS,
  GET_HISTORY,
  GET_CARDS,
  SET_HISTORY_PAYMENT_DATA,
  SET_PROCESSING,
  SET_CARDS,
} from '../constants';
import { ApiCall } from './api-call';
import { normalizeRenderingGoods, normalizeWidgetGoods, normalizeHistoryPayment, normalizeTeamMembers } from './normalize';
import { notifications } from '@features/notifications';
import { filterNotification } from './helpers';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale, [
        /^users./,
        /^cabinet./,
        /^modal./,
        /^form_entry./,
        /^store./,
        /^quickstore./,
        /^pricing.shortcontent./,
        /^gallery./,
        /^projects./,
        /^banners./,
        /^assistant./
      ]);
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: error.message },
      });
    }
  }
};

export const getProfile = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROFILE } });

  try {
    const response = await ApiCall.getProfile();

    LocalStorage.set(POP_PROFILE, response);
    dispatch({ type: SET_PROFILE, payload: response });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PROFILE, error: error },
    });
  }
};

export const getGoods = () => async (dispatch, getState) => {
  const {
    team,
  } = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GOODS } });

  try {
    dispatch({ type: SET_EXPIRED_TEAM, payload: false });

    const { data } = await ApiCall.getGoods({ teamId: team.id });

    if (!data.success) {
      if (team.id && data.errorText === 'Time expired') {
        dispatch({ type: SET_EXPIRED_TEAM, payload: true });
      }

      throw data.errorText;
    }

    const { goods } = data.data;

    dispatch({ type: SET_GOODS, payload: data.data });
    dispatch({ type: SET_RENDER_GOODS, payload: normalizeRenderingGoods(goods) });
    dispatch({ type: SET_WIDGET_GOODS, payload: normalizeWidgetGoods(goods) });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_GOODS, error: error },
    });
  }
};

export const getTeams = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_TEAMS } });

  try {
    const { data } = await ApiCall.getTeams();

    dispatch({ type: SET_TEAMS, payload: data.teams });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TEAMS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_TEAMS, error: error },
    });
  }
};

export const getTeamMembers = () => async (dispatch, getState) => {
  const {
    team,
  } = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_TEAM_MEMBERS } });

  try {
    if (team.id) {
      const { data } = await ApiCall.getTeamMembers({ teamId: team.id });

      dispatch({ type: SET_TEAM_MEMBERS, payload: normalizeTeamMembers(data.users) });
    } else {
      dispatch({ type: SET_TEAM_MEMBERS, payload: [] });
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TEAM_MEMBERS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_TEAM_MEMBERS, error: error },
    });
  }
};

export const getProjects = (params, loadMore = false) => async (dispatch, getState) => {
  const { projects: { items }} = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROJECTS } });

  try {
    const response = await ApiCall.getProjects(params);

    if (loadMore) {
      dispatch({ type: SET_MORE_PROJECTS_ITEMS, payload: response });
    } else {
      const projects = response.items.map((project) => {
        const duplicateProject = items.find(item => item.id === project.id && item.row_type === project.row_type);

        return {
          ...project,
          selected: duplicateProject ? duplicateProject.selected : false,
        }
      });

      dispatch({ type: SET_PROJECTS, payload: { ...response, items: projects }});
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROJECTS } });
  } catch (error) {
    console.error(error);

    dispatch({ type: RESET_PROJECTS, payload: {}});
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PROJECTS, error: error },
    });
  }
};

export const getSubscriptions = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_SUBSCRIPTIONS } });

  try {
    const { notifications, notifications_off } = await ApiCall.getSubscriptions();

    for (let i in notifications) {
      const item = notifications[i];
      if (notifications_off.indexOf(item.alias) !== -1) {
        item.active = false;
      } else {
        item.active = true;
      }
    }

    dispatch({ type: SET_SUBSCRIPTIONS, payload: notifications });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_SUBSCRIPTIONS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_SUBSCRIPTIONS, error: error },
    });
  }
};

export const getHistory = (currentPage = 0) => async (dispatch, getState) => {
  const {
    historyPayment: { perPage },
  } = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_HISTORY } });

  try {
    const response = await ApiCall.getHistory(currentPage * perPage, perPage);

    if (!response || !response.success) {
      notifications.showError(translations.t('cabinet.notification.fetchHistory.error'));

      return;
    }
    const { history, total_count_history } = response.data;
    const historyData = normalizeHistoryPayment(history);

    dispatch({
      type: SET_HISTORY_PAYMENT_DATA,
      payload: { history: historyData, pageCount: Math.ceil(parseInt(total_count_history) / perPage) },
    });
    dispatch({ type: SET_PROCESSING, payload: false });

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_HISTORY } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_HISTORY, error: error },
    });
  }
};

export const getCards = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_CARDS } });

  try {
    const response = await ApiCall.getCards();

    if (!response || !response.success) {
      notifications.showError(translations.t('cabinet.notification.fetchCards.error'));

      return;
    }

    const cards = response.data.items.filter((item) => item.type === 'bank_card' || item.type === 'card');

    cards.sort((a, b) => b.is_default - a.is_default);

    dispatch({ type: SET_CARDS, payload: cards });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_CARDS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_CARDS, error: error },
    });
  }
};
