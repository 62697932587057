import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { Analytics } from '../../../modules/analytics';
import { TARIFF_ALIAS } from '../../../constants';

export const Banner = ({ banner = {} }) => {
  const { tariff } = useSelector((state) => state);
  const userTariff = tariff.is_trial ? TARIFF_ALIAS.TRIAL_PRO : tariff.alias;

  const handleClick = (urlPath) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: `${urlPath}`,
    });

    Analytics.followingBannerLink(banner.caption);

    if (urlPath.includes('https://planoplan.com/ru/cabinet/')) {
      const parsedUrl = new URL(urlPath);
      const selectedTariff = parsedUrl.searchParams.get('tariff');

      if (selectedTariff) {
        Analytics.openStoreFromBanner(userTariff, selectedTariff);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      Analytics.viewBanner(banner.caption);
    });
  }, []);

  return (
    <View hasLink={!!banner.url} onClick={() => handleClick(banner.url)} >
      <img src={banner.image} alt={banner.caption} />
    </View>
  );
};

const View = styled.div`
  margin: 12px 0 0;
  width: 190px;
  max-height: fit-content;
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};

  img {
    width: inherit;
    height: inherit;
  }
`;