import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import * as effects from '../effects';
import { useDispatch } from 'react-redux';
import { Confirm } from './confirm';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const Promo = () => {
  const dispatch = useDispatch();

  const initPromoField = {
    promo: { value: '' },
  };
  const [promoField, setPromoField] = useState(initPromoField);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const onPromoOk = (e, isConfirm) => {
    e.preventDefault();
    if (promoField?.promo?.value) {
      let confirm;
      if(isConfirm) {
        confirm = 1;
      }
      dispatch(effects.setPromo(promoField.promo.value, setConfirmIsOpen, confirm));
    }
  };

  const onChange = ({ name, value }) => {
    setPromoField((prevState) => {
      return { ...prevState, promo: { ...prevState, value } };
    });
  };

  const onKeyPress = ({ name, event }) => {
    if (name === 'promo' && event.key === 'Enter') {
      onPromoOk(event);
    }
  };

  const onPromoClose = () => {
    setConfirmIsOpen(false);
  };

  return (
    <View>
      <Input
        type="text"
        name="promo"
        value={promoField?.promo?.value}
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder={translations.t('cabinet.balance.promo.enter')}>
        {translations.t('cabinet.balance.promocode')}
      </Input>

      <ButtonRoute onClick={onPromoOk}>
        <span>{translations.t('cabinet.profile.apply')}</span>
      </ButtonRoute>
      {confirmIsOpen && (
        <Confirm
          onAgree={(event) => {
            onPromoOk(event, true);
          }}
          onCancel={onPromoClose}
          title={translations.t('cabinet.balance.confirm.title')}
          text={translations.t('cabinet.balance.confirm.text')}
          cancelText={translations.t('cabinet.balance.confirm.cancel')}
          aggreeText={translations.t('cabinet.balance.confirm.agree')}
        />
      )}
    </View>
  );
};

export const View = styled.div`
  position: relative;
`;

const ButtonRoute = styled.div`
  ${Mixins.transition('background-color, border-color, box-shadow')};
  position: absolute;
  top: 28px;
  right: 10px;
  height: 30px;
  max-width: 93px;
  padding: 8px;
  color: ${Colors.white};
  background-color: ${Colors.main_blue};
  border-radius: 25px;
  font-size: 12px;
  cursor: pointer;
  
  &:hover {
    box-shadow: 0px 2px 4px 0px ${Colors.main_blue}17,
        0px 7px 7px 0px ${Colors.main_blue}14,
        0px 16px 9px 0px ${Colors.main_blue}0D,
        0px 28px 11px 0px ${Colors.main_blue}03,
        0px 43px 12px 0px ${Colors.main_blue}00;
  }
  
  &:active {
    background-color: ${Colors.second_blue};
    border-color: ${Colors.second_blue};
    box-shadow: none;

    svg {
      fill: ${Colors.white};
    }
  }
  
  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }
`;
