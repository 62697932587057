import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Avatar = ({ onDelete, src, width = 150, height = 150, cover = false, isLogo = false, ...restProps }) => (
  <Container width={width} height={height} isLogo={isLogo}>
    {src && src !== 'DELETED' && (
      <Image src={src} width={width} height={height} isLogo={isLogo} cover={cover} {...restProps} alt="avatar" />
    )}
  </Container>
);

const Container = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  border-radius: ${({ isLogo }) => isLogo ? '5px' : '5px 0 0 5px'};
  border: ${({src}) => src !== '' ? '0px' : '1px solid #4F5B60'};
  position: relative;
`;
const Image = styled.img`
  width: ${({ width }) => `${width}px}`};
  height: ${({ height }) => `${height}px}`};
  border-radius: ${({ isLogo }) => isLogo ? '5px' : '5px 0 0 5px'};
  overflow: hidden;
  object-fit: ${({ cover }) => (cover ? 'cover' : 'contain')};
  object-position: center;
`;
const DeleteButton = styled.button`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid ${Colors.mint};
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%239E9E9E' d='M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.868L14.868 16 12 13.132 9.132 16 8 14.868 10.868 12 8 9.132 9.132 8 12 10.868 14.868 8 16 9.132 13.132 12 16 14.868z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 24px 24px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`;
