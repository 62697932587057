import { LocalStorage } from '@libs';
import { POP_USER_SETTINGS } from '@globalLocalStorage';
import { SET_LOADING_ON, SET_LOADING_OFF, GET_NOTIFICATIONS } from '../constants';
import { ApiCall } from './api-call';
import { filterNotification } from './helpers';
import { setNotifications, setLastNoticeId, setChecksum } from './Store/action';

export const getCountNotifications = async () => {
  const userSettings = LocalStorage.get(POP_USER_SETTINGS);
  const lastId = userSettings?.unitySettings?.lastReadedNotis || 0;
  const deep = 10; // Для локалки, что бы небыло замыкания. Сколько раз будет рекурсия

  try {
    if (!lastId) return 0;

    const requestNotifications = async (innerLastId, innerDeep) => {
      const params = { count: 30, last_id: innerLastId };
      const { data } = await ApiCall.getNotifications(params);
      const list = filterNotification(data.events);

      if (list && list.length) {
        return list.length;
      }

      const lastIdFromList = Boolean(data.events && data.events.length) ? data.events[data.events.length - 1].id : null;

      if (innerDeep && lastIdFromList) {
        return requestNotifications(lastIdFromList, innerDeep - 1);
      }

      return 0;
    };

    return requestNotifications(lastId, deep);
  } catch (error) {
    console.error(error);

    return 0;
  }
} 

export const getNotifications = () => async (dispatch, getState) => {
  const userSettings = LocalStorage.get(POP_USER_SETTINGS);
  const lastId = userSettings?.unitySettings?.lastReadedNotis || 0;
  const deep = 10; // Для локалки, что бы небыло замыкания. Сколько раз будет рекурсия

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_NOTIFICATIONS } });

  try {
    if (!lastId) {
      console.error('last id not found');
      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_NOTIFICATIONS } });

      return;
    }

    const requestNotifications = async (innerLastId, innerDeep) => {
      const params = {
        count: 30,
        last_id: innerLastId,
      };
      const { data } = await ApiCall.getNotifications(params);
      const list = filterNotification(data.events);

      if (list && list.length) {
        dispatch(setLastNoticeId(innerLastId));
        dispatch(setNotifications(list));
        dispatch(setChecksum(userSettings?.unitySettingsChecksum));
        dispatch({ type: SET_LOADING_OFF, payload: { name: GET_NOTIFICATIONS } });
      } else {
        const lastIdFromList = Boolean(data.events && data.events.length) ? data.events[data.events.length - 1].id : null;

        if (innerDeep && lastIdFromList) {
          await requestNotifications(lastIdFromList, innerDeep - 1);
        } else {
          dispatch({ type: SET_LOADING_OFF, payload: { name: GET_NOTIFICATIONS } });
        }
      }
    };

    await requestNotifications(lastId, deep);
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_NOTIFICATIONS, error: error },
    });
  }
};