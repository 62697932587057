// Entry screen
export const ON_LOADING_OFF_SCREEN_ENTRY = 'ON_LOADING_OFF_SCREEN_ENTRY';
export const ON_CLOSE_APP = 'ON_CLOSE_APP';

// Form entry
export const ON_SUCCESS_LOGIN = 'ON_SUCCESS_LOGIN';
export const ON_SUCCESS_SIGNUP = 'ON_SUCCESS_SIGNUP';
export const ON_RUN_STANDALONE = 'ON_RUN_STANDALONE';
export const ON_RECEIVED_TOKENS = 'ON_RECEIVED_TOKENS';
export const ON_INIT_GOOGLE_SDK = 'ON_INIT_GOOGLE_SDK';

// Request.js
export const NOT_TOKENS_FOR_REQUEST = 'NOT_TOKENS_FOR_REQUEST';
export const ON_AUTH_REQUEST_ERROR_400 = 'ON_AUTH_REQUEST_ERROR_400';

export const ON_CHANGE_USER = 'ON_CHANGE_USER';
export const ON_LOGOUT = 'ON_LOGOUT';
export const ON_CABINET = 'ON_CABINET';
export const ON_OPEN_STORE = 'ON_OPEN_STORE';
export const ON_OPEN_STORE_CART = 'ON_OPEN_STORE_CART';
export const ON_OPEN_CABINET = 'ON_OPEN_CABINET';
export const ON_CLOSE_CABINET = 'ON_CLOSE_CABINET';
export const ON_OPEN_GALLERY = 'ON_OPEN_GALLERY';
export const ON_OPEN_BANNER = 'ON_OPEN_BANNER';
export const ON_OPEN_QUICK_STORE = 'ON_OPEN_QUICK_STORE';
export const ON_OPEN_GETTING_STARTED = 'ON_OPEN_GETTING_STARTED';

// Gallery
export const ON_OPEN_IMAGE_EDITOR = 'ON_OPEN_IMAGE_EDITOR';
export const ON_SAVE_IMAGE_EDITOR = 'ON_SAVE_IMAGE_EDITOR';
export const ON_OPEN_DESIGN_PROJECT = 'ON_OPEN_DESIGN_PROJECT';
export const ON_OPEN_TOUR_EDITOR = 'ON_OPEN_TOUR_EDITOR';
export const ON_CLOSE_TOUR_EDITOR = 'ON_CLOSE_TOUR_EDITOR';
export const ON_GALLERY_HIDE = 'ON_GALLERY_HIDE';
export const ON_GALLERY_SHOW = 'ON_GALLERY_SHOW';

// Store
export const ON_CHANGE_PAYMENT_STATUS_STORE = 'ON_CHANGE_PAYMENT_STATUS_STORE';
export const ON_BINDING_CARD_SUCCESS = 'ON_ADD_CARD_SUCCESS';

//
export const ON_ROUTE_CABINET = 'ON_ROUTE_CABINET';
export const ON_SCROLL_LOCK_LOCK = 'ON_SCROLL_LOCK_LOCK';
export const ON_SCROLL_LOCK_UNLOCK = 'ON_SCROLL_LOCK_UNLOCK';

export const ON_ROUTE = 'ON_ROUTE';
export const ON_MOTION_REQUEST = 'ON_MOTION_REQUEST'; // ios 13 запрос положения устройства vr

// Открытие попала с сообщение что свободных лицензий нету.
export const ON_SHORTAGE_TEAM_LICENSE = 'ON_SHORTAGE_TEAM_LICENSE';

// Проверка на свободные лицензии при создании проекта
export const ON_UPDATE_TEAM_LICENSE = 'ON_UPDATE_TEAM_LICENSE';

export const ON_CHANGE_ZOOM = 'ON_CHANGE_ZOOM';
export const ON_CHANGE_LANGUAGE = 'ON_CHANGE_LANGUAGE'; // Для загрузки перевода
export const ON_LANGUAGE_LOAD = 'ON_LANGUAGE_LOAD'; // Событие после загрузки переводов
export const ON_LANGUAGE_LOAD_FOR_PAGE = 'ON_LANGUAGE_LOAD_FOR_PAGE'; // Событие после загрузки переводов (для аналитики)

// Usedesk
export const USEDESK_CALLBACK = 'USEDESK_CALLBACK';

// Navigation
export const ON_UPDATE_CABINET_SCREEN = 'ON_UPDATE_CABINET_SCREEN';