import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from './Table';
import { translations, formatDate } from '@libs';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { IArrowNext } from 'planoplan-ui-kit/core/icons/i-arrow-next';
import { Button } from 'planoplan-ui-kit/core/button';
import { MODALS } from '@features/unity/constants';
import * as effects from '../effects';
import { TableActions } from './TableActions';
import * as getData from '../../../modules/getdata';
import {
  AddUserInTeamModal,
  RemoveUserFromTeamModal,
  useModals
} from '@components';
import { Close } from '../../../components/Close';
import { IconAdd } from '../../../components/icons/add';

export const TableContainer = ({ members }) => {
  const dispatch = useDispatch();
  const { team: { current }} = useSelector(state => state);
  const columns = [
    {
      header: 'ID',
      accessor: 'id',
      style: {
        width: '10%',
        alignItems: 'start',
      },
      sortable: true,
      align: 'left',
      render: ({ id }) => <InnerCol><ColBold>{id}</ColBold></InnerCol>,
    },
    {
      header: translations.t('cabinet.login'),
      accessor: 'email',
      style: {
        width: '19%',
      },
      sortable: true,
      align: 'left',
      render: ({ email }) => <InnerCol>{email}</InnerCol>,
    },
    {
      header: translations.t('cabinet.name'),
      accessor: 'name',
      style: {
        width: '8%',
      },
      sortable: true,
      align: 'left',
      render: ({ name }) => (<InnerCol>{name}</InnerCol>),
    },
    {
      header: translations.t('cabinet.renders'),
      accessor: 'render_stat.total',
      sortable: true,
      align: 'right',
      style: {
        width: '13%',
      },
      render: ({ render_stat }) => (
        <InnerColBetween>
          {render_stat.total}

            <Tooltip>
              <TooltipArrow disabled={!render_stat?.items?.length}>
                <IArrowNext width="14" height="14" fill={Colors.main_dark} />
              </TooltipArrow>

              <TooltipContent>
                {render_stat.items && render_stat.items.map((period) => (
                  <RenderPeriod key={period.date}>
                    <RenderPeriodDate>{period.date}</RenderPeriodDate>
                    {period.items.map((item, index) => (
                      <RenderPeriodItem key={index}>
                        <RenderPeriodCaption>{item.caption}</RenderPeriodCaption>
                        <RenderPeriodCount>{item.count}</RenderPeriodCount>
                      </RenderPeriodItem>
                    ))}
                  </RenderPeriod>
                ))}
              </TooltipContent>
            </Tooltip>
        </InnerColBetween>
      ),
    },
    {
      header: translations.t('cabinet.members.last_activity'),
      accessor: 'last_activity',
      sortable: true,
      align: 'right',
      style: {
        width: '15%',
      },
      render: ({ last_activity }) => (
        <InnerCol>{formatDate(last_activity)}</InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.projects.plural.5'),
      accessor: 'projects_count',
      sortable: true,
      align: 'right',
      style: {
        width: '10%',
      },
      render: ({ projects_count }) => (
        <InnerCol>{projects_count}</InnerCol>
      ),
    },
    {
      header: '',
      sortable: false,
      align: 'right',
      style: {
        width: '10%',
      },
      render: ({ id }) => ( (current && current.users_id !== id) &&
        <TableActions onBlockUser={() => onBlockUser(id)} onRemoveUser={() => openModal(MODALS.REMOVE_FROM_TEAM, id)} />
      ),
    },
  ];

  const [modals, openModal, closeAllModals] = useModals([MODALS.ADD_USER_IN_TEAM, MODALS.REMOVE_FROM_TEAM]);
  const openModalAddUser = () => openModal(MODALS.ADD_USER_IN_TEAM);
  const onAddUser = async (email) => {
    const status = await dispatch(effects.addUserInTeam(email));

    if (status) {
      closeAllModals();
    }
  };

  const onBlockUser = (id) => {
    dispatch(effects.blockUserInTeam(id));
  };

  const onRemoveUser = async () => {
    const id = modals[MODALS.REMOVE_FROM_TEAM].params;

    closeAllModals();
    await dispatch(effects.removeUserFromTeam(id));
    await dispatch(getData.getTeamMembers());
  };

  return (
    <View>
      <Top>
        <TopLeft>
          <Title>{translations.t('cabinet.members.title_page')}</Title>
          <ButtonInvoke theme="feldgrau" size="M" widthText={true} onClick={openModalAddUser}>
            {translations.t('cabinet.members.add.user')}
            <IconAdd fill={Colors.main_dark} />
          </ButtonInvoke>
        </TopLeft>

        <Close />
      </Top>

      <Container>
        {members.length ? <Table data={members} columns={columns} isSort={false} /> : null}

        <AddUserInTeamModal isOpen={modals[MODALS.ADD_USER_IN_TEAM].isOpen}
                            onCancel={closeAllModals}
                            onAddUser={(params) => onAddUser(params)}/>

        <RemoveUserFromTeamModal isOpen={modals[MODALS.REMOVE_FROM_TEAM].isOpen}
                                onCancel={closeAllModals}
                                onRemoveUser={onRemoveUser}/>
      </Container>
    </View>
  );
};

const View = styled.div`
  padding: 20px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-right: 60px;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const TopLeft = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-left: 6px;
  margin-right: 10px;
`;
const InnerCol = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InnerColBetween = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Col = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const ColBold = styled(Col)`
  font-weight: 400;
`;
const TooltipContent = styled.div`
  ${Mixins.transition('opacity')};
  position:absolute;
  top: 100%;
  right: -5px;
  width: 150px;
  background-color: ${Colors.white};
  border-radius: 4px;
  display: none;
  flex-direction: column;
  text-align: left;
  z-index: 1;
  font-size: 14px;
  box-shadow: 14px 29px 13px rgba(41, 41, 41, 0.01), 8px 16px 11px rgba(41, 41, 41, 0.02), 3px 7px 8px rgba(41, 41, 41, 0.03), 1px 2px 4px rgba(41, 41, 41, 0.04);
`;
const Tooltip = styled.div`
  position: relative;
  
  &:hover {
      ${TooltipContent} {
        display: flex;
      }
  }
`;
const TooltipArrow = styled.div`
  transform: rotate(90deg);
  cursor: pointer;
  margin-left: 5px;
  width: 24px;
  height: 24px;
  background-color: ${Colors.main_beige};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: ${({ disabled }) => disabled ? 0.3 : 1};
`;
const RenderPeriod = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.light_gray};
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
`;
const RenderPeriodDate = styled.div`
  border-bottom: 1px solid ${Colors.light_gray};
  text-align: right;
  color: ${Colors.light_gray};
  padding: 10px 20px 2px 5px;
`;
const RenderPeriodItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 10px;
`;
const RenderPeriodCaption = styled.div`
  margin-right: 10px;
`;

const RenderPeriodCount = styled.div`
  margin-right: 10px;
`;
const ButtonInvoke = styled(Button)`
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: normal;
  padding: 0 20px;
  border-color: ${Colors.main_dark};
  color: ${Colors.main_dark};

  svg {
    margin-left: 5px;
  }

  &:hover {
    background-color: inherit;
    color: inherit;
    box-shadow: 1px 1px 3px 0px ${Colors.main_dark}14,
    3px 4px 5px 0px ${Colors.main_dark}12,
    6px 9px 7px 0px ${Colors.main_dark}0A,
    11px 16px 8px 0px ${Colors.main_dark}03,
    17px 25px 8px 0px ${Colors.main_dark}00;
  }

  &:active {
    color: ${Colors.white};
    background-color: ${Colors.main_black};

    svg path {
      fill: ${Colors.white};
    }
  }
`;
