import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { createRows } from '../../../modules/normalize';

export const Table = ({ columns, data, isMultiSelectPanel, modify }) => {
  const [sortOrder, setSortOrder] = useState({
    slug: 'id',
    direction: 'asc',
  });

  const sort = (slug) => {
    if (sortOrder.slug === slug) {
      setSortOrder({
        slug,
        direction: sortOrder.direction === 'desc' ? 'asc' : 'desc',
      })
    } else {
      setSortOrder({
        slug,
        direction: 'asc'
      })
    }
  };

  const getValueForSort = (slug, item) => {
    const slugs = slug.split('.');
    let val = item;

    slugs.forEach((slug) => {
      val = val[slug];
    });

    return val;
  };

  data.sort((a, b) => {
    const { slug, direction } = sortOrder;
    const aVal = getValueForSort(slug, a);
    const bVal = getValueForSort(slug, b);

    if (typeof bVal === 'number') {
      return direction === 'desc' ? bVal - aVal : aVal - bVal;
    }

    if (!aVal.toLowerCase || !bVal.toLowerCase) {
      return 0;
    }

    if (aVal.toLowerCase() < bVal.toLowerCase()) {
      return direction === 'desc' ? -1 : 1;
    }

    if (aVal.toLowerCase() > bVal.toLowerCase()) {
      return direction === 'desc' ? 1 : -1;
    }

    return 0;
  });

  const rows = createRows(data, columns);

  return (
    <TableStyled modify={modify}>
      <thead>
        <tr>
          {columns.map(({ header, sortable, style, accessor, align }, i) => (
            <TH key={i} onClick={() => sortable && sort(accessor)} sortable={sortable} style={style} align={align}>
              {sortable && align === 'right' && <SortIndicator isRotate={sortOrder.slug === accessor && sortOrder.direction === 'desc'} isActive={sortOrder.slug === accessor} />}
              {header}
              {sortable && align === 'left' && <SortIndicator isRotate={sortOrder.slug === accessor && sortOrder.direction === 'desc'} isActive={sortOrder.slug === accessor} />}
            </TH>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <TR key={i} onClick={row.onClick} isMultiSelect={row.isMultiSelect} isMultiSelectPanel={isMultiSelectPanel}>
            {row.isMultiSelect}
            {row.map((cell, i) => (
              <TD key={i} style={cell.style} align={cell.align}>
                <Name>{cell.render}</Name>
              </TD>
            ))}
          </TR>
        ))}
      </tbody>
    </TableStyled>
  );
};

const Name = styled.div`
  padding-top: 1px;
`;

const TableStyled = styled.table`
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  border-collapse: initial;

  tbody td {
    vertical-align: ${({ modify }) => (modify === 'alignTop' ? 'top' : 'middle')};
    border-bottom: 1px solid ${Colors.feldgrau25};

    &:first-child {
        border-left: 1px solid ${Colors.feldgrau25};
    }

    &:last-child {
        border-right: 1px solid ${Colors.feldgrau25};
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const TD = styled.td`
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${Colors.main_dark};
  height: 48px;
  position: relative;
  padding: 20px;
  vertical-align: middle;
  text-align: ${p => p.align || 'left'};
  vertical-align: middle;
`;

const TR = styled.tr`
  border-radius: 10px;
  opacity: ${({ isMultiSelectPanel }) => (isMultiSelectPanel ? '0.5' : '1')};

  &:first-child td {
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
        border-top-right-radius: 10px;
    }
    border-top: 1px solid ${Colors.feldgrau25};
  }

  &:last-child td {
    &:first-child {
        border-bottom-left-radius: 10px;
    }
    &:last-child {
        border-bottom-right-radius: 10px;
    }
  }
`;

const TH = styled(TD)`
  font-weight: 400;
  line-height: normal;
  background: none;
  color: ${Colors.second_gray};
  height: 59px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(p) => p.style && p.style.width};
  user-select: none;
  text-align: ${p => p.align || 'left'};

  ${(p) =>
    p.sortable &&
    css`
      cursor: pointer;
    `}

  &:not(:last-child):after {
    display: none;
  }
`;

const SortIndicator = styled.div`
  ${Mixins.transition('transform')};
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  width: 12px;
  height: 10px;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(${(p) => (p.isRotate ? '180deg' : '0deg')});
  transform-origin: 50% 50%;
  
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    right: 3px;
    transform: rotate(-45deg);
    width: 5px;
    height: 5px;
    border: 1px solid transparent;
    border-bottom-color: ${Colors.main_blue};
    border-left-color: ${Colors.main_blue};
    opacity: ${({ isActive }) => isActive ? 1 : 0};
  }
`;
