import {
  SET_NOTIFICATIONS,
  SET_LAST_NOTIFICATION,
  SET_CHECKSUM,
} from '../../constants';

export const setNotifications = (value) => (dispatch) => {
  dispatch({ type: SET_NOTIFICATIONS, payload: value });
}

export const setLastNoticeId = (value) => (dispatch) => {
  dispatch({ type: SET_LAST_NOTIFICATION, payload: value });
}

export const setChecksum = (value) => (dispatch) => {
  dispatch({ type: SET_CHECKSUM, payload: value });
}
