import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

export const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};

export const MoveProjectModal = ({ isOpen, params = {}, onCancel = () => {}, onMove = () => {} }) => {
  const { projects: { currentFolder, foldersTree }, settings: { zoom }} = useSelector(state => state);
  const options = [{
    view_value: translations.t('modal.move.myProjects'),
    value: '0',
    render: <Option>{translations.t('modal.move.myProjects')}</Option>,
    render_selected: <div>{translations.t('modal.move.myProjects')}</div>,
    disabled: false,
  }];
  const [selected, setSelected] = useState(options[0]);
  const pushFolder = (folders = [], isDisabledParent = false) => {
    if (!Array.isArray(folders)) {
      return;
    }

    folders.forEach((folder) => {
      const data = folder.node;
      const sp = [];

      for (let i = 0; i < folder.level; i++) {
        sp.push(<Sp key={i} />);
      }

      const foldersIds = params.ids ? params.ids.filter((id) => id[0] === 'f').map(id => id.slice(2)) : [];
      const disabled = isDisabledParent || foldersIds.includes(`${data.id}`);

      const value = {
        view_value: data.name,
        value: `${data.id}`,
        render:
          <Option>
            {sp}
            {Boolean(folder.level) && <Nes />}
            {data.name}
          </Option>,
        render_selected: <div>{data.name}</div>,
        disabled: disabled || (currentFolder ? currentFolder.id === data.id : false)
      };

      options.push(value);

      if (folder.children) {
        pushFolder(folder.children, disabled);
      }
    });
  };

  pushFolder(foldersTree);

  const onSelected = (val) => !val.disabled && setSelected(val);

  useEffect(() => {
    setSelected(options[0]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.move.title')}</Header>

      <Content>
        <CustomSelect zoom={zoom}>
          <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
            {options.map((option) => (
              <ItemSelect key={option.value} disabled={option.disabled} selected={option.value === selected.value} onClick={() => onSelected(option)}>
                {option.render_option ? option.render_option : option.render}
              </ItemSelect>
            ))}
          </ScrollBar>
        </CustomSelect>
      </Content>

      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={() => onMove({ folderId: Number(selected.value) })}>
          {translations.t('modal.move')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
const Option = styled.div`
  display: flex;
`;
const Nes = styled.div`
  width: 8px;
  height: 6px;
  border-left: 1px solid ${Colors.light_gray};
  border-bottom: 1px solid ${Colors.light_gray};
  margin-right: 5px;
  margin-top: 6px;
`;
const Sp = styled.div`
  width: 8px;
`;
const CustomSelect = styled.div`
  border: 1px solid ${Colors.main_beige};
  border-radius: 4px;
  max-height: ${({ zoom }) => 50 / zoom}vh;
  overflow: auto;
  width: 100%;
  background-color: #f0f0f0;
`;
const ItemSelect = styled.div`
  ${Mixins.transition('color, background-color')};
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  color: ${({ disabled, selected }) => disabled ? Colors.second_light_gray : selected ? Colors.white : Colors.main_dark};
  background-color: ${({ selected }) => selected ? Colors.main_blue : 'transparent'};

  ${({ disabled }) => !disabled && `
    &:hover {
      color: ${Colors.white};
      background-color: ${Colors.main_blue};
    }
  `}
`;