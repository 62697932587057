export const NOTIFICATION_EVENTS_OPEN = 'NOTIFICATION_EVENTS_OPEN';
export const NOTIFICATION_EVENTS_CLOSE = 'NOTIFICATION_EVENTS_CLOSE';

export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_LAST_NOTIFICATION = 'SET_LAST_NOTIFICATION';
export const SET_CHECKSUM = 'SET_CHECKSUM';

// Getters
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const POSITIONS = {
  topRight: 'top-right', // пока этого достаточно
}