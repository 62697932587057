import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import {
  Login,
  SignUp,
  Check,
  Welcome,
  Social,
  RecoveryPassword,
  NewPassword,
  Success,
  Domains,
  Error,
  Skeleton,
  Update,
  Download,
  Thank,
  ThankLogin,
  Input,
} from './steps';
import { CHECKING_MODE, ENTRY_STEPS, LOGIN_MODE, ENTER_METHOD, OPEN_AUTH_MODAL } from './constants';
import { ON_INIT_GOOGLE_SDK, ON_LANGUAGE_LOAD } from '@observer/constants';
import * as effects from './modules/effects';
import * as login from './modules/login';
import * as signup from './modules/signup';
import { tablet_landscape } from './modules/breakpoints';
import { getCountryCode } from './modules/effects';
import * as actions from './modules/Store';
import { setLocale } from './modules/Store/actions';
import { LocalStorage } from '@libs';
import { POP_PRICING, POP_BANNERS, POP_TARIFFS } from '@globalLocalStorage';
import { MODE_ALIAS, UNITY } from '@globalConstants';
import { useModals, LogoutAnotherDevice, ConfirmRecoveryAccount } from '@components';
import { MODALS } from '@features/unity/constants';
import { getPricing, getBanners, getTariffs } from '../banners/modules/effects';


export const App = () => {
  const [loginData, setLoginData] = useState({});
  const { executeRecaptcha } = useGoogleReCaptcha();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [modals, openModal, closeAllModals] = useModals([MODALS.LOGOUT_ANOTHER_DEVICE, MODALS.CONFIRM_RECOVERY_ACCOUNT]);

  const {
    step,
    isProcessing,
    socialInfo,
    settings,
    checkingEmail,
    signUpMode,
    loginMode,
    checkingMode,
    domains,
    selectedDomain,
    updateStatus,
    downloadedPercent,
    standaloneLink,
    isCanResendCode,
    error,
    country,
  } = state;

  const isAfterLogin = checkingMode === CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN;
  const setStep = (step) => dispatch(effects.setStep(step));

  // Логин
  const onGetDomains = (username, password) => {
    setLoginData({ username, password });
    dispatch(login.getDomains(username));
  };
  const onEmailLogin = ({ endAllSessions = false } = {}) => dispatch(login.emailLogin(loginData.username, loginData.password, { openModal, endAllSessions }));
  const onSocialLogin = ({ endAllSessions = false } = {}) => dispatch(login.socialLogin({ openModal, endAllSessions }));
  const onSelectedDomain = (domain) => dispatch(effects.setSelectedDomain(domain));
  const onSuccessDomain = () => (loginMode === LOGIN_MODE.EMAIL ? onEmailLogin() : onSocialLogin());

  // social
  const onCheckGoogle = (code) => dispatch(effects.checkGoogle({ locale: settings.locale, code }));
  const onCheckVk = (method) => dispatch(effects.checkVk({ method, locale: settings.locale }));

  // Регистрация
  const onEmailSignUp = (username, password) => dispatch(signup.emailSignUp(username, password, executeRecaptcha, openModal));
  const onSubmitSocialSignUp = (username) => dispatch(signup.submitSocialSignUp(username));

  // Провека email после регистрации
  const onCheckEmail = (code) => dispatch(effects.checkEmail(code));
  const onResendCheckEmailCode = (code) => dispatch(effects.resendCheckEmailCode(code));

  // Сброс пароля
  const onRecoveryPassword = (email, props) => dispatch(effects.recoveryPassword(email, props));
  const onNewPassword = (password) => dispatch(effects.newPassword(password));
  const onConfirmRecoveryPassword = (code) => dispatch(effects.confirmRecoveryPassword(code));
  const onResendRecoveryPasswordCode = () => dispatch(effects.resendRecoveryPasswordCode());

  const onWelcome = (fields) => dispatch(effects.welcome(fields));
  const onClearError = () => dispatch(effects.clearError());
  const onSetCanResendCode = (status) => dispatch(effects.setCanResendCode(status));
  const onRetryUpdate = () => dispatch(effects.retryUpdate());
  const onSkipCheckEmail = () => dispatch(effects.onSkipCheckEmail());

  const onOpenCabinet = () => effects.openCabinet();
  const onRunStandalone = () => dispatch(effects.runStandalone());

  // Скачивание редактора
  const onDownloadStandalone = () => dispatch(effects.downloadStandalone());

  const renderGoogleButton = () => {
    if (!window.google) {
      return;
    }

    window.google.accounts.id.renderButton(
      document.getElementById('google-login-button'),
      {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangular',
        text: 'signin'
      }
    );
  };

  // Разлогинить все активные сессии
  const onAnotherAuthReset = () => {
    closeAllModals();

    loginMode === LOGIN_MODE.EMAIL ? onEmailLogin({ endAllSessions: true }) : onSocialLogin({ endAllSessions: true });
  };

  // Отмена входа при запуске на втором устройстве
  const onCancelLogin = () => closeAllModals();

  // События от modals
  const onRecoveryAccount = ({ email }) => onRecoveryPassword(email, { isRecoverAccount: true })

  useEffect(() => {
    (async () => {
      const country = await getCountryCode();
      dispatch(actions.setCountry(country));
      const pricing = await getPricing(settings.locale);
      LocalStorage.set(POP_PRICING, pricing);
      const banners = await getBanners(settings.locale, 'iinfopoints');
      LocalStorage.set(POP_BANNERS, banners);
      const tariffsContent = await getTariffs(settings.locale);
      LocalStorage.set(POP_TARIFFS, tariffsContent);
    })();

    if (loginMode === LOGIN_MODE.EMAIL && domains.length === 1) onEmailLogin();
    if (loginMode !== LOGIN_MODE.EMAIL && domains.length === 1) onSocialLogin();
    // eslint-disable-next-line
  }, [domains]);

  useEffect(() => {
    if ((step === ENTRY_STEPS.LOGIN || step === ENTRY_STEPS.SIGNUP) && MODE_ALIAS[settings.mode] !== UNITY) {
      renderGoogleButton();
    }
  }, [step]);

  useEffect(() => {
    const observer = new window.POPObserver();

    if ((step === ENTRY_STEPS.LOGIN || step === ENTRY_STEPS.SIGNUP) && MODE_ALIAS[settings.mode] !== UNITY) {
      observer.addEventListener(ON_INIT_GOOGLE_SDK, () => {
        if (!window.google) {
          return;
        }
        renderGoogleButton();
      });
    }

    observer.addEventListener(OPEN_AUTH_MODAL, ({ alias, params }) => openModal(alias, params));

    observer.addEventListener(ON_LANGUAGE_LOAD, (lang) => {
      dispatch(actions.setLocale(lang));
    });

    return () => {
      observer.removeEventListener(ON_INIT_GOOGLE_SDK);
      observer.removeEventListener(OPEN_AUTH_MODAL);
      observer.removeEventListener(ON_LANGUAGE_LOAD);
    }
  }, []);

  return (
    <Wrapper isProcessing={isProcessing} data-test={step}>
      {
        {
          [ENTRY_STEPS.LOGIN]: (
            <Login
              setStep={setStep}
              onGetDomains={onGetDomains}
              onVK={onCheckVk.bind(null, ENTER_METHOD.login)}
              onGoogle={onCheckGoogle.bind(null)}
              onClearError={onClearError}
              settings={settings}
              error={error}
              isProcessing={isProcessing}
              country={country}
            />
          ),
          [ENTRY_STEPS.SIGNUP]: (
            <SignUp
              setStep={setStep}
              onSignUp={onEmailSignUp}
              onVK={onCheckVk.bind(null, ENTER_METHOD.signup)}
              onGoogle={onCheckGoogle.bind(null)}
              onClearError={onClearError}
              settings={settings}
              error={error}
              isProcessing={isProcessing}
              country={country}
            />
          ),
          [ENTRY_STEPS.CHECK]: (
            <Check
              setStep={setStep}
              onCheckEmail={onCheckEmail}
              onResendCheckEmailCode={onResendCheckEmailCode}
              onConfirmRecoveryPassword={onConfirmRecoveryPassword}
              onResendRecoveryPasswordCode={onResendRecoveryPasswordCode}
              onClearError={onClearError}
              onSetCanResendCode={onSetCanResendCode}
              onSkipCheckEmail={onSkipCheckEmail}
              settings={settings}
              checkingEmail={checkingEmail}
              checkingMode={checkingMode}
              isProcessing={isProcessing}
              isCanResendCode={isCanResendCode}
              signUpMode={signUpMode}
              error={error}
            />
          ),
          [ENTRY_STEPS.WELCOME]: (
            <Welcome name={socialInfo.name} signUpMode={signUpMode} loginMode={loginMode} onWelcome={onWelcome} isProcessing={isProcessing}/>
          ),
          [ENTRY_STEPS.SOCIAL]: (
            <Social
              setStep={setStep}
              onSubmitSocial={onSubmitSocialSignUp}
              onClearError={onClearError}
              name={socialInfo.name}
              avatar={socialInfo.avatar}
              error={error}
              isProcessing={isProcessing}
            />
          ),
          [ENTRY_STEPS.RECOVERY_PASSWORD]: (
            <RecoveryPassword
              setStep={setStep}
              onRecoveryPassword={onRecoveryPassword}
              onClearError={onClearError}
              error={error}
              isProcessing={isProcessing}
            />
          ),
          [ENTRY_STEPS.NEW_PASSWORD]: (
            <NewPassword
              setStep={setStep}
              checkingEmail={checkingEmail}
              onNewPassword={onNewPassword}
              onClearError={onClearError}
              error={error}
              isProcessing={isProcessing}
              checkingMode={checkingMode}
            />
          ),
          [ENTRY_STEPS.SUCCESS]: <Success checkingMode={checkingMode} setStep={setStep}/>,
          [ENTRY_STEPS.THANK]: isAfterLogin ? (
            <ThankLogin
              onOpenCabinet={onOpenCabinet}
              onRunStandalone={onRunStandalone}
              isProcessing={isProcessing}
            />
          ) : (
            <Thank
              onOpenCabinet={onOpenCabinet}
              onRunStandalone={onRunStandalone}
              isProcessing={isProcessing}
            />
          ),
          [ENTRY_STEPS.DOMAINS]: (
            <Domains
              setStep={setStep}
              domains={domains}
              selectedDomain={selectedDomain}
              onSelectedDomain={onSelectedDomain}
              onSuccess={onSuccessDomain}
              isProcessing={isProcessing}
            />
          ),
          [ENTRY_STEPS.ERROR]: <Error setStep={setStep} onRetryUpdate={onRetryUpdate} error={error}/>,
          [ENTRY_STEPS.SKELETON]: <Skeleton/>,
          [ENTRY_STEPS.UPDATE]: <Update updateStatus={updateStatus} downloadedPercent={downloadedPercent}/>,
          [ENTRY_STEPS.DOWNLOAD]: (
            <Download
              setStep={setStep}
              onDownloadStandalone={onDownloadStandalone}
              standaloneLink={standaloneLink}
            />
          ),
          [ENTRY_STEPS.INPUT]: <Input/>,
        }[step]
      }
      <LogoutAnotherDevice isOpen={modals[MODALS.LOGOUT_ANOTHER_DEVICE].isOpen}
                           onCancel={onCancelLogin}
                           onAnotherAuthReset={onAnotherAuthReset}/>

      <ConfirmRecoveryAccount isOpen={modals[MODALS.CONFIRM_RECOVERY_ACCOUNT].isOpen}
                              params={modals[MODALS.CONFIRM_RECOVERY_ACCOUNT].params}
                              setStep={setStep}
                              onRecovery={onRecoveryAccount}
                              onClose={closeAllModals}/>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  color: ${Colors.coal};
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 438px;

  ${(p) =>
    p.isProcessing &&
    css`
      pointer-events: none;
      opacity: 0.8;
    `}
  
  @media ${tablet_landscape} {
    min-height: 530px;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 345px;
    height: 500px;
    display: block;
    top: -1px;
    left: 0;
    background-image: url("./2020-05-25_10-57-30.png");
    background-size: cover;
    z-index: 1000;
    opacity: 0.6;
    display: none;
  }
`;
